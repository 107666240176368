import { IoNotifications, IoRemove } from "react-icons/io5";
import { InBoxDialog } from "../navigations/header/notification";
import Page from "./page";
import Button from "../dashboard/asset/button";
import { userModel } from "../../model/user";
import { useState } from "react";
import { updateDataEvent } from "../events";
import { MdDeleteForever, MdDeleteSweep, MdOutlineDelete, MdOutlineMarkEmailRead } from "react-icons/md";




export default
function Inbox({})
{
  const [loading , setLoading] = useState(false)

  const markAllRead = async () => {
    setLoading(true);
    const seen = await userModel.makeSeenNotify();
    updateDataEvent();
    setLoading(false);
  }
  const deleteAll = async () => {
    setLoading(true);
    const seen = await userModel.deleteAllNotifications();
    updateDataEvent();
    setLoading(false);
  }
  
  return(
    <Page title='جميع الأشعارات' Icon={<IoNotifications/>}>
      <div className=" flex flex-col gap-4 items-center" >
        <div className=" bg-white shadow-lg p-2 sticky -top-0 py-3 z-20 w-full flex gap-4 ">
          <Button loading = {loading} type={'button'} OnClick={markAllRead} secondary >
            <MdOutlineMarkEmailRead/>
          </Button>
          <Button loading = {loading} type={'button'} OnClick={deleteAll} secondary >
            <MdDeleteSweep/>
          </Button>
        </div>
        <div className=" px-5 " >
          <InBoxDialog/>
        </div>
      </div>
    </Page>
  )
}