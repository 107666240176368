
import { useEffect, useRef, useState } from "react";
import {  textValues } from "./assets";
import { BiCheck, BiErrorCircle, BiMinus } from "react-icons/bi";

/**
 * 
 * @param {style} 
 * @returns 
 */


export default
function CheckBoxField({
  value,
  onChange = () => null,
  placeholder,
  name,
  err ,
  success ,
  children,
  title,
  empty,
  disabled
})
{
  const textRef = useRef(null)
  const [active, setActive] = useState(false);
  const FieldTitle = textValues.FieldTitle;
  const Child = textValues.ChildSection;
  useEffect(() =>{
    if(empty)
    {
      textRef.current.value = null;
    }
  } , [empty])
  return (
    <div
      onClick={ () => textRef.current.focus()}>
      <FieldTitle title={placeholder || title} name={name} show={active || ( textRef.current ?  textRef.current.value : false)}/>
      <div>
        <div className={textValues.checkBoxClassName(active , err , success)}>
          <Child children={children} textRef={textRef} active={active}/>
          <input
            ref={textRef}
            className={textValues.inputClassName}
              onChange={(v) =>{onChange( name , v.target.value)} }
              onFocus={() => setActive(true)}
              onBlur={() => setActive(false)}
              type='checkbox'
              name={name}
              id={name}
              disabled = {disabled}
              defaultValue={value}
            />
        </div>
        {
          err && 
          <div className=" text-red-500 flex items-center gap-3" >
              <BiErrorCircle/>
              <span >
                {
                  err
                }
              </span>
          </div>
        }
      </div>
    </div>
  )
}

export
function CheckBox({name , children , onChange = () => null , selected = null }) 
{
  const [checked , setChecked] = useState(false);
  const toggle = () =>{
    setChecked(prev => !prev);
    onChange(name , !checked);
    console.log(checked);
  }

  

  useEffect(() => {
    if(selected)
    {
      setChecked(true)
      onChange(name, true);
    } 
    else 
    {
      onChange(name, false);
      setChecked(false);    
    } 
  },[selected])

  return(
    <div  className="flex gap-4 items-center w-full justify-between px-3 py-1">
        <label onClick={toggle} htmlFor = {name} className={` " font-bold ${checked ? ' text-sky-500 ' : ' '} "`}>
          {
            children
          }
        </label>
      <div>
        <div 
        onClick={toggle}
        className="  w-5 h-5 rounded flex justify-center items-center border-2 text-sky-600 text-2xl">
          {
            checked &&
            <BiCheck/>
          }
        </div>
        {/* <input type="checkbox" name={name} onChange={ e => onChange(name , e.target.checked)}/> */}
      </div>
    </div>
  )
}
export
function MasterCheckBox({name , children , onChange = () => null , standard = null , checkList = null }) 
{
  const [checked , setChecked] = useState(false);
  const toggle = () =>{
    let nextCheck = false

    if(checked === true)
      nextCheck = (false);
    else if(checked === false)
      nextCheck = (true);
    else
      nextCheck = (false);

    setChecked(nextCheck);
    onChange(nextCheck);
    // console.log(checked);
  }


  useEffect(() => {
    let admitPermissions = 0 ;
    let list = Object.keys(standard)
    list.map(key => {
      if(checkList[key] === true)
      {
        admitPermissions ++;
      }
    })
    if(admitPermissions == list.length)
    {
      setChecked(true);
    }
    else if(admitPermissions == 0)
    {
      setChecked(false);
    }
    else
    {
      setChecked(admitPermissions)
    }
    
  },[checkList])


  return(
    <div  className="flex gap-4 items-center w-full justify-between px-3 py-1">
        <label htmlFor = {name} className=" font-bold">
          {
            children
          }
        </label>
      <div className=" flex gap-2">
        <div className=" text-sm">
          <span>{
            checked === true ? 'الكل' : checked === false ? 'بدون' : checked
            }
          </span>
          {
            checked !== true && checked !== false &&
            <span className=" text-gray-400">
            /
              {
                Object.keys(checkList).length
              }
            </span>
          }
        </div>
        <div 
        onClick={toggle}
        className="  w-5 h-5 rounded flex justify-center items-center border-2 text-sky-600 text-2xl">
          {
            checked === true &&
            <BiCheck/>
          }
          {
            checked !== true && checked !== false &&
            <BiMinus/>
          }
        </div>
        {/* <input type="checkbox" name={name} onChange={ e => onChange(name , e.target.checked)}/> */}
      </div>
    </div>
  )
}