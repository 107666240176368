
import { useEffect, useRef, useState } from "react";
import { useFindInArray, useOutsideAlerter } from "../../hooks/customHook";
import { IoSearchOutline } from "react-icons/io5";
import { textValues } from "./assets";





function FilterBox({active , temData , setDataValue })
{

  return(
    <div >
      {
        active && 
        <div className=" scroll-style z-20 bg-white max-h-40 absolute overflow-y-scroll p-2 overflow-hidden gap-2 top-full w-full mt-1 rounded-lg  flex flex-col shadow-lg">
          {
            temData.length > 0 &&
            temData.map(e => (
              <div onClick={() => setDataValue(e)} className=" flex gap-2 w-full ease-linear duration-200 border p-2 rounded-lg 
              hover:bg-sky-300 cursor-pointer
                hover:gap-4
                hover:outline
                hover:border-none
                hover:outline-1 outline-sky-700
              " >
                <span>
                  {
                    e.id
                  }
                </span>
                <span>
                  |
                </span>
                <span>
                  {e.name || e.patientName}
                </span>
              </div>
              ))
              ||
              <div  className=" w-full ease-linear duration-200  p-2 rounded-lg text-center" >
                لا توجد بيانات
              </div>
          } 
        </div>
      }
    </div>
  )
}






/**
 * 
 * @param {style} 
 * @returns 
 */


export default
function FindField({
  value,
  onChange = () => null,
  placeholder,
  name,
  type,
  err,
  success,
  title,
  data,
  empty,
  endActionButton = null,
  textOfEndAction 
})
{
  const textRef = useRef()
  const containerRef = useRef();

  const [active, setActive] = useState();
  const [nameFinded, setName] = useState();
  const [temData , setTemData] = useState([])
  const [currentText , setCurrentText] = useState('')
  const [agentId , setAgentId] = useState(null);
  const [show , setSHow] = useState(null);
  const [filterKey , setFilterKey] = useState('id');
  useEffect(() => {
    data.length > 0 && setFilterKey([
      (data[0]['name'] && 'name' ) || 
      (data[0].patientName && 'patientName') || 
      'id']
    )  
  },[data])

  useEffect(() => {
    setAgentId('')
  }, [empty])
  useFindInArray(data , currentText , setTemData , filterKey );
  useOutsideAlerter(containerRef , () =>{
    setActive(false)
  } 
);
  const setDataValue = (e)=>
  {
    setName(e.name || e.patientName || e.id);
    setAgentId(e.id);
    onChange(name , e.id);
    setActive(false);
    setSHow(true);
    ////
  }
  useEffect(() => {
    setSHow(active || textRef.current.value)
    if(agentId)
    {
      textRef.current.value = nameFinded
      setSHow(true);
    }else
    {
      textRef.current.value = '';
      setSHow(active || false);
    }

  } , [active , agentId] )
  const FieldTitle = textValues.FieldTitle;
  const Child = textValues.ChildSection;
  return ( 
    <div ref={containerRef}>
        <FieldTitle title={ placeholder || title } name={name} show={show}/>
      <div className=" relative">
        {
          temData &&
          <FilterBox endActionButton={endActionButton} textOfEndAction={textOfEndAction} active = {active} temData = {temData} setDataValue = {setDataValue}/>
        }
        <div className={textValues.fieldClassName(active , err , success)}>  
          <Child children={<IoSearchOutline/>} textRef={textRef} active={active}/>
          <input
            ref={textRef}
            autoComplete="false"
            className={textValues.inputClassName}
            onChange={(v) =>{ onChange( name , agentId) ; setCurrentText(v.target.value);} }
            onFocus={() => setActive(true)}
            type={type}
            name={name}
            id={name}
            defaultValue={value} />
        </div>
      </div>
    </div>
  )
}


