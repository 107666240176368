
import { BiInfoCircle, BiLogOut, BiMenu } from 'react-icons/bi';
import { useOutsideAlerter } from '../../hooks/customHook';
import { useRef, useState } from 'react';
import { MdAccountCircle, MdSettings } from 'react-icons/md';
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from 'react-router-dom';
import { showMenuEvent } from '../../events';





export
function AccountBtn({title , onClick , icon = null})
{
  return(
    <div onClick={onClick} dir='rtl' className=' flex items-center gap-2 whitespace-nowrap text-right p-1 px-10 hover:bg-gray-100 duration-200 ease-linear cursor-pointer'>
      <div className=' text-xl'>
        {
          icon
        }
      </div>
      <div>
        {
          title
        }
      </div>
    </div>
  )
}



export
function AccountDialog({setShowDialog})
{
  const signOut = useSignOut();
  const navigator = useNavigate();
  const doSignOut = () =>
  {
    signOut()
    setShowDialog(false);
    navigator('/login')
  }

  const goToPage =(dir) =>{
    navigator(dir)
    setShowDialog(false)
  }

  return(
    <div>
      <AccountBtn icon={<MdAccountCircle/>} title='الحساب' onClick ={() => {goToPage('/account')}} />
      <AccountBtn icon={<MdSettings/>} title='الأعدادات' onClick ={() => goToPage('/settings')}  />
      <AccountBtn icon={<BiInfoCircle/>} title='ارشادات' onClick ={() => goToPage('/info')}  />
      <div className=' border'></div>
      <AccountBtn icon={<BiLogOut/>} title='تسحيل الخروج' onClick ={() => doSignOut()}  />  
    </div>
  )
}



export
function HeaderBtn({
  onClick ,
  icon ,
  notificationNumbers
}){


  return(
      <div
      onClick={onClick} 
      className=' relative text-lg text-white rounded-full p-2 bg-black/30
       hover:bg-black/60 cursor-pointer ease-linear duration-75
      '>
        <div>
          {
            icon
          }
        </div>
        {
          notificationNumbers  ? 
          <div className=' absolute p-1 h-fit rounded-full bg-red-400 -top-1 -right-1 text-[0.5rem] text-xs '>
            {
              notificationNumbers
            }
          </div> : ''
        }
      </div>
  )
}

export
function NavDropBtn({Btn = () => null , Dialog = () => null , icon , notiNo , setNotifyNo})
{
  const [showDialog , setShowDialog] = useState();
  const meRef = useRef();
  const toggleShow = () =>{
    setShowDialog(!showDialog);
  }
  useOutsideAlerter(meRef , () =>{
    setShowDialog(false)
  })
  

  return(
    <div>
      <div ref={meRef} className=' relative w-fit' >
            <Btn onClick={() => toggleShow()} icon = {icon} notificationNumbers = {notiNo}/>
            <div ref={meRef} className=
            {`' ${showDialog ? ' opacity-100 ' : ' opacity-50   -translate-x-[100vw] md:-translate-x-[70vw]   '}  ease-linear duration-300 fixed flex flex-col  overflow-hidden bg-white shadow-md z-50  rounded-lg
              left-[10vw]   w-[80vw]  sm:absolute  max-h-72 sm:left-0 sm:w-fit
            `}
            >
              <Dialog  setShowDialog={setShowDialog} setNotifyNo = {setNotifyNo}/>
            </div>
          </div>
    </div>
  )
}


export 
function MobileMenu({width})
{
  const handleShowMenu = () => {
    showMenuEvent();
  }
  return(
    <div className=' flex items-center' >
          {
            width < 430 &&
            <div className='  items-center flex text-3xl'>
              <div 
              onClick={handleShowMenu}
              className=' rounded-sm text-white  '>
                <BiMenu/>
              </div>
            </div>
          }
        </div>
  )
}

