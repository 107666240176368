


import axios from 'axios'
import Cookies from 'js-cookie'
import { model } from '..';


export
const notesModel = {
  getAll : async (setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer +  '/notes/get_all' ,  {headers : {'access-token' : token, }})
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },

  getById : async (id , setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer +  '/notes/get/'+id ,  {headers : {'access-token' : token, }})
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },

  create : async (modelData) => {
    let response = null
    const token = false;
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.put( model.backEndServer +  '/notes/create' , modelData ,  {headers : {'access-token' : token, }} )
                  .then(res => {
                    // console.log(res);
                    response = res;
                  })
                  .catch(err => {
                    // console.log(err);
                    response = err.response;
                  } );
    }
    catch(err)
    {
      // console.log(err)
      response = err;
    }
    return response
  },


  update : async (modelData) => {
    let response = null
    const token = false;
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.patch( model.backEndServer +  '/notes/update' , modelData ,  {headers : {'access-token' : token, }} )
                  .then(res => {
                    // console.log(res);
                    response = res.data;
                  })
                  .catch(err => {
                    // console.log(err);
                    response = err.response.data;
                  } );
    }
    catch(err)
    {
      // console.log(err)
      response = err;
    }
    return response
  },


  

}
 