import { useEffect, useState } from "react";
import Container from "../dashboard/container";
import Button from "../dashboard/asset/button";
import TextField from "../dashboard/asset/textField";
import { LineNotify } from "../dashboard/asset/notify";
import { CheckBox, MasterCheckBox } from "../dashboard/asset/checkBox";
import { perModel } from "../../model/permissions";

import { useGetPermissionsKeys } from "../hooks/customHook";
import { updateDataEvent } from "../events";

import LoadingScreen from "../dashboard/asset/loading";



// export
// const
// perm = Object.freeze({
//   ///// addition
//   addEmployee : 1,
//   addClient : 2,
//   addStay : 3,
//   addEmployeeStay : 4 ,
//   ///------------------// : 1
//   //// : 1 view
//   viewEmployee : 5 ,
//   viewClient : 6 ,
//   viewStay : 7,
//   /////---------------// : 1
//   /// : 1 view table     //
//   viewEmployeeTable : 8,
//   viewClientTable : 9,
//   viewStayTable : 10,
//   /// : 1 -------------//
//   addUser : 11, 
//   ////// : 1
//   editEmployee : 12,
//   editClient : 13,
//   editStay : 14,
//   editEmployeeStay: 15,

// })
export
const
permIntoArabic = Object.freeze({
  ///// addition
  addEmployee :'اضافة موظف جديد',
  addClient :'اضافة عميل جديد',
  addStay :'اضافة اقامة جديدة',
  addEmployeeStay :'اضافة اقامة موظف' ,
  ///------------------// :''
  //// :'' view
  viewEmployee :'عرض الموظفين' ,
  viewClient :'عرض العملاء' ,
  viewStay :'عرض الإقامات',
  /////---------------// :''
  /// :'' view table     //
  viewEmployeeTable :'عرض جدول الموظفين',
  viewClientTable :'عرض جدول العملاء',
  viewStayTable :'عرض جدول الإقامات',
  /// :'' -------------//
  addUser :'اضافة مستخدم', 
  ////// :''
  editEmployee :'تعديل الموظفين',
  editClient :'تعديل العملاء',
  editStay :'تعديل الإقامات',
  editEmployeeStay :'تعديل إقامة موظف',

  viewUser : 'عرض المستخدمين',
  viewEmployeeStay : 'عرض اقامة الموظفين',
  visitAdmin : 'ادارة الزيارات',
  visitUser : 'مستخدم زيارات',
  viewDocument : 'عرض المستندات',
  addDocument : 'اضافة مستندات',
  jobAdmin : 'ادارة الوظائف',
  ////// : ------------------
  addSupplies : 'اضافة مستلزمات',
  magicSearch : 'اداة البحث السحرية',
  viewPricing : 'عرض الأسعار',
  addPricing : 'اضافة تسعير',
  viewSupplies : 'عرض المستلزمات',
  editRequest : 'تعديل طلبات الموقع',
  viewRequest : 'عرض الطلبات',
  addTerm : 'اضافة بنود',
  viewTerm : 'عرض البنود',
  addNotes : 'اضافة ملاحظة',
  viewNotes : 'عرض الملاحضات',
  viewNotesAdmin : 'ادارة الملاحضات',

  viewDeals : 'عرض التعاقدات',
  addDeals : 'اضافة تعاقد',
  viewPayment : 'عرض المعاملات',
  addPayment : 'اضافة معاملة',
  backup : 'نسخة احتياطية',
  addPreStay : 'الأقامات المحتملة',

})



export  function AddNewJob()
{
  const [emptyFiled , setEmptyField] = useState(false);
  const [selectAll , setSelectAll] = useState(false);
  const [loading , setLoading] = useState(false);
  const perm = useGetPermissionsKeys();
  const [values , setValues] = useState({
    
  })

  const log = {
    err : false,
    success : false,
    alert : false,
  }
  const [customLog , setCustomLog] = useState(log);
  const [res , setRes] = useState(null);
  useEffect(() => {
    const isErr = (fieldName) =>
    {
      try{
        console.log(res[fieldName])
        return (res[fieldName] == true || res['key'] == fieldName)
      }catch
      {
        return false
      }
    }

    setCustomLog(prev => ({...prev , 'nameErr'        : isErr('name') }) );
  }, [res])
  

  
  
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true);
    const res = await perModel.create(values);
    console.log('res is : ' , res);
    setCustomLog(prev => ({...prev , msg   : res.msg}) );
    
    if(res.done)
    {
      setCustomLog(prev => ({...prev , msg   : 'تم تسجيل وظيفة جديدة بنجاح'}) );
      setCustomLog(prev => ({...prev , success   : true}) );
      setEmptyField(prev => prev++ );
    }else
    if(res.status == 400)
    {
      setCustomLog(prev => ({...prev , alert   : true}) );
    }else
    if(res.msg)
    {
      setCustomLog(prev => ({...prev , err   : true}) );
      setRes(res);
    }
    updateDataEvent();
    setLoading(false);
  }
  const handleInput = (name , value) => {
    setCustomLog({})
    setValues(prev => ({...prev , [name] : value }));
    // console.log(values);
    
  }
  const handleMasterCheckBox = (checked) =>
  {
    console.log('master' , checked);
    // setSelectAll(prev => !prev);
    setSelectAll(checked);
  }

  return( !loading ?
      <>
      <Container onSubmit={handleSubmit} title={'اضافة وظيفة جديدة '}  footer={<>
        <Button type='submit'>
          تأكيد
        </Button>
        <Button secondary>
          الغاء
        </Button>
      </>}>
        <div>
          <TextField empty = {emptyFiled} err={customLog.nameErr ? customLog.msg : false} type = 'text' title = {'اسم الوظيفة'}  name = 'job' onChange = {handleInput}/>
          <div className=" mt-5">
            <div className=" text-lg border-b-2 mb-2">
              <MasterCheckBox name={'master'} onChange={handleMasterCheckBox} standard={perm} checkList={values}>
                الصلاحيات
              </MasterCheckBox>
            </div>
            <div className=" flex flex-col gap-4">
              {
                Object.keys(perm).length > 0 &&
                Object.keys(perm).map(e => ( (e != 'id' && e != 'job' ) &&
                  <CheckBox name={e} onChange={handleInput} selected={selectAll}>
                    {
                      permIntoArabic[e] || e
                    }
                  </CheckBox>
                ))
              }
            </div>
          </div>
          </div>
          <LineNotify text={customLog.msg} err={customLog.err} alert={customLog.alert} success={customLog.success}/>
        </Container>
      </> : <LoadingScreen/>
  )
}


