import { useParams } from "react-router-dom";
import { AddNewClient } from "../dashboard/containers/addNew";
import PageHeader from "../dashboard/asset/pageHeader";
import { IoPeopleCircle, IoPeopleOutline } from "react-icons/io5";
import Page from "./page";
import { MdAccountBox } from "react-icons/md";
import { EmployeeData } from "../dashboard/containers/data/employee";
import { Line, LineChart, Tooltip } from "recharts";
import { UserCardDataCard } from "./userPage";



export default function Account()
{
  const {route} = useParams();
  return(
    
      <Page title={'الحساب'} Icon={<MdAccountBox/>} >
      
          <UserCardDataCard personal = {true} />
      </Page>
  )
}



