import axios from 'axios'
import Cookies from 'js-cookie'
import { model } from '..';


export
const userModel = {
  getAll : async (setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer +  '/user/get_all' ,  {headers : {'access-token' : token, }})
      .then(res => {
        console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },
  setOnline : async () => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.post( model.backEndServer +  '/user/online' , { } ,  {headers : {'access-token' : token, }})
      .then(res => {
        // console.log(res.data);
        // setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },
  getOnline : async (id , setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer +  '/user/getOnline/'+id , { } ,  {headers : {'access-token' : token, }})
      .then(res => {
        console.log(res.data);
        setData(res.data.online);
      })
      .catch(
        err =>{
          console.log(err)
          setData(false)
        } )
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },
  getNotifications : async (notSeen) => {
    var value = [];
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios({
        url : model.backEndServer +  '/user/get_all_notifications/'+notSeen ,  
        method : 'get' ,
        headers : {
          'access-token' : token, 
        } 
      })
      .then(res => {
        const {notifyLogs} = res.data;
        // console.log('notify from axios is : ' , notifyLogs);
        value = notifyLogs
      }).catch(err => console.log(err));
    }catch(err)
    {
      console.log(err);
    }
    return value
  },

  active : async (id , active) => {
    try
    {
      const token = Cookies.get('_auth');
      console.log(active);
      const data = await axios.patch( model.backEndServer +  '/user/active/'+ id , {active} ,  {headers : {'access-token' : token, }})
      .then(res => {
        console.log(res.data);
        return res.data;
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },

  


  updatePasswordFromAdmin : async (values) => {
    /// data is { id , adminPass , newPass }
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.patch( model.backEndServer +  '/user/update_user_password' , values ,  {headers : {'access-token' : token, }})
      .then(res => {
        console.log(res.data);
        return res.data;
      })
      .catch(err => {
        console.log(err);
        return err.response.data;
      })
    }catch(err)
    {
      // console.log(err);
      return null
    }
    return null
  },
  updateJobFromAdmin : async (values) => {
    /// data is { id , adminPass , newPass }
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.patch( model.backEndServer +  '/user/update_user_job' , values ,  {headers : {'access-token' : token, }})
      .then(res => {
        console.log(res.data);
        return res.data;
      })
      .catch(err => {
        console.log(err);
        return err.response.data;
      })
    }catch(err)
    {
      // console.log(err);
      return null
    }
    return null
  },
  getById : async (id , setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer +  '/user/get/'+id ,  {headers : {'access-token' : token, }})
      .then(res => {
        console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },

  
  MainStandardTable : {
    id: "#" ,
    name: 'الأسم' ,
    job : "الوظيفة",
    phone: "الهاتف",
    phone2: "الهاتف",
    workDate: 'تاريخ التعيين',
    birthDate: 'تاريخ الميلاد',
    age:'السن',
    nationality:'الجنسية',
    gender:'النوع',
    job : 'الوظيفة',
  },


  create : async (modelData) => {
    let response = null
    const token = false;
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.put( model.backEndServer +  '/register' , modelData ,  {headers : {'access-token' : token, }} )
                  .then(res => {
                    console.log(res);
                    response = res.data;
                  })
                  .catch(err => {
                    // console.log(err);
                    response = err.response.data;
                  } );
    }
    catch(err)
    {
      // console.log(err)
      response = err;
    }
    return response
  },

  changePassword : async (modelData) => {
    let response = null
    const token = false;
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.patch( model.backEndServer +  '/change_password' , modelData ,  {headers : {'access-token' : token, }} )
                  .then(res => {
                    console.log(res);
                    response = res.data;
                  })
                  .catch(err => {
                    // console.log(err);
                    response = err.response.data;
                  } );
    }
    catch(err)
    {
      // console.log(err)
      response = err;
    }
    return response
  },

  makeSeenNotify : async (id = 'all') => {
    let response = null
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.patch( model.backEndServer +  '/user/seen_notify/'+ id ,{},  {headers : {'access-token' : token, }} )
      .then(res => {
        console.log(res);
        return response = res.data;
      })
      .catch(err => {
        // console.log(err);
        return response = err.response.data;
      } );
      
    }
    catch(err)
    {
      // console.log(err)
      return response = err;
    }
    return response

  },
  deleteAllNotifications : async (id = 'all') => {
    let response = null
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.patch( model.backEndServer +  '/user/delete_notify' ,{},  {headers : {'access-token' : token, }} )
      .then(res => {
        console.log(res);
        return response = res.data;
      })
      .catch(err => {
        // console.log(err);
        return response = err.response.data;
      } );
      
    }
    catch(err)
    {
      // console.log(err)
      return response = err;
    }
    return response

  },


  checkAuth : (  setAuth) => {
    const token = Cookies.get('_auth');
    axios.get( model.backEndServer + '/check_auth',{
      headers : {
        'access-token' : token,
      }
    } ).then(res => {
      setAuth(res.data);
    }).catch(res => {
      // console.log(res)
    })
  }

}
 