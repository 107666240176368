
export default 
function PageHeader({title , Icon})
{
  return (
    <div className="border-2 border-blue-300 p-2 rounded-lg border-r-indigo-300 border-r-4  ">
            <div className="py-1 font-bold text-lg flex items-center">
              {Icon}
              <span className="mx-2">
                {title}
              </span>
            </div>
          </div>
  )
}