
import Cookies from 'js-cookie'
import axios from 'axios'
import {model} from '../'


export
const perModel = {
  create : async (modelData) => {
    
    let response = null
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.put( model.backEndServer + '/permission/create' , modelData ,  {headers : {'access-token' : token, }})
                  .then(res => {
                    console.log(res);
                    response = res.data;
                  })
                  .catch(err => {
                    console.log(err);
                    response = err.response.data;
                  } );
    }
    catch(err)
    {
      console.log(err)
      response = err;
    }
    return response
  },
  getAll : async (setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer +  '/permission/get_all' ,  {headers : {'access-token' : token, }})
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      console.log(err);
      return []
    }
    return []
  },
  getById : async ( id , setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer +  '/permission/get/'+ id ,  {headers : {'access-token' : token, }})
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      console.log(err);
      return []
    }
    return []
  },
  update : () => {
    return ////todo
  }

}
 