import { useEffect, useState } from "react";
import { BiErrorCircle } from "react-icons/bi";
import { CiCircleCheck, CiWarning } from "react-icons/ci";


export
function LineNotify({text , err , alert , success })
{
  
  
  return( 
    <div>
        {
          ( err || success || alert ) && text && 
          <div dir="rtl" className=" relative  bottom-0  items-center flex justify-center w-full">
            <div className={`  flex items-center gap-3 border rounded-lg p-1 px-2
            ${ success &&  " bg-green-100 text-green-600 border-green-600  "}
            ${ err && !success &&  " bg-red-100 text-red-600 border-red-600  "}
            ${ alert && !err && !success &&  " bg-yellow-100 yellow-red-600 border-yellow-600  "}
            `}>
              <div>
                {
                  alert && !err && !success && 
                  <CiWarning/>
                }
                {
                  err && !success && 
                  <BiErrorCircle/>
                }
                {
                  success &&
                  <CiCircleCheck />
                }
              </div>
              <div>
                {
                  
                  text
                }
              </div>
            </div>
          </div>
        }
    </div>
  )
}
