import axios from 'axios'
import Cookies from 'js-cookie'
import { model } from '..';


export
const docModel = {
  getClientDoc : async (setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer + '/docs/get_all/client'  ,  {headers : {'access-token' : token, }})
      .then(res => {
        // console.log(res.data);
        setData(res.data);
        return[res.data];
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      console.log(err);
      setData('err')
      return []
    }
    // setData([])
    return []
  },
  
  getEmployeeDoc : async (setData , target) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer + '/docs/get_all/' + target ,  {headers : {'access-token' : token, }})
      .then(res => {
        console.log(res.data);
        setData(res.data);
        return[res.data];
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      console.log(err);
      setData('err')
      return []
    }
    // setData([])
    return []
  },


  /**********************
   * 
   * 
   * 
   * 
   */

  getTitles : async (setData , target) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer + '/docs/get_all/'+target,  {headers : {'access-token' : token, }})
      .then(res => {
        // console.log(res.data);
        setData(res.data);
        return[res.data];
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      console.log(err);
      setData([])
      return []
    }
    // setData([])
    return []
  },

  createTerm : async (modelData , target) => {
    let response = null
    const token = false;
    try
    {
      const token = Cookies.get('_auth');

      const data = await axios.put( model.backEndServer +  '/docs/create_term/'+target , modelData ,  {headers : {'access-token' : token, }} )
                  .then(res => {
                    // console.log(res);
                    response = res.data;
                  })
                  .catch(err => {
                    // console.log(err);
                    response = err.response.data;
                  } );
    }
    catch(err)
    {
      // console.log(err)
      response = err;
    }
    return response
  },
  update : async (modelData , target) => {
    let response = null
    const token = false;
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.patch( model.backEndServer +  '/docs/update/'+target , modelData ,  {headers : {'access-token' : token, }} )
                  .then(res => {
                    // console.log(res);
                    response = res.data;
                  })
                  .catch(err => {
                    // console.log(err);
                    response = err.response.data;
                  } );
    }
    catch(err)
    {
      // console.log(err)
      response = err;
    }
    return response
  },
  remove : async (modelId , target) => {
    let response = null
    const token = false;
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.delete( model.backEndServer +  '/docs/update/'+target+'/'+modelId  ,  {headers : {'access-token' : token, }} )
                  .then(res => {
                    // console.log(res);
                    response = res.data;
                  })
                  .catch(err => {
                    // console.log(err);
                    response = err.response.data;
                  } );
    }
    catch(err)
    {
      // console.log(err)
      response = err;
    }
    return response
  },
  
  getById : async (id , setData , target) => {
    let response = null
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer +  '/docs/get_by_id/' + target + '/'+ id , {headers : {'access-token' : token, }} )
                  .then(res => {
                    // console.log(res);
                    [response] = res.data;
                    setData(response);
                  })
                  .catch(err => {
                    // console.log(err);
                    response = err.response.data;
                  } );
    }
    catch(err)
    {
      // console.log(err)
      response = err;
    }
    return response
  },
  


}
 