import { useParams } from "react-router-dom"
import {  AddNewStay } from "../dashboard/containers/addNew";
import { StayTable } from "../dashboard/containers/tables";
import Page from "./page";
import { useEffect, useState } from "react";
import { customDialogsEvents, useUpdateData } from "../hooks/events";
import { StayData } from "../dashboard/containers/data/stay";
import { BiHomeHeart } from "react-icons/bi";
import { useGetPermissions } from "../hooks/customHook";
import { NotAvailable } from "../dashboard/asset/notAvailable";
import NewItemDialog from "../dashboard/asset/newItemDialog";
import Button from "../dashboard/asset/button";
import Container from "../dashboard/container";
import { Radio, Segmented, Table, Tag } from "antd";
import TextField from "../dashboard/asset/textField";
import SelectBox from "../dashboard/asset/selectBox";
import TextArea from "../dashboard/asset/textArea";
import { stayModel } from "../../model/stay";
import { updateDataEvent } from "../events";
import LoadingScreen from "../dashboard/asset/loading";
import { model } from "../../model";



export default function Stay()
{
  const {route} = useParams();
  const [showData , setShowData] = useState(null);

  const permissions = useGetPermissions();
  customDialogsEvents.useEmployee(  e =>{
    setShowData(e.detail.detail);
    console.log(e.detail.detail);
  })

  const [mode, setMode] = useState('preStay');
  const handleModeChange = (e) => {
    setMode(e.target.value);
  };

  return(
    <Page title={'العملاء'} Icon={<BiHomeHeart/>} >
      <div className=" flex gap-4" >
      <Radio.Group
        onChange={handleModeChange}
        value={mode}
        style={{
          marginBottom: 8,
        }}
      >
        <Radio.Button value="preStay">اقامات محتملة</Radio.Button>
        <Radio.Button value="stay">اقامات</Radio.Button>
      </Radio.Group>
      </div>
      {
        mode == 'stay' ?
        <>
            {
         
         (
           permissions && permissions.viewUser &&
           <NewItemDialog>
             <AddNewStay/> 
           </NewItemDialog>
           || <NotAvailable/>
         )
       } 
        {
          permissions && permissions.viewStay &&(
            <StayData modelId={showData}/>
          ) || null
        }
        {
          
          
          (
            permissions && permissions.addStay &&
            <StayData modelId={route}/> || <NotAvailable/>
          )
        }
    
      {
        
        (
          permissions && permissions.viewUser &&
          <StayTable/> || <NotAvailable/>
        )
      }
        </> :
        <>
        <PreStayTable/>
        </>
      }




      
    </Page>
  )
}



const PreStayTable = () => {
  const [values , setValues] = useState();
  const [data , setData] = useState([]);
  const [loading , setLoading] = useState(false);
  
  const getMoveText = (text) => {
    switch (text) {
      case 'move':
        return (
          <Tag color="green">
            متحرك
          </Tag>
        )
        
      case 'semi-move':
        return <Tag color="orange">
        شبه متحرك
      </Tag>
      
      case 'disabled':
        return <Tag color="magenta">
          قعيد
        </Tag>  
    }
  }

  const columns = [
    {
      title: '#',
      dataIndex : 'id',
      key : 'id'
    },
    {
      title: 'التاريخ',
      dataIndex : 'date',
      key : 'date',
      render: (text) => model.dateToType(text)
    },
    {
      title: 'الأسم',
      dataIndex : 'name',
      key : 'name'
    },
    {
      title: 'العنوان',
      dataIndex : 'address',
      key : 'address'
    },
    {
      title: 'السن',
      dataIndex : 'age',
      key : 'id'
    },
    {
      title: 'الوزن',
      dataIndex : 'weight',
      key : 'weight'
    },
    {
      title: 'التحرك',
      dataIndex : 'ability',
      key : 'weight',
      render : (text) => getMoveText(text)
    },
    {
      title: 'التوصيلات',
      dataIndex : 'supplies',
      key : 'weight'
    },
    {
      title: 'المرافق',
      dataIndex : 'family',
      key : 'weight'
    },
    {
      title: 'معلومات',
      dataIndex : 'info',
      key : 'weight'
    },
    {
      title: 'السعر',
      dataIndex : 'price',
      key : 'weight'
    },
    {
      title: 'رقم الهاتف',
      dataIndex : 'phone',
      key : 'weight'
    },
  ]
  const loadData = async () => {
    setLoading(true);
    await stayModel.getAllPreStay(setData);
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  },[])
  
  const handleInput = (name , value) => {
    setValues(prev => ({...prev ,[name] : value }));
  }

  const handleSubmit = async () =>{
    setLoading(true);
    await stayModel.createPreStay(values);
    await loadData();
    setLoading(false);
  }

  return(
    <Container title={
      <div className="flex justify-between " >
        <div>
          الأقامات المحتملة
        </div>
  
      </div>
      }
    >
      {
        !loading ?
      <div>
        <div className=" w-full overflow-x-scroll scroll-style border-2 border-slate-200 rounded-lg " >
          <Table dataSource={data || []} columns={columns} />
        </div>
        <div>
          <div>اضافة حقل جديد</div>
          <div className=" grid grid-auto-fit gap-2  mt-5 " >
            <TextField onChange = {handleInput} name = 'name'  placeholder= 'الأسم'  />
            <TextField onChange = {handleInput} name = 'address'  placeholder= 'العنوان' />
            <div className=" flex  gap-2 text-sm w-full " >
                <TextField onChange = {handleInput}  name = 'age' placeholder= 'السن' />
                <TextField onChange = {handleInput}  name = 'weight' placeholder= 'الوزن' />
            </div>
            <TextField onChange = {handleInput}  name = 'supplies' placeholder= 'التوصيلات' />
            <TextField onChange = {handleInput}  name = 'family' placeholder= 'المرافق' />
            <div className=" flex  gap-2 text-sm w-full " >
              <SelectBox onChange = {handleInput}  name = 'ability' placeholder= '' options ={{"move" : 'متحرك' , "disabled" : 'قعيد' , "semi-move" : 'شبه متحرك'}} />
              <TextField onChange = {handleInput}  name = 'price' placeholder= 'السعر' />
            </div>
            <TextField onChange = {handleInput}  name = 'phone' placeholder= 'الهاتف'  />
            <TextArea  onChange = {handleInput} name = 'info' placeholder= 'معلومات' />
            <Button OnClick={handleSubmit} type={'button'}  >
              تاكيد
            </Button>
          </div>
        </div>
      </div>
      :
      <LoadingScreen/>
      }
    </Container>
  )
}