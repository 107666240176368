import axios from 'axios'
import Cookies from 'js-cookie'
import { model } from '..';


export
const employeeModel = {
  getAll : async (setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer + '/employee/get_all' ,  {headers : {'access-token' : token, }})
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },
  getAllJobs : async (setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer + '/permission/get_all_jobs' ,  {headers : {'access-token' : token, }})
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },

  getAllStay : async (setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer + '/employee/get_all_stay' ,  {headers : {'access-token' : token, }} )
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },
  
  MainStandardTable : {
    id: "#" ,
    name: 'الأسم' ,
    job : "الوظيفة",
    phone: "الهاتف",
    phone2: "الهاتف",
    cardId : 'الرقم القومي',
    address:'العنوان',
    workDate: 'تاريخ التعيين',
    birthDate: 'تاريخ الميلاد',
    age:'السن',
    nationality:'الجنسية',
    gender:'النوع',
    job : 'الوظيفة',
  },
  StayStandardTable : {
    id : '#',
    name: "الموظف" ,
    patientName: 'اسم الحالة' ,
    status : "الحالة",
    salary : "المرتب" , 
    paid : "مدفوع",
    total : "الأجمالي",
    workedDays : 'ايام العمل',
    freePeriod : 'ايام الراحه',
    additional : "اضافي",
    deduct : "خصم",
    startDate : 'بداية الشهر ',
    endDate : 'نهاية الشهر ',
    },

  create : async (createModel) => {
    let response = null
    const token = false;
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.put( model.backEndServer + '/employee/create' , createModel ,  {headers : {'access-token' : token, }} )
                  .then(res => {
                    // console.log(res);
                    response = res.data;
                  })
                  .catch(err => {
                    // console.log(err);
                    response = err.response.data;
                  } );
    }
    catch(err)
    {
      // console.log(err)
      response = err;
    }
    return response
  },
  updateStayData : async (dataModel) => {
    let response = null
    const token = Cookies.get('_auth');
    try
    {
      const data = await axios.patch( model.backEndServer + '/employee/update_stay_data' , dataModel ,  {headers : {'access-token' : token, }})
        .then(res => {
          console.log(res);
          response = res;
        })
        .catch(err => {
          console.log(err);
          response = err.response;
        } );
    }
    catch(err)
    {
      console.log(err)
      response = err;
    }
    return response
  },


  updateMoney : async (createData) => {
    let response = null
    const token = Cookies.get('_auth');
    try
    {
      const data = await axios.patch( model.backEndServer + '/employee/update_money' , createData ,  {headers : {'access-token' : token, }})
        .then(res => {
          console.log(res);
          response = res;
        })
        .catch(err => {
          console.log(err);
          response = err.response;
        } );
    }
    catch(err)
    {
      console.log(err)
      response = err;
    }
    return response
  },
  
  
  
  updateMainData : async (dataModel) => {
    let response = null
    const token = Cookies.get('_auth');
    try
    {
      const data = await axios.patch( model.backEndServer + '/employee/update_main_data' , dataModel ,  {headers : {'access-token' : token, }})
        .then(res => {
          console.log(res);
          response = res;
        })
        .catch(err => {
          console.log(err);
          response = err.response;
        } );
    }
    catch(err)
    {
      console.log(err)
      response = err;
    }
    return response
  },
  
  
  
  createStay : async (dataModel) => {
    let response = null
    const token = Cookies.get('_auth');
    try
    {
      
      const data = 
      await axios.put( model.backEndServer + '/employee/create_Stay' , dataModel ,  {headers : {'access-token' : token, }} )
        .then(res => {
          console.log(res);
          response = res.data;
        })
        .catch(err => {
          console.log(err);
          response = err.response.data;
        } );
    }
    catch(err)
    {
      console.log(err)
      response = err;
    }
    return response
  },
  getById : async (userId , setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer + `/employee/get/${userId}` ,  {headers : {'access-token' : token, }} )
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(err => {
        // console.log(err);
      })
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },
  getByUserId : async (userId , setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer + `/employee/get_by_userid/${userId}` ,  {headers : {'access-token' : token, }} )
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(err => {
        // console.log(err);
      })
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },
  getStayById : async (userId , setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer + `/employee/get_stay/${userId}` ,  {headers : {'access-token' : token, }} )
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(
        err => console.log(err)
      )
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },
  getStayByEmployeeId : async (userId , setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer + `/employee/get_stay_by_employee/${userId}` ,  {headers : {'access-token' : token, }} )
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(
        err => console.log(err)
      )
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },
  getByStayId : async (userId , setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer + `/employee/get_by_stay_id/${userId}` ,  {headers : {'access-token' : token, }} )
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(
        err => console.log(err)
      )
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },



}
 