import { useNavigate, useParams } from "react-router-dom";
import { AddNewClient, AddNewStay } from "../dashboard/containers/addNew";
import PageHeader from "../dashboard/asset/pageHeader";
import { IoDocument, IoPeopleCircle, IoPeopleOutline } from "react-icons/io5";
import Page from "./page";
import { ClientTable } from "../dashboard/containers/tables";
import { useEffect, useState } from "react";
import { customDialogsEvents, useUpdateData } from "../hooks/events";
import { ClientData } from "../dashboard/containers/data/client";
import { useGetPermissions } from "../hooks/customHook";
import { NotAvailable } from "../dashboard/asset/notAvailable";
import NewItemDialog, { NewItemDialog2 } from "../dashboard/asset/newItemDialog";
import { clientModel } from "../../model/client";
import { stayModel } from "../../model/stay";
import { model } from "../../model";
import Button from "../dashboard/asset/button";
import { Table, Tag } from "antd";
import { PageNavigate, SearchHeadBox } from "../dashboard/asset/tableHeadSearch";
import TextField from "../dashboard/asset/textField";
import SelectBox from "../dashboard/asset/selectBox";
import TextArea from "../dashboard/asset/textArea";

 

export default function Client()
{
  const {route} = useParams();
  const [showData , setShowData] = useState(null);
  const [stay , setStay] = useState(null);
  const permissions = useGetPermissions();

  
  const [mode, setMode] = useState('main');
  
  const handleModeChange = (e) => {
    setMode(e.target.value);
  };


  customDialogsEvents.useEmployee(  e =>{
    setShowData(e.detail.detail);
  })

  return(
    
      <Page title={'العملاء'} Icon={<IoPeopleOutline/>} >
      <PageNavigate handleModeChange={handleModeChange} mode={mode} options={{main : 'بيانات اساسية',stay : 'اقامات' , preStay : 'اقامات محتملة'}} />

        {
           permissions.addClient &&
          (
            <NewItemDialog>
              <AddNewClient/>
            </NewItemDialog>
          ) || <NotAvailable/>
        }
        {
         
         (
           permissions.addStay &&
           <NewItemDialog2>
             <AddNewStay/> 
           </NewItemDialog2>
           || <NotAvailable/>
         )
       } 
        {
            permissions.viewClient &&
            <ClientData modelId={showData}/> || null
        }
        {
            permissions.viewClient &&(
               
              <ClientData modelId={route}/>
            ) || <NotAvailable/>
        }
        <div>
        
          {
            permissions.viewClientTable &&(
              <div>
                <AllTables setClient={setShowData} setStay={setStay} mode={mode} />
              </div>
              // <ClientTable/>
            ) || <NotAvailable/>
          }
        </div>        
    </Page>
  )
}




function AllTables({setClient , setStay , mode })
{
  const [stayData ,setStayData] = useState([]);
  const [mainData ,setMainData] = useState([]);
  const [mainLoading ,setMainLoading] = useState(false);
  const [stayLoading ,setStayLoading] = useState(false);

  const loadData = async () => {
    setMainLoading(true);
    await clientModel.getAll(setMainData);
    setMainLoading(false);

    setStayLoading(true);
    await stayModel.getAll(setStayData);
    setStayLoading(false);
  }
  useEffect(() => {
    loadData();
  },[])

  useUpdateData(()=>{
    loadData();
  })

  
  return(
    <div>
      {
        mode == 'main' ?
      <MainEmpTable setClient={setClient} loading ={ mainLoading } data={mainData }  />
      : mode == 'stay' ?
      <StayEmpTable setStay={setStay} loading = { stayLoading } data={stayData}  />
      : mode == 'preStay' ?
      <PreStayTable/> 
      : ''
      
    }
    </div>
  )
}



const PreStayTable = () => {
  const [values , setValues] = useState();
  const [data , setData] = useState([]);
  const [loading , setLoading] = useState(false);
  
  const getMoveText = (text) => {
    switch (text) {
      case 'move':
        return (
          <Tag color="green">
            متحرك
          </Tag>
        )
        
      case 'semi-move':
        return <Tag color="orange">
        شبه متحرك
      </Tag>
      
      case 'disabled':
        return <Tag color="magenta">
          قعيد
        </Tag>  
    }
  }

  const columns = [
    {
      title: '#',
      dataIndex : 'id',
      key : 'id'
    },
    {
      title: 'التاريخ',
      dataIndex : 'date',
      key : 'date',
      render: (text) => model.dateToType(text)
    },
    {
      title: 'الأسم',
      dataIndex : 'name',
      key : 'name'
    },
    {
      title: 'العنوان',
      dataIndex : 'address',
      key : 'address'
    },
    {
      title: 'السن',
      dataIndex : 'age',
      key : 'id'
    },
    {
      title: 'الوزن',
      dataIndex : 'weight',
      key : 'weight'
    },
    {
      title: 'التحرك',
      dataIndex : 'ability',
      key : 'weight',
      render : (text) => getMoveText(text)
    },
    {
      title: 'التوصيلات',
      dataIndex : 'supplies',
      key : 'weight'
    },
    {
      title: 'المرافق',
      dataIndex : 'family',
      key : 'weight'
    },
    {
      title: 'معلومات',
      dataIndex : 'info',
      key : 'weight'
    },
    {
      title: 'السعر',
      dataIndex : 'price',
      key : 'weight'
    },
    {
      title: 'رقم الهاتف',
      dataIndex : 'phone',
      key : 'weight'
    },
  ]
  const loadData = async () => {
    setLoading(true);
    await stayModel.getAllPreStay(setData);
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  },[])
  
  const handleInput = (name , value) => {
    setValues(prev => ({...prev ,[name] : value }));
  }

  const handleSubmit = async () =>{
    setLoading(true);
    await stayModel.createPreStay(values);
    await loadData();
    setLoading(false);
  }

  return(
    
      <div>
        <div className=" w-full overflow-x-scroll scroll-style border-2 border-slate-200 rounded-lg " >
          <Table dataSource={data || []} columns={columns} loading = {loading} />
        </div>
        <div>
          <div>اضافة حقل جديد</div>
          <div className=" grid grid-auto-fit gap-2  mt-5 " >
            <TextField onChange = {handleInput} name = 'name'  placeholder= 'الأسم'  />
            <TextField onChange = {handleInput} name = 'address'  placeholder= 'العنوان' />
            <div className=" flex  gap-2 text-sm w-full " >
                <TextField onChange = {handleInput}  name = 'age' placeholder= 'السن' />
                <TextField onChange = {handleInput}  name = 'weight' placeholder= 'الوزن' />
            </div>
            <TextField onChange = {handleInput}  name = 'supplies' placeholder= 'التوصيلات' />
            <TextField onChange = {handleInput}  name = 'family' placeholder= 'المرافق' />
            <div className=" flex  gap-2 text-sm w-full " >
              <SelectBox onChange = {handleInput}  name = 'ability' placeholder= '' options ={{"move" : 'متحرك' , "disabled" : 'قعيد' , "semi-move" : 'شبه متحرك'}} />
              <TextField onChange = {handleInput}  name = 'price' placeholder= 'السعر' />
            </div>
            <TextField onChange = {handleInput}  name = 'phone' placeholder= 'الهاتف'  />
            <TextArea  onChange = {handleInput} name = 'info' placeholder= 'معلومات' />
            <Button OnClick={handleSubmit} type={'button'}  >
              تاكيد
            </Button>
          </div>
        </div>
      </div>
  )
}

function MainEmpTable({setClient , data = [] , loading = false })
{
  
  const [columns , setColumns] = useState([])
  const [srData ,setSrData] = useState([])
  const [searchText ,setSrchText] = useState(false)
  const [filterText ,setFilterText] = useState('name')

  useEffect(() => {
    if(searchText)
    {
      var newData = data.filter(a => a[filterText].toString().toUpperCase().includes(searchText.toString().toUpperCase())) 
      setSrData(newData);
    }
    else
      setSrData(data);

  } , [searchText , filterText , data])

  
  useEffect(() => {
    // console.log(data);
    if(srData.length > 0)
    {
      const keysArray = Object.keys(clientModel.MainStandardTable)
      var col = []
      keysArray.forEach(e => {
        col.push({
          title : clientModel.MainStandardTable[e] ,
          dataIndex : e,
          key : e,
          sorter : (a , b) => e == 'total' ? model.getTotal(a) - model.getTotal(b) :  a[e] - b[e] ,
          render : (text , record , index) => (e == 'address' )? model.getAddress(record) : text ,
          fixed : e == 'name'? 'left' : ''
        })
      })
      col.push({
        title : 'حدث' ,
        dataIndex : 'id',
        render : (e) => <Button type={'button'} OnClick={ () => setClient(e)} >عرض</Button> ,
        fixed : 'right'
      })
      setColumns(col);
    }
  },[srData])

  return(
    <div className=" pb-10 flex flex-col gap-4 "  >
      <SearchHeadBox  setSearchText={setSrchText} setFilterText = {setFilterText} options={{name : 'الأسم' , phone :'الهاتف' , cardId : 'الرقم القومي'}} defaultValue={'name'}  />
      <div dir="ltr" >
        <Table dataSource={srData} columns={columns} scroll={{x : 1500 , y : 600}} loading = {loading} />
      </div>
    </div>
  )
}


function StayEmpTable({setEmployee , data = [] , loading = false })
{
  const [columns , setColumns] = useState([])
  const [searchText , setSrchText] = useState(false)
  const [filterText , setFilterText] = useState('name')
  const [statusFilter , setStatusFilter] = useState('all')
  const navigator = useNavigate();
  
  const showDocument = (record) => {
    navigator('/docs/client/' + record.id )
  }

  const [fiData ,setFiData] = useState([])
  const [srData ,setSrData] = useState([])

  useEffect(() => {
    if(statusFilter == 'all')
    {
      setFiData(data);
    }
    else
    {
      var newData = data.filter(a => a.status == statusFilter) 
      setFiData(newData);
    }
  } , [statusFilter , data])

  
  useEffect(() => {
    if(searchText)
    {
      var newData = fiData.filter(a => a[filterText].toString().toUpperCase().includes(searchText.toString().toUpperCase())) 
      setSrData(newData);
    }
    else
      setSrData(fiData);

  } , [searchText , filterText , fiData])
  

  useEffect(() => {
    if(srData.length > 0)
    {
      const keysArray = Object.keys(stayModel.tableStanderd)
      var col = []
      keysArray.forEach(e => {
        // console.log(e);
        col.push({
          title : e == 'id' ? '#' : stayModel.tableStanderd[e],
          dataIndex : e,
          key : e,
          sorter : (a , b) => e == 'total' ? model.getTotal(a) - model.getTotal(b) : a[e] > b[e] ,
          render : (text , record ,index) => (e == 'workDate' || e == 'birthDate' || e == "startDate") ? model.dateToText(text) : 
          e == 'total' ? model.getTotal(record) : 
          e == "endDate" ? model.dateToText(model.getEndDate(record)) :
          e == 'status' ? model.getStatus(record) :
          e == 'subtract' ? record.salary - record.paid :
          e == 'workedDays' ? model.getWorkInPeriod(record) :
          text,
          fixed : e == 'patientName' || e == 'clientName' ? 'left' : ''
        })
      })
      col.push({
        title : 'حدث' ,
        dataIndex : 'id',
        render : (e , record) => <div className=" flex gap-2" >
          <Button type={'button'} OnClick={ () => setEmployee(e)} >عرض</Button> 
          <Button secondary type={'button'} OnClick={ () => showDocument(record)} ><IoDocument/></Button> 
        </div>, 
        fixed : 'right'
      })
      setColumns(col);
    }
  },[srData])

  return(
    <div className=" pb-10 flex flex-col gap-4 " >
      <SearchHeadBox setStatusText={setStatusFilter} setSearchText={setSrchText} setFilterText = {setFilterText} options={{clientName : 'اسم المسئول الأول' , secClientName : 'اسم المسئول الثاني' , patientName : 'اسم الحالة' , employeeName : 'اسم المقيم ' , phone :'الهاتف' , cardId : 'الرقم القومي'}} defaultValue={'patientName'} />
      <div dir="ltr" >
        <Table dataSource={srData} columns={columns} scroll={{x : 1500 , y : 600}} loading = {loading} />
      </div>
    </div>
  )
}



