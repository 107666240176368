import { useParams } from "react-router-dom";
import { EmployeeTable, SuppliesTable } from "../dashboard/containers/tables";
import { AddNewEmployee, AddNewEmployeeStay } from "../dashboard/containers/addNew";
import PageHeader from "../dashboard/asset/pageHeader";
import { FcAreaChart, FcManager } from "react-icons/fc";
import Page from "./page";
import { EmployeeData } from "../dashboard/containers/data/employee";
import { customDialogsEvents } from "../hooks/events";
import { useState } from "react";
import { NotAvailable } from "../dashboard/asset/notAvailable";
import { useGetPermissions } from "../hooks/customHook";
import Supplies from "../supplies";
import LoadingScreen from "../dashboard/asset/loading";








export default function SuppliesPage()
{
  const {route} = useParams();
  const [suppliesId , setSuppliesId] = useState(null);
  const permissions = useGetPermissions();
  customDialogsEvents.useSupplies(  e =>{
    setSuppliesId(e.detail.detail);
    })
  
  return(
  
    <Page title={'المخزن'} Icon={<FcAreaChart/>} >
      {
        permissions.job ? 
        <div className=" flex flex-col gap-4">
          <div >
            {
              permissions.addSupplies && 
              <Supplies id = {suppliesId} />
            }
          </div>
          <div>
            {
              permissions.viewSupplies && 
              <SuppliesTable/>
            }
          </div>
        </div>
        : <LoadingScreen/>
      }
    </Page>
    
  )
}