import { useParams } from "react-router-dom"
import { AddNewVisit } from "../dashboard/containers/addNew";
import Page from "./page";
import { FcInTransit } from "react-icons/fc";
import VisitsData, { SingleVisit, VisitsFilterData } from "../dashboard/containers/data/visit";
import { useEffect, useState } from "react";
import { visitModel } from "../../model/visit";



export default function FilterVisit()
{
  const {target , id} = useParams();

  return(
    <Page title={
      target == 'client' ?
      'زيارات العميل كود رقم ' + id
      : target == 'user' ? 
      'زيارات الموظف كود رقم ' + id :
      ' '
      } Icon={<FcInTransit/>}>
      <div>
        <VisitsFilterData id={id} target={target} />
      </div>
    </Page>
  )
}