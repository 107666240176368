
import { useEffect, useRef, useState } from "react";
import {  textValues } from "./assets";
import { BiErrorCircle } from "react-icons/bi";
import { useOutsideAlerter } from "../../hooks/customHook";

/**
 * 
 * @param {style} 
 * @returns 
 */


export default
function TextField({
  value,
  onChange = () => null,
  placeholder,
  name,
  type,
  err ,
  success ,
  children,
  title,
  empty,
  disabled,
  step,
  focusOut = () => null,
})
{
  const textRef = useRef(null)
  const [active, setActive] = useState(false);
  const FieldTitle = textValues.FieldTitle;
  const Child = textValues.ChildSection;
  useEffect(() =>{
    if(empty)
    {
      textRef.current.value = '';
    }
  } , [empty])

  useEffect(() => {
    textRef.current.value = value || '';
    // console.log(value)
    if(value)
    {
      setActive(true);
    }
  },[value])

  useOutsideAlerter(textRef , () => {
    focusOut();
  })

  const handleOnChange = (v) => {
    // console.log('has changed to : ' , v);
    onChange( name , v);
    return
  }

  return (
    <div
      onClick={ () => textRef.current.focus()}>
      <FieldTitle title={placeholder || title} name={name} show={active || ( textRef.current ?  textRef.current.value : false) || type == 'date' || type == 'time'}/>
      <div>
        <div className={textValues.fieldClassName(active , err , success)}>
          <Child children={children} textRef={textRef} active={active}/>
          <input
            ref={textRef}
            className={textValues.inputClassName}
              onChange={ e => handleOnChange(e.target.value) }
              onFocus={() => setActive(true)}
              onBlur={() => setActive(false)}
              type={type}
              name={name}
              id={name}
              step={step}
              defaultValue={value}
              disabled = {disabled}
            />
        </div>
        
        {
          err && 
          <div className=" text-red-500 flex text-sm items-center gap-3" >
              <BiErrorCircle/>
              <span >
                {
                  err
                }
              </span>
          </div>
        }
      </div>
    </div>
  )
}
