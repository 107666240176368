import { useEffect, useState } from "react";
import { blockRoute } from "../routes/routeAsset";
import { useCustomPageEvent, useUpdateData } from "./events";
import { employeeModel } from "../../model/employee";
import { model } from "../../model";
import Cookies from 'js-cookie'
import axios from "axios";
import { userModel } from "../../model/user";



export
const useGetAllLog = () => {
  axios.get( model.backEndServer +  '/user/get_all_logs')
  return
}


export
function useOutsideAlerter(ref , callback) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback()
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export const
useFilter = (oldData , key , value) =>
{
  //console.log(oldData)
  const [newData , setData] = useState({});
  useEffect(() => {
    let i = 0;
    oldData.map(e => {
      //console.log(e)
      if(e[key] == value)
        {
          i++
          setData(prev => ({...prev , [i] : e }))
        }
    })
    // return newData
  },[oldData , key])
  return newData
}


export function
useFindDateInArray(data , searchText)
{
  const [newData , setNewData] = useState();
  // //console.log(searchText);
  useEffect(()=>{
    const filter = () => {
      return data.filter(e =>
          (
            
            new Date(e.date).toLocaleDateString()
            .toString().toUpperCase().includes(searchText.toString().toUpperCase()) 
          )
        ) 
    }
    // //console.log(key)
    try{
      if(!searchText) setNewData(data);
      else setNewData(filter());
    }catch(err)
    {
      setNewData(data);
    }
  },[searchText , data])
  return newData
}





export function
useArrayFilter(data , searchText , key)
{
  // //console.log(searchText);
  const [newData , setNewData] = useState();
  useEffect(()=>{
    const filter = () => {
      return data.filter(e =>
          (
            e[key].toString().toUpperCase().includes(searchText.toString().toUpperCase()) 
          )
        ) 
    }
    try{
      // //console.log('from use array ', data , searchText);
      if(searchText == 'false'){
        setNewData(data);
      } 
      else setNewData(filter());
    }catch(err)
    {
      setNewData(data);
    }
  },[searchText , key , data ])
  return newData || []
}

export function
useFindInArray(data , searchText , setNewData , key)
{
  // console.log(key);
  useEffect(()=>{
    const filter = () => {
      return data.filter(e =>
        (
            e[key].toString().toUpperCase().includes(searchText.toString().toUpperCase()) 
          // keys.map(key => (
          // ))
          // keys.map( key =>
          // )
          )
        ) 
    }
    try{
      setNewData(filter());
    }catch(err)
    {
      setNewData([]);
    }
  },[searchText , key , data ])
}

export
function useDelay(callback = () => null , sec = 1){
  const [count , setCount] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + 1);
    }, 1000 * sec);
    callback();
    return () => clearInterval(interval);
  },[count])
}


export
const useRefDimensions = (ref) => {
  const [dimensions , setDimensions] = useState([0 , 0]);
  useEffect(() => {
    if(ref.current){
      const {current} = ref;
      const boundingRect = current.getBoundingClientRect();
      const { width , height} = boundingRect;
      setDimensions({width: Math.round(width) , height : Math.round(height)})
    }
    return
  } , [ref])
  return dimensions;
}


export const useChangeWindowSize = () =>
{
  const [size , setSize] = useState([0 , 0]);

  function updateSize() {
    setSize([window.innerWidth ,window.innerHeight])
  }
  useEffect(() => {
    window.addEventListener('resize' , updateSize );
    updateSize();
    return () => window.removeEventListener('resize' , updateSize);
  } , [])
  return size;
}


export const useCheckBlockRoute = () =>
{
  const [block , setBlock] = useState(false);
  const [currentRoute , setCurrentRoute] = useState();
  useCustomPageEvent((e) => {
    setCurrentRoute(e.detail);
  })
  useEffect(() => {
    setBlock(false)
    blockRoute.forEach(e => {
      if(e == currentRoute)
        {
          setBlock(true)
        } 
      });
    } , [currentRoute])
    // //console.log( 'bloc is ', currentRoute)
  return [block , currentRoute];
}


export const useGetAllJobs = ()=>{
  const [jobsData , setJobsData] = useState({});
  const [jobs , setJobs] = useState({});
  
  useEffect(() => {
    employeeModel.getAllJobs(setJobsData);
  },[])
  useEffect(() => {
    Object.keys(jobsData).map(e =>{
      // e != 0 && 
      setJobs(prev => ({...prev , [jobsData[e].id] : jobsData[e].job }))
    } 
  )
  },[jobsData])
  // //console.log('jobs is ', jobs);
  return jobs;
}

export const
useGetPermissions = (target , update) =>{
  const [perm , setPerm] =useState({});
  const [loading ,  setLoading] = useState(false);
  const loadData = async () => {
    setLoading(true);
    if(sessionStorage.getItem('permissions_'))
    {
      setPerm(JSON.parse(sessionStorage.getItem('permissions_')))
    }
    else
    {
      const token = Cookies.get('_auth');
      await axios.get( model.backEndServer + '/permission/get_permission' , {headers : {'access-token' : token, }})
      .then(res => {
        // console.log('my per is ', res.data[0]);
        setPerm(res.data[0])
        sessionStorage.setItem('permissions_' , JSON.stringify(res.data[0]) );
      }).catch(err =>
        {
          console.log(err)   
        }
      );
      setLoading(false);
    }

  }
  useEffect(() => {
    loadData();  
  },[])


  return  perm ;
}


export const 
useGetPermissionsKeys = () => {
  const [keys , setKeys] = useState({});
  const token = Cookies.get('_auth');

  const getData = async () => {
     await axios.get( model.backEndServer +  '/permission/get/keys' , {headers : {'access-token' : token, }})
    .then(res => {
      //console.log('my per is ', res);
      setKeys(res.data);
    }).catch(err =>
      {
        //console.log('my per is ', err);
        console.log(err) 
        // permissions = err   
      }
    );
  }

  useUpdateData(() => {
    getData();
  })
  useEffect(() => {
    getData();
  },[])
  //console.log(keys)
  return  keys
}