import { useEffect, useState } from "react";
import { BiCart, BiCheck, BiRightIndent, BiX } from "react-icons/bi";
import { CiShop } from "react-icons/ci";
import { FaRegHospital, FaUserDoctor } from "react-icons/fa6";
import { FcAdvertising, FcAssistant, FcCallTransfer, FcDocument, FcHome, FcManager, FcQuestions, FcRight } from "react-icons/fc";
import { PiHospital } from "react-icons/pi";
import { MdBusinessCenter, MdTransitEnterexit } from "react-icons/md";


function Box({text , theme , user , inline })
{
  return(
    <div className={`  p-1 border rounded-md px-2 items-center gap-2 flex ${inline && ' flex-col '} justify-center
      ${theme == 'red' && ' bg-red-50 text-red-700 border-red-600 '}
      ${theme == 'blue' && ' bg-blue-50 text-blue-700 border-blue-600 '}
      ${theme == 'purple' && '  bg-purple-50 text-purple-700 border-purple-600 '}
      ${theme == 'green' && ' bg-green-50 text-green-700 border-green-600 '}
    `}>
      <div>
        {
          text
        }
      </div>
      <div>
        {
          user.stopDate &&
          (user.status == 'stop' || user.status == 'waiting') && 
          model.dateToText(user.stopDate)
        }
      </div>
    </div>
  )
}

export
const model ={

  getDataFromCache :async (cacheName , url) =>
  {
      const cacheStorage = await caches.open(cacheName);
      const cacheResponse = await cacheStorage.match(url);
      let data = await cacheResponse.json();
      return data;
  },
  
  addDataIntoCache : async (cacheName , url , response) =>
  {
      const data = new Response(JSON.stringify(response));
      console.log(data);
      if('caches' in window)
      {
        caches.open(cacheName).then((cache) => {
          cache.put(url , data);
          alert('data added to cache !');
        })
      }
  },
  getGender : (user) =>
{
  switch (user.gender) {
    case 'male':
      return "ذكر"
      break;
  
    case 'female':
      return "انثي"
      break;
  
    default:
      return user.gender
      break;
  }
},


getPaymentAction : (payment) => {
  let value = ' '
  switch (payment.action) {
    case 'pay':
      value = 
      <div>
        <span>
        {
          'ايداع'
        }

        </span>
      </div>
      
      break;
    case 'take':
      value =
      <div>
        <span>
        {
          'سحب'
        }

        </span>
      </div>
      
      break;
    case 'additional':
      value = 
      <div>
        <span>
        {
          'اضافي'
        }

        </span>
      </div>
      
      break;
    case 'deduct':
      value = 
      <div>
        <span>
        {
          'خصم'
        }

        </span>
      </div>
      
      break;
    case 'buy':
      value = 
      <div>
        <span>
        {
          'شراء'
        }

        </span>
      </div>
      
      break;
    case 'sale':
      value = 
      <div>
        <span>
        {
          'بيع'
        }

        </span>
      </div>
      
      break;
  
    default:
      break;
  }
  return(
    <div>
      {
        value
      }
    </div>
  )
},
getMoneyTransaction : (payment) => {
  return(
  <div className=" flex justify-between" >
    <div className=" flex gap-2 items-center">
        <div className={` ${payment.amount > 0 ? '  text-green-400 ' : ' rotate-180 text-purple-400 ' }  text-3xl w-fit bg-slate-100 rounded-full `}>
            <MdTransitEnterexit/>
        </div>
        <span>
          {
            payment.amount > 0 ?
            'داخل' :
            'خارج'
          }
        </span>
      </div>
      <div className=" font-bold text-gray-500 flex gap-2" >
        <span>
          EGP
        </span>
        {
          payment.amount > 0 ?
          payment.amount : payment.amount * -1
        }
      </div>
  </div>

  )

},


convertObjToNameAndId : (obj = [], target) => {
  let newObj = obj;
  if(newObj.length > 0)
  newObj = newObj.map(e => {
    e['name'] = e[target];
    delete e[target]
    return e;
  })
  return newObj;
},


getDealIcon : (deal) => {
  var icon = null
  let target = ''
  switch (deal.section) {
    case 'pharmacy':
      icon = <CiShop/>
      target="صيدلية"
      break;
    case 'doctor':
      icon = <FaUserDoctor/>
      target="دكتور"

      break;
    case 'hospital':
      icon = <FaRegHospital/>
      target="مستشفي"

      break;
    case 'center':
      icon = <MdBusinessCenter/>
      target="مركز"

      break;
      
    default:
      break;
  }
  return(
    <div className=" flex items-center gap-2" >
      <div className=" text-sky-500" >
        {
          icon
        }
      </div>
      <div className=" text-lg text-gray-400" >
        <span>
        تعاقدات
        </span>
        <span>
          (
            {
              target
            }
          )
        </span>
      </div>
    </div>
  ) 

},

getTargetSection : (target) => {

  switch (target.targetSection) {
    case 'client':
      return 'العميل'
    case 'employee':
      return 'الموظف'
    case 'stay':
      return 'الأقامة'
    case 'apartment':
      return 'المنشأة / التعاقد'
  
    default:
      break;
  }
},

getGainPercentage : (data) => {

  let total = data.cost  - data.wholesale 
  return total 
},

getTotalGain : (data) => {
  let sum = model.getGainPercentage(data) * data.count ;

  return(
    <div className=" bg-green-500 text-white p-2 text-center rounded-lg" >
      {
        sum
      }
    </div>
  ) 
},


  getSearchResult : (result) =>{
    //// id ,, section ,, name
    let route = '';
    let icon = null;
    let title = '';

    switch (result.section) {
      case 'employee':
        route = '/employee'
        icon = <FcAssistant/>
        title = 'موظف'
        break;
      case 'client':
        route = '/client'
        icon = <FcManager/>
        title = 'عميل'
        break;
      case 'emDoc':
        route = '/docs/employee'
        icon = <FcDocument/>
        title = 'بند عقد موظف'
        break;
      case 'clDoc':
        route = '/docs/client'
        icon = <FcDocument/>
        title = 'بند عقد عميل'
        break;
      case 'stay':
        route = '/stay'
        icon = <FcHome/>
        title = 'اقامة'
        break;
      case 'supplies':
        route = '/supplies'
        icon = <BiCart/>
        title = 'منتج'
        break;
      case 'visit':
        route = '/visit'
        icon = <FcCallTransfer/>
        title = 'زيارة'
        break;
      case 'job':
        route = '/job_permissions'
        icon = <FcQuestions/>
        title = 'وظيفة'
        break;
      case 'request':
        route = '/requests'
        icon = <FcAdvertising/>
        title = 'طلب'
        break;
      default:
        break;
    }

    return {
      route,
      icon , 
      title
    }
  },


  timeToType : (date ) => {

    
    const now = new Date();
    const oldDate = new Date(date);

    const diffInMilSec = Math.abs(now - oldDate);
    const diffInMin = diffInMilSec / ( 1000 * 60 )
    const diffInHours = diffInMin / 60
    
    // console.log(now , oldDate , diffInMilSec , diffInMin , diffInHours )
    
    if(diffInMin < 1 )
      return 'الأن'
    else if(diffInMin < 60)
      return Math.floor(diffInMin) + ' د '
    else 
    if(diffInMin > 60 && diffInHours < 24 )
      return Math.floor(diffInHours) + ' س '
    else 
      return model.dateToType(date)
  },


  dateToType : (date , showyear/*addDate = null */ ) =>
{
    
  const today = new Date();
  var newDate = new Date(date);
  var substract = today - newDate;
  var DaysNumber = Math.floor(substract / (1000 * 60 * 60 * 24));
  var text = ''
  if(DaysNumber == 0)                         text = 'اليوم'
  else if(DaysNumber == 1)                    text = 'امس'
  else if(DaysNumber > 1 && DaysNumber < 7 )  text = `منذ ${DaysNumber} ايام`
  else if(DaysNumber == 7)                    text = 'اسبوع'
  else if(DaysNumber > 7)                     text = date
  else text = model.dateToText(date)
  return text;
},

  dateToText : (d , showyear = false , /*addDate = null */ ) =>
{
    
    var  date = d == "today" ? new Date() : new Date(d);
    let  day = date.getDate() ;
    let  old_month = date.getMonth() + 1 
    let  month = '';
    let  year = date.getFullYear() ;
    switch (old_month) {
      case 1:
        month = "يناير"
        break;
      case 2:
        month = "فبراير"
        break;
      case 3:
        month = "مارس"
        break;
      case 4:
        month = "ابريل"
        break;
      case 5:
        month = "مايو"
        break;
      case 6:
        month = "يونيو"
        break;
      case 7:
        month = "يوليو"
        break;
      case 8:
        month = "اغسطس"
        break;
      case 9:
        month = "سبتمبر"
        break;
      case 10:
        month = "اكتوبر"
        break;
      case 11:
        month = "نوفمبر"
        break;
      case 12:
        month = "ديسمبر"
        break;
    
      default:
        month = old_month;
        break;
    }
    let newDate;
    let now = new Date();
    if(now.getFullYear() == year && !showyear)
    {
      newDate = day + " - " + month;
    }
    else
    {
      newDate = day + " - " + month + " - " + year;
    }
    return newDate;
},




  getAge : (user) =>
{
    var birthDate = new Date(user.birthDate);
    var now = new Date();
    let age = (now - birthDate ) / (1000 * 60 * 60 * 24 * 30 * 12 );
    return (Math.floor(age)) + ' سنة ';
},

timeToText : (time) => {
  let now = new Date()
  let newTime = new Date(now.toLocaleDateString() +' ' + time);
  // newTime.setTime(newTime.getTime() - (60*60*1000 * 12))
  // //console.log( time , newTime.toTimeString())
  
  return newTime.toLocaleTimeString();
},

getTime : (time) => {
  let xTime = new Date(time);
  return xTime;
},

getDateToInput : (d) => {

  let validDate = new Date(d || 0) 
  validDate.getDate(validDate.setDate() + 1)
  console.log(d);
  return validDate;
},

visitCollected : (visit) => {
  function StatusBar({text , color})
  {

    return(
      <div className={`  p-1 border rounded-md px-2 items-center flex justify-center
        ${color == 'red' && ' bg-red-50 text-red-700 border-red-600 '}
        ${color == 'blue' && ' bg-blue-50 text-blue-700 border-blue-600 '}
        ${color == 'purple' && '  bg-purple-50 text-purple-700 border-purple-600 '}
        ${color == 'lime' && '  bg-lime-50 text-lime-700 border-lime-600 '}
        ${color == 'green' && ' bg-green-50 text-green-700 border-green-600 '}
      `}>
        {
          text
        }
      </div>
    )
  }
  if (!visit.collected) {
      return<StatusBar text = 'غير محصل' color={'red'} />
      
    }
    else
      return<StatusBar text = 'تم التحصيل' color={'green'} /> 
  
},
visitStatus : (visit) => {
  function StatusBar({text , color})
  {

    return(
      <div className={`  p-1 border rounded-md px-2 items-center flex justify-center
        ${color == 'red' && ' bg-red-50 text-red-700 border-red-600 '}
        ${color == 'blue' && ' bg-blue-50 text-blue-700 border-blue-600 '}
        ${color == 'purple' && '  bg-purple-50 text-purple-700 border-purple-600 '}
        ${color == 'lime' && '  bg-lime-50 text-lime-700 border-lime-600 '}
        ${color == 'green' && ' bg-green-50 text-green-700 border-green-600 '}
      `}>
        {
          text
        }
      </div>
    )
  }
  switch (visit.status) {
    case 'waiting':
      return<StatusBar text = 'انتظار'  color={'blue'} />
    
    case 'approved':
      return<StatusBar text = 'ف الطريق' color={'purple'} />
    
    case 'canceled':
      return<StatusBar text = 'الغاء' color={'red'} />
    
    case 'arrived':
      return<StatusBar text = 'تم الوصول' color={'lime'} />
    
    case 'done':
      return<StatusBar text = 'تمت' color={'green'} /> 
    
  }
  return 'ss'
},

getDateWithDay : (date) => {
  let xTime = new Date(date);
  let today = new Date();
  // if(xTime.getDate)
    // toDo ,,// ..// ....///
  return xTime;
},

  getEndDate : (user) =>
{
  let firstDate = new Date(user.startDate);
  let secondDate = firstDate ;
  if(user.wholeTime == 'day')
    secondDate.setDate(secondDate.getDate() + user.workDays);
  else if(user.wholeTime == 'week')
    secondDate.setDate(secondDate.getDate() + 7 );
  else if(user.wholeTime == 'month')
    secondDate.setMonth(firstDate.getMonth() + 1 , firstDate.getDate())
  else 
    secondDate.setFullYear(firstDate.getFullYear() + 1, firstDate.getMonth() , firstDate.getDate())

  // //console.log(secondDate);
  return (secondDate)
},
  getWorkedDays : (user) =>
{
  var firstDate = new Date(user.startMonthDate);
  // //console.log(firstDate);
  var secondDate = user.status == 'working' ? new Date() : new Date(user.stopDate);
  var diffInDays = (secondDate - firstDate) / ( 1000 * 60 * 60 * 24);
  return Math.round(diffInDays - user.absent);
},
  getTotal : (user) =>
{ 
  
  var dayCost ;
  if(user.wholeTime == 'day')
    dayCost = (user.salary / user.workDays)
  else
  if(user.wholeTime == 'week')
    dayCost = (user.salary / 7)
  else
  if(user.wholeTime == 'month')
    dayCost = (user.salary / 30)
  else
    dayCost = (user.salary / 360)

  var totalDaysCosts = dayCost * model.getElapsedDays(user);
  var freePeriodCost = (user.freePeriod - user.absent) * dayCost;
  var total = totalDaysCosts + (user.additional || 0 ) - user.paid + freePeriodCost;
  return total.toFixed(2);
},
  getLeftDays : (user) =>
{
  var toDay = new Date()
  var subtract =
  (model.getEndDate(user) - toDay) / (1000 * 60 * 60 * 24)
  return Math.round(subtract)
},
  getElapsedDays : (user) =>
{
  var toDay = new Date()
  var startDate = new Date(user.startDate)
  var stopDate = new Date(user.stopDate)
  var subtract ;
  if(user.status == 'working')
    subtract = ( toDay - startDate ) / (1000 * 60 * 60 * 24)
  else
    subtract = ( stopDate - startDate ) / (1000 * 60 * 60 * 24)

  return Math.round(subtract) - (user.absent || 0)
},

  getJob : (user) =>
{
  switch (user.job) {
    case "manager":
      return "مدير";
      break;
    case "preManager":
      return "نائب مدير";
      break;
    case "callCenter":
      return "خدمة عملاء";
      break;
    case "sales":
      return "مبيعات";
      break;
    case "nurse":
      return "تمريض";
      break;
  
    default:
      break;
  }
},

  getRequestStatus : (user) =>
{
  


  switch (user.status) {
    case "done":
      return <Box user={user} theme= 'green' text = "تم" />;
    case "waiting":
      return <Box user={user} theme= 'purple' text = "انتظار" />;
    default:
      break;
  }
},
  getStatus : (user , inline) =>
{
  
  switch (user.status) {
    case "working":
      return <Box user={user} theme= 'green' text = "شغال" />;
      break;
    case "stop":
      return <Box user={user} theme= 'red' text = "متوقف" />;
      break;
    case "available":
      return <Box user={user} theme= 'purple' text = "متاح" />;
      break;
    case "waiting":
      return <Box user={user} theme= 'blue' text = "اجازة" />;
      break;
  
    default:
      break;
  }
},
getAddress:(user) => {
  const total = 
    ' شقة رقم ' + user.flat 
  + ' , عمارة رقم ' + user.block
  + ' , منطقة ' + user.area
  + ' , مدينة ' + user.city
  
  return total
},

getVisitAddress:(user , visit) => {
  const total = 
    ' شقة رقم ' + ( visit.flat || user.flat   ) 
  + ' , عمارة رقم ' + ( visit.block || user.block   )
  + ' , منطقة ' + ( visit.area || user.area   )
  + ' , مدينة ' + ( visit.city || user.city   )
  
  return total
},

getWorkInPeriod : (user) =>{
  let num = model.getElapsedDays(user);
  let wholeTime = '' ;
  if(user.wholeTime == 'day')
  {
    wholeTime = user.workDays + ' يوم '
  }
  else
  if(user.wholeTime == 'week')
    {
    wholeTime = 'الأسبوع'
    
  }
  else
  if(user.wholeTime == 'month')
    {
    wholeTime = 'الشهر'
    
  }
  else
  {
    wholeTime = 'السنة'
  }
  

  // return 24 / الشهر 
  // return 3 / 15
  return num + ' يوم ' + ' من ' + wholeTime
},
getFreeInPeriod : (user) =>{
  let num = user.absent;
  let freePeriod = user.freePeriod;
  let wholeTime = '' ;
  if(user.freePeriodType == 'day')
  {
    wholeTime =  ' يوم '
  }
  else
  if(user.freePeriodType == 'week')
    {
    wholeTime = 'الأسبوع'
    
  }
  else
  if(user.freePeriodType == 'month')
    {
    wholeTime = 'الشهر'
  }
  
  

  // return 24 / الشهر 
  // return 3 / 15
  return num  + ' من ' + freePeriod + ' ' + wholeTime
},

  // backEndServer : process.env.BACKEND_SERVER,
  // backEndServer : "http://localhost:4000",
  backEndServer : process.env.REACT_APP_BACKEND_SERVER,

}