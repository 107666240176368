
import { Link } from 'react-router-dom'

export
function Field({ children , ownKey , value , reference})
{
  return(
    <div className=" flex gap-3 items-center">
          <span className=" text-slate-400">
            {
              children || ownKey
            }
          </span>
          <span>
            :
          </span>
          {
            reference ?
            <Link to={reference}>
              <span>
                {
                  value
                }
              </span>
            </Link>
            : 
            <span>
              {
                value
              }
            </span>
          }
    </div>
  )
}