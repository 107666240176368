


import { useEffect, useState } from 'react';
import { MdCreate, MdEdit, MdMessage, MdUpdate } from 'react-icons/md';
import { employeeModel } from '../../../../../model/employee';
import { BiCheck, BiHome, BiMessage, BiNotification, BiUser, BiUserCheck, BiUserCircle } from 'react-icons/bi';
import { FcAssistant, FcHome, FcInTransit, FcManager } from 'react-icons/fc';
import { userModel } from '../../../../../model/user';
import { IoCreate, IoCreateOutline, IoCreateSharp } from 'react-icons/io5';
import { FaMessage } from 'react-icons/fa6';
import { PiSignIn } from 'react-icons/pi';

// 'employee_log',
// 'client_log',
// 'stay_log',
// 'visit_log',
// 'supplies_log',
// 'notes_log',
// 'user_log',
// 'pricing_log',
// 'requests_log',
// 'jobs_log',
// 'payment_log',
// 'deals_log',
// 'employee_doc_log',
// 'client_doc_log'

const
vocabulary = {
  employee : 'موظف',
  client : 'عميل',
  stay : 'اقامة',
  visit : 'زيارة',
  supplies : 'مستلزمات',
  notes : 'ملاحظة',
  user : 'مستخدم',
  pricing : 'تسعير',
  requests : 'طلب',
  jobs : 'صلحيات',
  payment : 'عملية دفع',
  deals : 'تعاقد',
  employee_doc : 'عقد الموظفين',
  client_doc : 'عقد العملاء',
  ///////
  name : 'اسم',
  job  : 'وظيفة',
  patientName: 'اسم الحالة',
  gender : 'جنس',
  phone : 'رقم هاتف',
  phone2 : 'رقم الهاتف الأحطياطي',
  cardId : 'الرقم القومي',
  birthDate : 'تاريخ الميلاد',
  workDate : 'تاريخ العمل',
  address : 'العنوان',


  ///// visit stuff
  done : 'تمم',
  arrived : 'وصل',
  approved : 'قبل',
  waiting : 'استعاد',
  canceled : 'قام بألغاء',
  collected : 'تم التحصيل',
}

  
  const loginText = ( notifyLog , userId ) => {
    
    return(
          ` قام ( ${ userId == notifyLog.user_id ? ' انت ' :  notifyLog.user_name} ) بتسجيل الدخول الي السيستم  `  
    )
  }
  const createText = ( notifyLog , userId ) => {
    
    return(
          ` قام ( ${ userId == notifyLog.user_id ? ' انت ' :  notifyLog.user_name} ) بأنشاء ${vocabulary[notifyLog.section]} بأسم ( ${ notifyLog.model_name } ) `  
    )
  }


  const editText = ( notifyLog , userId) => {
    
    if(notifyLog.section == 'visit')
      return editVisitText(notifyLog , userId);
    return(
    
          ` قام ( ${ userId == notifyLog.user_id ? ' انت ' :  notifyLog.user_name} ) بتعديل ${vocabulary[notifyLog.unique_key] || notifyLog.unique_key } ${vocabulary[notifyLog.section]} بأسم ${ notifyLog.model_name } من (${notifyLog.old_value}) الي (${ notifyLog.new_value}) `  
    )
  }
  
  
  const editVisitText = (target , userId) =>
  {
    let text = ` ${vocabulary[target.new_value] || target.new_value} ( ${ userId == target.user_id ? 'انت' :  target.user_name } )  الزيارة بأسم حالة ( ${target.model_name} )   `
  
    return text 
  }

  
  export
  const getNotificationBody = ( notifyLog , userId ) => {
      let target = {}
      if(notifyLog.unique_key === 'create')
      {
        target =
          {
            actionIcon : <IoCreateOutline/>  ,
            topic : createText(notifyLog , userId),
            icon : <MdMessage/>
          }
          
        }
      else if(notifyLog.unique_key === 'login')
      {
        target =
          {
            actionIcon : <PiSignIn/>  ,
            topic : loginText(notifyLog , userId),
            icon : <BiUserCircle/>
          }
          
        }
      else
      {
        target =
        {
          actionIcon : <MdUpdate/>  ,
          topic : editText(notifyLog , userId),
          icon : <MdMessage/>
        }
      }
    return target 
  }
  