import { useParams } from "react-router-dom";
import { FcMoneyTransfer } from "react-icons/fc";
import Page from "./page";
import { customDialogsEvents } from "../hooks/events";
import { useGetPermissions } from "../hooks/customHook";
import AddNewPricing from "../pricing";
import LoadingScreen from "../dashboard/asset/loading";
import { useState } from "react";
import { PricingTable } from "../dashboard/containers/tables";


export default function PricingPage()
{
  const {route} = useParams();
  const [pricingId , setPricingId] = useState(null);
  const permissions = useGetPermissions();
  customDialogsEvents.useSupplies(  e =>{
    setPricingId(e.detail.detail);
    })
  return(
    
    <Page title={'التسعير'} Icon={<FcMoneyTransfer/>} >
      {
        permissions.job ?
        <div className=" flex flex-col gap-4" >
          {
            permissions.addPricing && 
            <AddNewPricing id = {pricingId || route} />
          }
          {
            permissions.viewPricing && 
            <PricingTable/>
          }
        </div>
        :
        <LoadingScreen/>
      }
      
    </Page>
    
  )
}