import { CiEdit } from "react-icons/ci"
import Button from "./button"
import { BiSort, BiTable } from "react-icons/bi"
import { PiColumns, PiRows } from "react-icons/pi"
import { useState } from "react"


export
default
function ScrollableDataView({children , title , icon })
{
  const [vertical , setVertical] = useState(true);

  function DataHeader() {
    return(
      <div className=" flex justify-between p-4" >
        <div className=" text-xl font-bold flex items-center gap-2" >
          <span className=" text-sky-500 " >
            {
              icon
            }
          </span>
          <span>
            {
              title
            }
          </span>
        </div>
        <Button secondary  type={'button'} OnClick={toggleVertical} >
          {
            !vertical ?
            <PiColumns/>
            :
            <PiRows/>
          }
        </Button> 
      </div>
    )
  }


  const toggleVertical = () => {
    setVertical(prev => !prev);
  }
  

  return(
    <div className=" w-full relative flex flex-col  bg-slate-400/10 rounded-lg " >
        <DataHeader/>
        <div className="  overflow-x-scroll scroll-style p-4 " >
          <div className={` ${ vertical ? '  relative  flex ' : ' grid grid-auto-fit-sm ' } gap-4  w-fit `} >
            {
              children
            }
          </div>
      </div>

  </div>  
  )
}




