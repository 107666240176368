import { createRef, useEffect, useRef, useState } from "react";
import { employeeModel } from "../../../../../model/employee";
import Container from "../../../container";
import Button, { TabButton } from "../../../asset/button";
import { model } from "../../../../../model";
import { useDelay } from "../../../../hooks/customHook";
import TextField from "../../../asset/textField";
import SelectBox from "../../../asset/selectBox";
import { LineNotify } from "../../../asset/notify";
import { gender, periods, stayStatus } from "../../../asset/assets";
import FindField from "../../../asset/findField";
import { stayModel } from "../../../../../model/stay";
import { clientModel } from "../../../../../model/client";
import { Field, ScrollCard, ScrollableCard } from "../assets";
import { useNavigate } from "react-router-dom";
import { SpliterDiv } from "../../addNew";








export
function StayData({modelId})
{
  const navigate = useNavigate();
  const [values , setValues] = useState({id : modelId})
  const [currentCard , setCurrentCard] = useState(0);
  const [mainStayData , setMainStayData] = useState({});
  const [employeeData , setEmployeeData] = useState({});
  const [employeeStayData , setEmployeeStayData] = useState({});
  const [clientData , setClientData] = useState({});
  const [secondClientData , setSecondClientData] = useState({});
  const [emptyFiled , setEmptyField] = useState({});

  useDelay(()=> {
    stayModel.getById(modelId , setMainStayData);
  },2)
  useEffect(() => {
    stayModel.getById(modelId , setMainStayData);
  } , [modelId])

  
  useEffect(() => {
    clientModel.getById(mainStayData.mainClientId , setClientData);
    clientModel.getById(mainStayData.secondClientId , setSecondClientData);
    employeeModel.getByStayId(mainStayData.id , setEmployeeStayData)
  } , [mainStayData])
  
  useEffect(() => {
    employeeModel.getById(employeeStayData.employeeId , setEmployeeData);
  } , [employeeStayData])
  

  

  const [customLog , setCustomLog] = useState({});
  const handleInput = (name , value) => {
    setCustomLog({})
    setValues(prev => ({...prev , [name] : value }));
    setValues(prev => ({...prev , id : modelId }));
  }
  
  const setChanges =async (e) =>{
    e.preventDefault()
    if(currentCard == 1) /// update main data
    {
      const res = await stayModel.update(values)
      console.log(res);
      if(res.status === 200)
        {
          setEmptyField(true);
          setCustomLog(prev => ({...prev , msg : 'تمت التغيرات بنجاح' , success : true}))
          setCurrentCard(0)
          // setEmptyField(false);
        }
        else
        {
          setCustomLog(prev => ({...prev , msg : res.data.msg , err : true , success : false}))
        }
    }
  }

  const [width , setWidth] = useState();
  return(
    modelId > 0 &&
    <>
      <Container onSubmit={setChanges}  title={
        <div className=" flex justify-between ">
        <span>
          {
            mainStayData.patientName 
          }
        </span>
        <span>
          {
            model.getStatus(mainStayData)
          }
        </span>
        </div>
      } title2={'إقامة'}
        
        header={
          <div className=" ">
            
            <TabButton activeColor="gray-100"  active={currentCard == 0} OnClick={() => {setCurrentCard(0)}} secondary type='button'>
              بيانات الأقامة
            </TabButton>
            <TabButton activeColor="gray-100"  active={currentCard == 1} OnClick={() => {setCurrentCard(1)}}  secondary type='button'>
              تعديل 
            </TabButton>
            
          </div>
        }
        footer={ 
          
          <>
          {
          (currentCard == 1) && 
            <Button>
              تأكيد التغيرات
            </Button>
          }
          <Button secondary OnClick={() => {navigate('/docs/client/'+mainStayData.id)}} >
            طباعة العقد
          </Button>
          </>
        }
      >
          <ScrollableCard currentCard={currentCard} setWidth={setWidth} >
            <StayDataCard stayData={mainStayData}  width={width} employeeData={employeeData} clientData={clientData} secondClientData = {secondClientData} />
            <UpdateMainDataCard stayData={mainStayData} width = {width}  customLog = {customLog} handleInput = {handleInput} emptyFiled={emptyFiled} />
          </ScrollableCard>
      </Container> 
    </>
  )
}




function UpdateMainDataCard({width , stayData = {} , customLog , handleInput , emptyFiled , values = {}})
{
  const [clients , setClients] = useState({});
  useEffect(() => {
    clientModel.getAll(setClients);
  },[])

  return(
    <ScrollCard width={width} >
                <>
              <FindField data = {clients} type = 'text' title = {' المسئول عن الحالة'}  name = 'mainClientId' onChange = {handleInput}/>
              <FindField data = {clients} type = 'text' title = {' المسئول عن الحالة الثاني'}  name = 'secondClientId' onChange = {handleInput}/>
              <TextField empty = {emptyFiled} type = 'text' title = {'اسم الحاله'}  name = 'patientName' onChange = {handleInput}/>
              <SpliterDiv>
                <SelectBox options = {gender} title = {'النوع'}  name = 'gender' onChange = {handleInput}/>
                <TextField empty = {emptyFiled} type = 'number' title = {'السن'} name = 'age' onChange = {handleInput}/>
              </SpliterDiv>
              <SpliterDiv>
                <TextField empty = {emptyFiled} type = 'number' placeholder = 'الراتب' name = 'salary' onChange = {handleInput}/>
                  <span>في</span>
                <SelectBox options = {periods} value = 'month' name = 'wholeTime' onChange = {handleInput}/>
              </SpliterDiv>
              {
                values.wholeTime == 'day' &&
                <TextField empty = {emptyFiled} type = 'number' title = {'ايام العمل'}  name = 'workDays' onChange = {handleInput}/>
              }
              <SpliterDiv>
                <TextField empty = {emptyFiled} type = 'number'  title = {'عدد سعات العمل'}  name = 'hours' onChange = {handleInput}/>
              </SpliterDiv>
              <SpliterDiv>
                <TextField empty = {emptyFiled} type = 'number' title = {'الأجازة'}  name = 'freePeriod' onChange = {handleInput}/>
                <SelectBox options = {periods} name = 'freePeriodType' onChange = {handleInput}/>
                <span>كل</span>
                <SelectBox options = {periods} value = 'month' name = 'freePeriodMain' onChange = {handleInput}/>
              </SpliterDiv>
              <SpliterDiv>
                <SelectBox options = {stayStatus} value = {stayData.status} name = 'status' placeholder = 'الحالة' onChange = {handleInput} />
                {
                  values.status != 'working' &&
                  <TextField placeholder = 'تاريخ التوقف' empty = {emptyFiled} type = 'date'  name = 'stopDate' onChange = {handleInput}/>
                }
              </SpliterDiv>
              
            </>
        <LineNotify err ={customLog.err} success={customLog.success} alert={customLog.alert} text = {customLog.msg}/>
        </ScrollCard>
  )
}


function StayDataCard({stayData = {}  , width , secondClientData = {} , clientData = {} , employeeData = {}})
{
  
  return(
    <ScrollCard width={width} >
    {
      stayData.id ?
      <>
        <Field ownKey={'اسم الحالة'} value={stayData.patientName}  />
        
          <div className="flex gap-4 pr-5">
            <span>
              {
                stayData.age
              }
            </span>
            <span>
              سنة
            </span>
            <span>
              {
                model.getGender(stayData)
              }
            </span>
            
          </div>
          
        <Field ownKey={' المسئول الأساسي'} value={clientData.name} reference={`/client/${clientData.id}`}/>
        <Field ownKey={' المسئول الفرعي'} value={secondClientData.name} reference={`/client/${secondClientData.id}`}/>
        <Field ownKey={' المقيم مع الحالة'} value={employeeData.name} reference={`/employee/${employeeData.id}`}/>
        <Field ownKey={'الراتب'} value={stayData.salary}/>
        <Field ownKey={'بداية الشهر'} value={model.dateToText(stayData.startDate)}/>
        <Field ownKey={'نهايةالشهر'} value={model.dateToText(model.getEndDate(stayData))}/>
        <Field ownKey={'ساعات العمل'} value={stayData.hours}/>
        <Field ownKey={'المدفوع'} value={stayData.paid}/>
        <Field ownKey={'الخصم'} value={stayData.deduct}/>
        <Field ownKey={'الأضافي'} value={stayData.additional}/>
        <Field ownKey={'الغياب'} value={model.getFreeInPeriod(stayData)}/>
        <Field ownKey={'عدد الأيام'} value={model.getWorkInPeriod(stayData)}/>
        <Field ownKey={'الأجمالي'} value={model.getTotal(stayData)}/>

      </>
      :
      <>
      <div>
        لا توجد بيانات اقامة 
      </div>
      </>
    }
  </ScrollCard>
  )
}