import { Link, useParams } from "react-router-dom";
import Page from "./page";
import {  useEffect, useState } from "react";

import { useGetPermissions } from "../hooks/customHook";

import { FcAdvertising } from "react-icons/fc";
import Container from "../dashboard/container";
import Button from "../dashboard/asset/button";
import { model } from "../../model";
import { BiLogoWhatsapp, BiPhone } from "react-icons/bi";
import { Field } from "../dashboard/containers/data/assets";
import EmptyData from "../dashboard/asset/empty";
import { requestModel } from "../../model/request";
import { updateDataEvent } from "../events";
import { useUpdateData } from "../hooks/events";
import { io } from 'socket.io-client'



export default function RequestsPage()
{
  const {route} = useParams();
  const [showData , setShowData] = useState(null);
  const permissions = useGetPermissions();
  const [data , setData] = useState([]);
  const [loading , setLoading] = useState(true);

  const setupMethod = async () =>{
    if(route)
    {
     await requestModel.getById( route , setData);
    }else
    {
      await requestModel.getAll(setData);
    }
  }

  useEffect(() => {
    setupMethod();
  },[])
  useUpdateData(() => {
    setupMethod();
  })

  // new_request
  const socket = io(model.backEndServer , {transports : ["websocket"]});
  socket.on("new_request",async (data) => {
    await setupMethod();
  });
  
  
  return(
    
      <Page title={'الطلبات '} Icon={<FcAdvertising/>} >

        <div className=" grid grid-auto-fit-md gap-4">
          {
            
            data.length > 0 ?
            data.sort( (a , b) => new Date(b.date) - new Date(a.date) ) 
            .map(e => (
              <RequestsCard data={e}/>
            ))
            :
            <EmptyData des="لا توجد طلبات"/>
          }
        </div>

      </Page>
  )
}



function RequestsCard({data = {}})
{
  const [loading , setLoading] = useState();

  const  handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const res = await requestModel.confirm(data.id);
    console.log(res);
    updateDataEvent();
    setLoading(false);
  }

  return(
    <Container
    onSubmit={handleSubmit}
      title={
        <div className=" flex justify-between">
          <span>
            {
              'طلب رقم ' + data.id
            }
          </span>
          <span>
            {model.getRequestStatus(data)}
          </span>
        </div>

      }
      footer={
        data.status == 'waiting' &&
        <Button loading = { loading}>
          تم التواصل
        </Button>
      }
    >
      <Field ownKey='الأسم' value={data.name} />
      <Field ownKey='البريد' value={
        <Link to = {'mailto:' +  data.email}>
          {
            data.email
          }
        </Link>
      } />
      <Field ownKey='رقم الهاتف' value={
        <div className=" flex items-center gap-4">
          <span>
            {
            data.phone
            }
          </span>
          <Link to ={'https://wa.me/+2'+data.phone} className=" bg-green-400 p-2 rounded-lg text-white">
            <BiLogoWhatsapp/>
          </Link>
          <Link to ={'tel:+2'+data.phone} className=" bg-blue-400 p-2 rounded-lg text-white">
            <BiPhone/>
          </Link>
        </div>
        } />
      <Field ownKey='الخدمة' value={data.service} />
      <Field ownKey='التعليق' value={data.comment} />
      <Field ownKey='تاريخ الطلب' value={model.dateToText(data.date)} />
      <Field ownKey='تاريخ الرد' value={model.dateToText(data.resDate)} />
    </Container>
  )
}