import axios from 'axios'
import Cookies from 'js-cookie'
import { model } from '..';


export
const paymentModel = {
  getById : async ( id , setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer + '/payment/get/'+id ,  {headers : {'access-token' : token, }})
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },
  getAll : async (setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer + '/payment/get_all' ,  {headers : {'access-token' : token, }})
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },
  getStatics : async ( target , setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer + '/payment/get_statics/'+target ,  {headers : {'access-token' : token, }})
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },
  getTotals : async (setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer + '/payment/get_totals' ,  {headers : {'access-token' : token, }})
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },

  create : async (values = {}) => {
    let response = null
    const token = false;
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.post( model.backEndServer + '/payment/create' , values ,  {headers : {'access-token' : token, }} )
                  .then(res => {
                    console.log(res);
                    response = res;
                  })
                  .catch(err => {
                    console.log(err);
                    response = err.response;
                  } );
    }
    catch(err)
    {
      console.log(err)
      response = err;
    }
    return response
  },


  
  
  
}
 