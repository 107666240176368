import { BiPlus, BiX } from "react-icons/bi";
import Button from "./button";
import { useState } from "react";
import { dialogEvents } from "../../events";


export
function NewItemDialog2({children , icon})
{
  const [showNewItem , setShowNewItem] = useState(false);
  const handleNewItem = () => {
    setShowNewItem(true);
    dialogEvents.suppliesData({})
  }

  return( 
      <div className="">
        {
          !showNewItem &&
          <div className=" text-3xl absolute bottom-20 z-30 shadow-xl left-5 " >
          <Button type={'button'} secondary OnClick={handleNewItem}  >
            {
              icon ||
              <BiPlus/>
            }
          </Button>
        </div  >
        }

        {
          showNewItem &&
          <div className=" relative w-fit  "  >
            <div 
              onClick={() => setShowNewItem(false)}
            className=" text-xl absolute left-2 bg-red-300 rounded-full p-1 cursor-pointer shadow-lg " >
              <BiX/>
            </div>
            {
              children
            }
          </div>
        }
      </div>
    
  )
}

export
default
function NewItemDialog({children})
{
  const [showNewItem , setShowNewItem] = useState(false);
  const handleNewItem = () => {
    setShowNewItem(true);
    dialogEvents.suppliesData({})
  }

  return( 
      <div className="">
        {
          !showNewItem &&
          <div className=" text-3xl absolute bottom-5 z-30 shadow-xl left-5 " >
          <Button type={'button'} OnClick={handleNewItem}  >
            <BiPlus/>
          </Button>
        </div  >
        }

        {
          showNewItem &&
          <div className=" relative w-fit  "  >
            <div 
              onClick={() => setShowNewItem(false)}
            className=" text-xl absolute left-2 bg-red-300 rounded-full p-1 cursor-pointer shadow-lg " >
              <BiX/>
            </div>
            {
              children
            }
          </div>
        }
      </div>
    
  )
}
