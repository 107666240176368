import { useNavigate, useParams } from "react-router-dom";
import { AddNewClient } from "../dashboard/containers/addNew";
import PageHeader from "../dashboard/asset/pageHeader";
import { IoPeopleCircle, IoPeopleOutline } from "react-icons/io5";
import Page from "./page";
import { ClientTable } from "../dashboard/containers/tables";
import { useEffect, useState } from "react";
import { customDialogsEvents } from "../hooks/events";
import { ClientData } from "../dashboard/containers/data/client";
import { useGetPermissions } from "../hooks/customHook";
import { NotAvailable } from "../dashboard/asset/notAvailable";
import { FcAssistant, FcSearch } from "react-icons/fc";
import Container from "../dashboard/container";
import Button from "../dashboard/asset/button";
import { model } from "../../model";
import axios from "axios";
import { searchModel } from "../../model/search";
import ClipLoader from "react-spinners/ClipLoader";
import {Empty} from 'antd'
import LoadingScreen from "../dashboard/asset/loading";
import EmptyData from "../dashboard/asset/empty";
 



export default function SearchPage()
{
  const {route} = useParams();
  const [showData , setShowData] = useState(null);
  const permissions = useGetPermissions();
  const [data , setData] = useState([]);
  const [loading , setLoading] = useState(true);

  // console.log(permissions);
  const loadSomeThings = async () => {
    setLoading(true);
    await searchModel.getAll(route , setData)
    setLoading(false);
  }
  useEffect( () => {
    loadSomeThings();
  },[route])
  
  
  
  return(
    
      <Page title={
        <div>
          <span>
            {
              ' نتائج البحث عن'
            } 
          </span>
          <span className=" text-blue-600 font-thin text-lg" > 
            {
              ` " ${route} "`
            }
          </span>
        </div>
        } Icon={<FcSearch/>} >
          <div>
            {
              loading ? 
              <LoadingScreen/>
              :
              data.length > 0 ?
            <div className=" grid grid-auto-fit-lg gap-4" >
              {
                data.map(e => (
                  <SearchCard result={e} />
                ))
              }
            </div> : 
            <EmptyData/>
            }
          </div>
      </Page>
  )
}



function SearchCard({result = {}}) 
{
  const searchResult =  model.getSearchResult(result);
  const navigator = useNavigate();
  console.log(searchResult);

  const handleClickCard = (e) => {
    e.preventDefault();
    navigator(searchResult.route + '/' + result.id);
  }


  return(
    <Container
          onSubmit={handleClickCard}
          title={
            <div className=" flex items-center gap-3" >
              <span>
                {
                  searchResult.icon
                }
              </span>
              <span>
                {
                  searchResult.title
                }
              </span>
            </div>
          }
          footer = {
            <Button
            >
              عرض البيانات
            </Button>
          }
        >
          <div className="flex items-center" >
            <span>
            الأسم :
            </span>
            <span>
              {
                result.name
              }
            </span>
          </div>

    </Container>
  )
}