
import { useEffect, useRef, useState } from "react";
import { textBoxClassName, textValues } from "./assets";


/**
 * 
 * @param {style} 
 * @returns 
 */


export default
function TextFilterField({
  value,
  onChange = () => null,
  placeholder,
  name,
  type,
  err,
  success,
  children,
  title,
  options,
  sortValue,
  setKey = () => null ,
  setValue = () => null
})
{
  const textRef = useRef()
  const selectRef = useRef()
  const [active, setActive] = useState();
  const [selectActive, setSelectActive] = useState(false);
  const [currentText, setCurrentText] = useState('');
  const [currentKey, setCurrentKey] = useState('');
  
  const FieldTitle = textValues.FieldTitle;
  const Child = textValues.ChildSection;

  useEffect(()=>{
    setValue(prev => ({...prev , 'value' : currentText , 'name' : currentKey}));
  } , [currentText , currentKey])

  const activeMe = () => {
    !selectActive && textRef.current.focus();
  }
  return (
    <div
      onClick={() => activeMe}
    >
      <div onClick={() => textRef.current.focus()}>
        <FieldTitle title={placeholder || title} name={name} show={active || ( textRef.current ?  textRef.current.value : false)}/>
      </div>
      <div className={textValues.fieldClassName(active , err , success)}>
        <Child children={children} textRef={textRef} active={active}/>
          <div>
            <input
              ref={textRef} className={textValues.inputClassName}
              onChange={ (v) =>{onChange(v.target.value) ; setCurrentText(v.target.value)}}
              onFocus={() => setActive(true)}
              onBlur={() => setActive(false)}
              type={type}
              
              name={name}
              id={name}
              defaultValue={value}/>
          </div>
          <div>
          <select
            className={textValues.inputClassName + ' w-1/2 '}
            onChange={(v) =>{onChange(name , v.target.value); setCurrentKey(v.target.value)} }
            onFocus={() => setSelectActive(true)}
            ref={selectRef}
          
            defaultValue={sortValue}>
              {
                options &&
                Object.keys(options).map(e => (
                  <option selected = {e == value} value={e} >
                    {options[e]}
                  </option>))
              }
          </select>
          </div>
      </div>
    </div>
  )
}

