import { useRef } from "react";
import PageHeader from "../dashboard/asset/pageHeader";
import { customDialogsEvents } from "../hooks/events";



export default function Page({children , title , Icon})
{

  return(
    <div className=" flex flex-col gap-4 p-4 pb-20">
      <div className=" text-blue-500">
        {
          title &&
          <PageHeader title={title} Icon={Icon}/>
        }
      </div>
      <div className=" h-full flex flex-col gap-4 overflow-visible">
        {
          children
        }
      </div>
    </div>
  )
}
