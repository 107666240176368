import { FcDataBackup, FcHome, FcSettings } from "react-icons/fc";
import Page from "./page";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import Cookies from 'js-cookie'
import axios from "axios";
import { useEffect, useState } from "react";
import { useGetPermissions } from "../hooks/customHook";
import { permIntoArabic } from "../permissions";
import { AddNewUser } from "../dashboard/containers/addNew";
import { model } from "../../model";
import { CiSettings } from "react-icons/ci";
import Container from "../dashboard/container";
import SelectBox from "../dashboard/asset/selectBox";
import Button from "../dashboard/asset/button";
import { MdBackup, MdBackupTable, MdOutlineBackup, MdPassword } from "react-icons/md";
import { IoBackspaceOutline, IoCheckmark, IoEye, IoEyeOff } from "react-icons/io5";
import LoadingScreen from "../dashboard/asset/loading";
import { Link, useNavigate } from "react-router-dom";
import { PopDialog } from "../dashboard/asset/popDialog";
import TextField from "../dashboard/asset/textField";
import { LineNotify } from "../dashboard/asset/notify";
import { userModel } from "../../model/user";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { SkeletonLine } from "./home";
import { employeeModel } from "../../model/employee";
import { backupModel } from "../../model/backup";
import { updateDataEvent } from "../events";
import { useUpdateData } from "../hooks/events";

export default
function SettingPage()
{
  const myPem = useGetPermissions();

  return( 
    <Page title='الأعدادات' Icon={<FcSettings/>}>

    <PasswordReset/>
    {
      myPem.backup &&
      <BackUpDialog/>
    }
    <ViewAccess myPem={myPem}/>
    </Page>
  )
}

function ViewAccess ({myPem = {}})
{
  const [view , setView] = useState(false);

  const toggle = () => {
    setView(prev => !prev);
  }

  return(
    <div>
        <div className="flex gap-2">
          <div className=" text-lg" >صلاحياتك هي </div>
          <div>
            <Button secondary type={'button'} OnClick={toggle} >
              {
                !view ? 
                <IoEye/> :
                <IoEyeOff/>
              }
            </Button>
          </div>
        </div>
        {
          view &&
          <ul className=" pr-2">
            {
              Object.keys(myPem).length !== 0 ?
              Object.keys(myPem).map(e =>  (  (e != 'id' && e!= "job" )&& ( myPem[e] ?
                <li className=" flex gap-2" >
                  <div className=" text-sky-500 text-lg" >
                    <IoCheckmark/>
                  </div>
                {
                  permIntoArabic[e]
                }
                </li> : ''
              )
              ))
                :
                <div className="flex flex-col gap-2" >
                  <SkeletonLine/>
                  <SkeletonLine/>
                  <SkeletonLine/>
                  <SkeletonLine/>
                  <SkeletonLine/>
                  <SkeletonLine/>
                </div>  
            }
          </ul>
        }
      </div>
  )
}



function PasswordReset() {
  const [values , setValues] = useState({});
  const [matchedPass , setMatchedPass] = useState(true);
  const [loading , setLoading] = useState(false);
  const [errLine , setErrLine] = useState(null);
  const signOut = useSignOut();
  const navigator = useNavigate();



  const handleErr = () => {
    let matched = (values.newPass == values.matchPass) 
    setMatchedPass(matched);
  }
  useEffect(() => {
    handleErr()
  } , [values])
  const handleInput = (n , v) => {
    setValues(prev => ({...prev , [n] : v }))
    setErrLine(null);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(matchedPass && values.oldPassword && values.newPass)
    {
      setLoading(true);
      const res = await userModel.changePassword(values);
      console.log(res);
      if(res.passChanged)
      {
        signOut();
        navigator('/login')
      }
      else{
        setErrLine('كلمة المرور غير صحيحة');
      }
      setLoading(false);
    }
    else
    {
      setErrLine('اكمل الحقول');
    }
  }

  return(
    <Container
      title={
        <div className=" flex items-center gap-4">
          <span className="text-sky-500">
            <MdPassword/>
          </span>
          <span>
            تغير كلمة المرور
          </span>
        </div>
      }
      onSubmit={handleSubmit}
      footer={
        <Button loading = {loading} >
          تأكيد
        </Button>
      }
    >
      <TextField type = 'password' onChange = {handleInput} name = 'oldPassword' placeholder = 'كلمة  المرور القديمة' />
      <TextField type = 'password' onChange = {handleInput} name = 'newPass' placeholder = 'كلمة المرور الجديدة' />
      <TextField err ={  (!matchedPass) && 'غير متطابق'} type = 'password' onChange = {handleInput} name = 'matchPass' placeholder = 'كلمة المرور الجديدة' />
      
      <LineNotify text={errLine} err={errLine} />
    </Container>
  )
}

function BackUpDialog() {

  const [values , setValues] = useState({});
  const [loading , setLoading] = useState(false);
  const [showPop , setShowPop] = useState(false);
  const [backUpImages , setBackUpImages] = useState([  ]);
  const [data , setData] = useState({ 
    time : 'day',
    lastBackUpDate : '07-2-2024',
    userId : 2011,
    auto : false 
  });

  const loadData = async () => {
    setLoading(true);
    await backupModel.getAll(setBackUpImages);
    setLoading(false);
  }
  useEffect(() => {
    loadData();
  } , [])
  
  useUpdateData(() => {
    loadData();
  })
  
  useEffect(()=>{
    console.log(backUpImages);
    let data = backUpImages.sort(( a , b ) =>  new Date(a) - new Date(b) )
    setBackUpImages(data);
  } , [] )
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    /// todo api 
  }

  const handleTakeImage = async () => {
    setLoading(true);

    await backupModel.create();
    updateDataEvent();

    setLoading(false);
  }

  const handleChange = (n , v) => {

    setValues(prev => ({...prev , [n] : v}))
    // setLoading(true);
  }
  
  const handleRestore = (image) => {
    setValues(prev => ({...prev , image : image}))
    setShowPop(true);
    /// todo api 
  }
  const handleRestoreSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setLoading(true);
    await backupModel.update(values);
    setLoading(false);
    setShowPop(false);
  }
  return(
    <Container 
      title={
        <div className=" flex gap-4 items-center">
            <span className=" text-sky-600" >
              <MdOutlineBackup/>
            </span>
          <span>
            {
              'اعداد النسخة الأحتياطية'
            }
          </span>
        </div>
      }
      onSubmit={handleSubmit}
      footer={
        <div className=" flex justify-around w-full" >
          {
            loading ? 'جاري التحديث' :
            <Button  secondary type={'button'} OnClick={handleTakeImage} >
              حفظ نسخة 
            </Button>

          }
        </div>
      }
    >
      {
            loading ? <LoadingScreen/>
            :
          
      <div>
        <PopDialog title={'استعادة نسخة احتياطية'} show = {showPop} setShow={setShowPop} onSubmit={handleRestoreSubmit}  >
          <TextField type = 'password' onChange = {handleChange} name = 'password' placeholder = 'كلمة المرور' />
        </PopDialog>
        <div className=" text-sm mb-4" >
          <span>
          موعد اخر نسخة الأحتياطية
          </span>
          {/* <span className=" text-lg px-2 text-sky-600" >
            {
              model.dateToText(data.lastBackUpDate)
            }
          </span> */}
          {/* <span>
            {
              data.auto ? ' بشكل تلقائي ' : 
              <div>
                <span>
                  بشكل يدوي قام بها الموظف بكود رقم 
                </span>

                <Link className=" text-sky-500" to ={'/user/' + data.userId}>
                  {
                    ' ' + data.userId
                  }
                </Link>
              </div>
            }
          </span> */}
        </div>
        <div>
          {/* <span className=" text-sm" >يقوم النظام بعمل نسخة احتياطية كل 
             <span>
              {
                ' ' + data.time + ' '
              }
             </span>
             بشكل تلقائي
          </span>
          <div className=" relative flex w-full justify-around py-3 items-center  border-2 p-2 rounded-lg my-4 border-sky-400 ">
            <div className=" absolute -top-4 right-2 bg-white px-2 text-sky-400"  >تعديل المدة</div>
            <SelectBox name = 'time' value = 'day' onChange = {handleChange} options = {{day : 'يوم' , week : 'اسبوع' , month : 'شهر'}}/>
            <Button loading = {loading}>
                تأكيد
            </Button>
          </div> */}
          {/* {
            values.time &&
            <div className=" text-sm text-green-700 bg-green-200 px-2 py-1 my-4">
              <span>
                سيتم رفع نسخة احتياطية من البيانات كل 
              </span>
              <span>
                {
                  ' ' + values.time
                }
              </span>
            </div>
          } */}
          <div className=" relative flex-col w-full gap-4 justify-around py-3 items-center  border-2 p-2 rounded-lg my-4 border-sky-400 ">
            <div className=" absolute -top-4 right-2 bg-white px-2 text-sky-400"  >النسخ الموجودة</div>
            {
              backUpImages.length > 0 && 
              backUpImages.sort((a , b) => new Date(b.date) - new Date(a.date)).map(e => (
                <div className=" flex justify-around py-2 ">
                  <div className="flex flex-col">
                    <span className=" text-lg font-bold" >
                      {
                        model.dateToText(e.date)
                      }
                    </span>
                    <span className=" flex gap-3" >
                      <span>
                        الساعة : 
                      </span>
                      <span>
                        {
                          e.time
                        }
                      </span>
                    </span>
                  </div>
                  <Button secondary type={'button'} loading = {loading} OnClick={() => handleRestore(e)}>
                      استعادة
                  </Button>
                </div>
              ))
            }
          </div>
        
        </div>
      </div>
    }
    </Container>
  )
}