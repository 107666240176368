import { useEffect, useState } from "react";
import Button from "../dashboard/asset/button";
import { BiRightArrowAlt } from "react-icons/bi";
import ApplicantBody from "../applicant/body";
import { applicantModel } from "../applicant/model";
import axios from "axios";
import { model } from "../../model";








export function Applicant(){

  const [pageNumber , setPageNumber] = useState(0)
  const [values , setValues] = useState({});
  const [loading , setLoading] = useState(false);
  const [checkRes , setCheckRes] = useState('');
  const [image , setImages] = useState([]);

  useEffect(() =>{
    console.log(values);
    
    console.log(checkRes);
    
    console.log('images' , image);
    

  },[values,checkRes , image])

  



  const uploadData = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData()
    // console.log(image[0]);
    for (let i = 0; i < image.length; i++) {
      const element = image[i];
      formData.append("images" , element);
    }
    axios.post( model.backEndServer + '/applicant/upload_files',formData , {headers : { 'data' : 'ali mohamed ali' ,  'access-token' : {id : 15 , data : 'ali mohamed ali'}  }}).then(res => {console.log('uploaded successfully')}).catch(er => console.log(er));
    

    // const res = await applicantModel.uploadData(values)
    // const imageRes = await applicantModel.uploadImages(formData)
    // console.log(res);
    // console.log(imageRes);
    setLoading(false);
  }

  const checkApplicant = async () => {
    setLoading(true);
    const res = await applicantModel.checkPhoneCardId(values.phone , values.cardId)
    if(res.status == 200)
    {
      setCheckRes('notFound')
    }
    else if(res.status == 400)
    {
      setCheckRes('found')
    }
    else
    {
      setCheckRes('error');
    }
      setLoading(false);
  }


  const handleNextPage = () => 
    {
      
      if(pageNumber == 0)
        {
          checkApplicant();
        }
        if(pageNumber == 3)
        {
          uploadData();
        }
        else
        {
          const maxNumber = 5;
          if(pageNumber < maxNumber )
            setPageNumber(pageNumber + 1)
        }
    }
  const handlePrevPage = () => 
    {
      if(pageNumber > 0)
        setPageNumber(pageNumber - 1)
    }
  const inputValues = (n , v) => 
    {
      setValues(prev => ({...prev , [n] : v}));
    }

  return(
    <form action="POST" onSubmit={uploadData} className=" w-full h-full" >
      <div className="flex flex-col justify-between h-full bg-white py-2">
        <ApplicantHeader handlePrevPage={handlePrevPage} pageNumber={pageNumber} loading={loading}  />
        <ApplicantBody pageNumber={pageNumber} inputValues={inputValues} status={checkRes} values={values} setImages = {setImages} />
        <ApplicantBottom values={values} handleNextPage={handleNextPage} loading={loading} pageNumber={pageNumber}  />
      </div>
    </form>
  )
}






function ApplicantBottom({values , handleNextPage , loading , pageNumber})
{
  return(
    <div className=" border-t-2 pt-4 ease-linear duration-300 flex justify-around items-center w-full" >
      {
        pageNumber == 3 ? 
        <Button type='submit' disabled={ !(values.accepted && pageNumber == 3)} >
          تأكيد
        </Button>
        :
        <Button type='button' OnClick={handleNextPage} disabled = {
          (!(values.phone && values.cardId && pageNumber == 0))
          &&
          (!(values.name && values.job && values.address && pageNumber == 1))
          && !(pageNumber == 2)
          

          } 
          loading = {loading}
          >
            {
             'التالي'
            }
        </Button>
      }
    
  </div>
  )
}

function ApplicantHeader({handlePrevPage , pageNumber , loading})
{
  return(
    <div className=" relative flex flex-col gap-4 p-2" >
        <div className="  font-bold border p-2 shadow-md flex gap-4 rounded-lg">
          <span >
            نموذج تقديم علي وظيفة
          </span>
          /
          <span>
            صفحة : {
              pageNumber + 1
            }
          </span>
        </div>
        <div className=" absolute top-full">
          { pageNumber ? 
          <Button OnClick={handlePrevPage} secondary disabled={loading} >
            <BiRightArrowAlt/>
          </Button> :
          null}
        </div>
      </div>

  )
}