import { useParams } from "react-router-dom";
import { FcMoneyTransfer } from "react-icons/fc";
import Page from "./page";
import { customDialogsEvents, useUpdateData } from "../hooks/events";
import { useGetPermissions } from "../hooks/customHook";
import AddNewPricing from "../pricing";
import LoadingScreen from "../dashboard/asset/loading";
import { useEffect, useState } from "react";
import { PricingTable } from "../dashboard/containers/tables";
import NewItemDialog from "../dashboard/asset/newItemDialog";
import { GetAllAvaliableMoeny, GetAllPayment, MoneyTransaction } from "../payment";
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from "recharts";
import { paymentModel } from "../../model/payment";
import SelectBox from "../dashboard/asset/selectBox";


export default function PaymentPage()
{
  const {route} = useParams();
  const [pricingId , setPricingId] = useState(null);
  const permissions = useGetPermissions();
  customDialogsEvents.useSupplies(  e =>{
    setPricingId(e.detail.detail);
    })

    

  return(
    
    <Page title={'الدفع'} Icon={<FcMoneyTransfer/>} >
      {
        permissions.job ?
        <div className=" flex flex-col gap-4" >
          <NewItemDialog>
            <MoneyTransaction/>
          </NewItemDialog>
          <GetAllAvaliableMoeny/>
          <ActionCompare/>
          <GetAllPayment />
        </div>
        :
        <LoadingScreen/>
      }
      
    </Page>
    
  )
}


function ActionCompare() {

    const [paymentData , setPaymentData] = useState([]);
    const [loading ,setLoading] = useState(false);
    const [target , setTarget] = useState('week');

    const loadData = async () => {
      setLoading(true);
      await paymentModel.getStatics(target , setPaymentData);
      setLoading(false);
    }

    useEffect(() => {
      loadData();
    } , [target])
    
    useUpdateData(()=> {
      loadData();
    })

    // console.log(paymentData);

    const handleChange = (n , v) =>{
      setTarget(v);
    }


  return( 
    <div className="p-2 w-fit flex flex-col gap-4 bg-slate-200/50 rounded-xl shadow-md ">
      <div className=" flex justify-between items-center" >
        <div className=" text-2xl" >
          احصائيات 
        </div>
        <SelectBox options = {{week : 'اسبوع' , month : "شهر" , year : 'سنة'}} value = {'week'} onChange = {handleChange}   />
      </div>
      {
        !loading ?
        <div>
          <LineChart  width={450} height={400} data={paymentData}>
            <XAxis dataKey="name" />
            {/* <YAxis /> */}
            <CartesianGrid stroke="#ccc" strokeDasharray="2 2" />
            <Line type={"monotone"} dataKey={'vodafone'} stroke="red"  />
            <Line type={"monotone"} dataKey={'instaPay'} stroke="purple" />
            <Line type={"monotone"} dataKey={'cash'} stroke="green" />
            <Line type={"monotone"} dataKey={'total'} stroke="blue" />
            <Tooltip/>
          </LineChart>
        </div>
        : <LoadingScreen/>
      }
          </div>
          
  )
}