import { useParams } from "react-router-dom"
import Page from "./page";
import { useEffect, useState } from "react";
import { customDialogsEvents, useUpdateData } from "../hooks/events";
import { BiCheck, BiMessageSquareCheck } from "react-icons/bi";
import { AddNewJob, permIntoArabic } from "../permissions";
import MainJobTable from "../dashboard/containers/table/jobTable";
import { perModel } from "../../model/permissions";
import { useGetAllJobs, useGetPermissions } from "../hooks/customHook";
import { NotAvailable } from "../dashboard/asset/notAvailable";
import NewItemDialog from "../dashboard/asset/newItemDialog";
import { Table } from "antd";


export default function JobAndPermissions()
{
  const {route} = useParams();
  const [data , setData] = useState([]);
  const permissions = useGetPermissions();
  const [columns , setColumns] = useState([])
  
  
  useUpdateData(() => {
    perModel.getAll(setData);
  })
  useEffect(() => {
    perModel.getAll(setData);
  },[])

  useEffect(() => {
    if(permissions.job )
    {
      var keysArray = Object.keys(permissions);
      var col = []
      keysArray.forEach(e => {
        col.push({
          title : e == 'id' ? '#' : permIntoArabic[e] || e ,
          dataIndex : e,
          key : e,
          render : (text) => e == 'id' ? text : text == 1 ? <BiCheck/> : e == 'job' ? text : '',
          fixed : e == 'job' || e == 'id' ? 'left' : ''
        })
      })
      // console.log(col)
      setColumns(col);
    }
  } , [permissions])

  // permIntoArabic


  return(
    <Page title={'الوظائف و الصلاحيات'} Icon={<BiMessageSquareCheck/>} >
      {
        permissions && permissions.viewUser &&(
        <NewItemDialog>
          <AddNewJob/>
        </NewItemDialog>
      )
        
      }
      {
        permissions && permissions.viewUser &&(
         data.length > 0 && 
         <div>
              <div dir="ltr">
                <Table className=" scroll-style " dataSource={data || []} columns={columns} scroll={{x : 5000 }} />
              </div>
         </div>
        ) || <NotAvailable/>
      }
    </Page>
  )
}