

import { BrowserRouter } from 'react-router-dom'
import RouterHandler from '../components/routes';
import AuthProvider from 'react-auth-kit/AuthProvider';
import createStore from 'react-auth-kit/createStore';
import { useEffect, useState } from 'react';
import { BodyWithNavigation } from './bodyNavigation';
import { model } from '../model';
import { io } from 'socket.io-client'
import Cookies from 'js-cookie'


const store = createStore({
  authName:'_auth',
  authType:'cookie',
  cookieDomain: window.location.hostname,
  cookieSecure: false , // window.location.protocol === 'https:',
  
});

const socket = io(process.env.REACT_APP_BACKEND_SERVER_SOCKET , {
  withCredentials: true,
  extraHeaders: {
    "my-custom-header": "abcd"
  }});


function App(props) {
  
  useEffect(() => {
    // alert(process.env.REACT_APP_BACKEND_SERVER);
    socket.on("connect" , () => {
      console.log('socket io connected successfully ??? ----- ??? ')
    });

    socket.on("connect_error", (error) => {
      if (socket.active) {
        // console.log(" err in  socket");
        // temporary failure, the socket will automatically try to reconnect
      } else {
        // the connection was denied by the server
        // in that case, `socket.connect()` must be manually called in order to reconnect
        console.log( 'socket io -err is : ' ,  error.message);
      }
    });
    const token = Cookies.get('_auth');
    socket.emit('new_login' , { message : { token }} );
    
    // socket.on("new_notification", (data) => {
    //   console.log('from app we have new notificaiton bro ' , data.message);
    // });

    sessionStorage.removeItem('permissions_');
  } , [])
  

  
  return (
    // <AuthProvider >
    <div>
      <AuthProvider store={store}>
        <BrowserRouter>
          <BodyWithNavigation>
              <RouterHandler/>
          </BodyWithNavigation>
        </BrowserRouter>
      </AuthProvider>
      you are in app
    </div>
    // </AuthProvider>
  );
}


export default App;


