import { useNavigate, useParams } from "react-router-dom";
import { AddNewEmployee, AddNewEmployeeStay } from "../dashboard/containers/addNew";
import { FcManager } from "react-icons/fc";
import Page from "./page";
import { EmployeeData } from "../dashboard/containers/data/employee";
import { customDialogsEvents, useUpdateData } from "../hooks/events";
import { useEffect, useState } from "react";
import { NotAvailable } from "../dashboard/asset/notAvailable";
import { useGetPermissions } from "../hooks/customHook";
import Button from "../dashboard/asset/button";
import NewItemDialog from "../dashboard/asset/newItemDialog";
import { NewItemDialog2 } from "../dashboard/asset/newItemDialog";
import { Input, Radio, Select, Table } from "antd";
import { employeeModel } from "../../model/employee";
import { model } from "../../model";
import { IoDocument } from "react-icons/io5";
import { Option } from "antd/es/mentions";
import { BiSearch } from "react-icons/bi";
import { PageNavigate, SearchHeadBox } from "../dashboard/asset/tableHeadSearch";








export default function Employee()
{
  const {route} = useParams();
  const [showEmployee , setShowEmployee] = useState(null);
  const permissions = useGetPermissions();

  const [mode, setMode] = useState('main');

  const handleModeChange = (e) => {
    setMode(e.target.value);
  };

  customDialogsEvents.useEmployee(  e =>{
    setShowEmployee(e.detail.detail);
    })

  return(
    <Page title={'الموظفين'} Icon={<FcManager/>} >
      <PageNavigate handleModeChange={handleModeChange} mode={mode} options={{main : 'بيانات اساسية',stay : 'اقامات' }}  />
      {
        permissions.addEmployee &&
        (
          <NewItemDialog>
            <AddNewEmployee/>
          </NewItemDialog>
        )
      }
      {
        permissions.addEmployeeStay &&
        (
          <NewItemDialog2>
            <AddNewEmployeeStay/>
          </NewItemDialog2>
        )
        
      }
      
      {
        permissions.viewEmployeeTable &&
        (
          <div>
            <AllTables setShowEmployee={setShowEmployee} mode={mode} />
          </div>
        )
      }
      {
        permissions.viewEmployee &&
        (
          <EmployeeData modelId={route || showEmployee }/>
        )
      }
      {
        !permissions && !permissions.addEmployeeStay &&
        <NotAvailable/>
      }
    </Page>
    
  )
}




function AllTables({setShowEmployee , mode })
{
  const [stayData ,setStayData] = useState([]);
  const [mainData ,setMainData] = useState([]);
  const [mainLoading ,setMainLoading] = useState(false);
  const [stayLoading ,setStayLoading] = useState(false);

  const loadData = async () => {
    setMainLoading(true);
    await employeeModel.getAll(setMainData);
    setMainLoading(false);

    setStayLoading(true);
    await employeeModel.getAllStay(setStayData);
    setStayLoading(false);
  }
  useEffect(() => {
    loadData();
  },[])

  useUpdateData(()=>{
    loadData();
  })

  
  return(
    <div>
      {
        mode == 'main' ?
      <MainEmpTable setEmployee={setShowEmployee} loading ={ mainLoading } data={mainData }  />
      : mode == 'stay' ?
      <StayEmpTable setEmployee={setShowEmployee} loading = { stayLoading } data={stayData}  />
      : ''
    }
    </div>
  )
}



function MainEmpTable({setEmployee , data = [] , loading = false })
{
  
  const [columns , setColumns] = useState([])
  const [srData ,setSrData] = useState([])
  const [searchText ,setSrchText] = useState(false)
  const [filterText ,setFilterText] = useState('name')

  useEffect(() => {
    if(searchText)
    {
      var newData = data.filter(a => a[filterText].toString().toUpperCase().includes(searchText.toString().toUpperCase())) 
      setSrData(newData);
    }
    else
      setSrData(data);

  } , [searchText , filterText , data])

  
  useEffect(() => {
    // console.log(data);
    if(srData.length > 0)
    {
      const keysArray = Object.keys(data[0])
      var col = []
      keysArray.forEach(e => {
        col.push({
          title : employeeModel.MainStandardTable[e] || e ,
          dataIndex : e,
          key : e,
          sorter : (a , b) => e == 'total' ? model.getTotal(a) - model.getTotal(b) :  a[e] - b[e] ,
          render : (text , record , index) => (e == 'workDate' || e == 'birthDate' )? model.dateToText(text) : text ,
          fixed : e == 'name'? 'left' : ''
        })
      })
      col.push({
        title : 'حدث' ,
        dataIndex : 'id',
        render : (e) => <Button type={'button'} OnClick={ () => setEmployee(e)} >عرض</Button> ,
        fixed : 'right'
      })
      setColumns(col);
    }
  },[srData])

  return(
    <div className=" pb-10 flex flex-col gap-4 "  >
      <SearchHeadBox  setSearchText={setSrchText} setFilterText = {setFilterText} options={{name : 'الأسم' , phone :'الهاتف' , cardId : 'الرقم القومي'}} defaultValue={'name'}  />
      <div dir="ltr" >
        <Table dataSource={srData} columns={columns} scroll={{x : 1500 , y : 600}} loading = {loading} />
      </div>
    </div>
  )
}


function StayEmpTable({setEmployee , data = [] , loading = false })
{
  const [columns , setColumns] = useState([])
  const [searchText , setSrchText] = useState(false)
  const [filterText , setFilterText] = useState('name')
  const [statusFilter , setStatusFilter] = useState('all')
  const navigator = useNavigate();
  
  const showDocument = (record) => {
    navigator('/docs/employee/' + record.id )
  }

  const [fiData ,setFiData] = useState([])
  const [srData ,setSrData] = useState([])

  useEffect(() => {
    if(statusFilter == 'all')
    {
      setFiData(data);
    }
    else
    {
      var newData = data.filter(a => a.status == statusFilter) 
      setFiData(newData);
    }
  } , [statusFilter , data])

  
  useEffect(() => {
    if(searchText)
    {
      var newData = fiData.filter(a => a[filterText].toString().toUpperCase().includes(searchText.toString().toUpperCase())) 
      setSrData(newData);
    }
    else
      setSrData(fiData);

  } , [searchText , filterText , fiData])
  

  useEffect(() => {
    if(srData.length > 0)
    {
      const keysArray = Object.keys(employeeModel.StayStandardTable)
      var col = []
      keysArray.forEach(e => {
        // console.log(e);
        col.push({
          title : e == 'id' ? '#' : employeeModel.StayStandardTable[e],
          dataIndex : e,
          key : e,
          sorter : (a , b) => e == 'total' ? model.getTotal(a) - model.getTotal(b) : a[e] > b[e] ,
          render : (text , record ,index) => (e == 'workDate' || e == 'birthDate' || e == "startDate") ? model.dateToText(text) : 
          e == 'total' ? model.getTotal(record) : 
          e == "endDate" ? model.dateToText(model.getEndDate(record)) :
          e == 'status' ? model.getStatus(record) :
          e == 'workedDays' ? model.getWorkInPeriod(record) :
          text,
          fixed : e == 'name' ? 'left' : ''
        })
      })
      col.push({
        title : 'حدث' ,
        dataIndex : 'id',
        render : (e , record) => <div className=" flex gap-2" >
          <Button type={'button'} OnClick={ () => setEmployee(e)} >عرض</Button> 
          <Button secondary type={'button'} OnClick={ () => showDocument(record)} ><IoDocument/></Button> 
        </div>, 
        fixed : 'right'
      })
      setColumns(col);
    }
  },[srData])

  return(
    <div className=" pb-10 flex flex-col gap-4 " >
      <SearchHeadBox setStatusText={setStatusFilter} setSearchText={setSrchText} setFilterText = {setFilterText} options={{name : 'الأسم' , patientName : 'اسم الحالة' , phone :'الهاتف' , cardId : 'الرقم القومي'}} defaultValue={'name'} />
      <div dir="ltr" >
        <Table dataSource={srData} columns={columns} scroll={{x : 1500 , y : 600}} loading = {loading} />
      </div>
    </div>
  )
}