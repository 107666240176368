/// external imported
import { FcDocument } from "react-icons/fc";
import { useEffect, useState } from "react";

/// local imported 
import ClientPDF from "../dashboard/pdf/clientPDF";
import { stayModel } from "../../model/stay";
import { clientModel } from "../../model/client";
import { employeeModel } from "../../model/employee";
import EmployeePDF from "../dashboard/pdf/employeePDF";
import { useParams } from "react-router-dom";
import Button from "../dashboard/asset/button";
import { useGetPermissions } from "../hooks/customHook";
import Page from "./page";
import { docModel } from "../../model/doc";
import { useUpdateData } from "../hooks/events";
import { AddNewDoc, ViewTerm } from "../dashboard/docs";
import NewItemDialog from "../dashboard/asset/newItemDialog";
import { Card, Empty, Spin } from "antd";
import { PageNavigate } from "../dashboard/asset/tableHeadSearch";
import { updateDataEvent } from "../events";


/**
 * 
 * id
 * title
 * term
 */

function ClientCompletDoc({docId = 0})
{
  
  const [values , setValues] = useState(null);
  const [mainClient , setClient] = useState(null)
  useEffect(() => {
    values && clientModel.getById(values.mainClientId , setClient)
    // getImageScreened();
  } , [values])
  
  useEffect(() => {
    setValues(null)
    stayModel.getById(docId, setValues);
    // console.log('change document')
  },[docId])

  return ( 
    ( values && mainClient ) &&
        <ClientPDF data={values} client = {mainClient} className = {'w-full h-full'}/>
        ||
        <Empty/>
  )
}

function EmployeeCompletDoc({docId = 0 , target})
{
  
  const [values , setValues] = useState(null);
  const [mainClient , setClient] = useState(null)
  useEffect(() => {
    values && employeeModel.getById(values.employeeId , setClient)
    // getImageScreened();
  } , [values])
  
  useEffect(() => {
    setValues(null)
    employeeModel.getStayById(docId, setValues);
  },[docId])
  // console.log('change document' , values)

  return ( 
    ( values && mainClient ) &&
      <div className=" pt-5 h-full">
        <EmployeePDF target = {target}  data={values} client = {mainClient} className = {'w-full h-full'}/>
      </div>
        ||
        <Empty/>
  )
}






export default function Documents()
{
  const {route , id} = useParams();
  const permissions = useGetPermissions();
  const [loading , setLoading] = useState([]);
  const [data , setData] = useState([]);
  const [target , setTarget] = useState('employee');
  const [update , setUpdate] = useState(0);
  


  const loadData = async () => {
    // setTarget(target);
    setLoading(true);
    await docModel.getTitles(setData , target);
    setLoading(false);
    // console.log(target);
  }

  useEffect(() => {
    loadData();
  },[target , update])

  useUpdateData(() => {
    setUpdate(prev => prev + 1)
  });

  useEffect(() => {
    if(route == 'employee')
      setTarget('employee');
  } , [route])

  const handleTargetChange = (e) => {
    setTarget(e.target.value);
  }


  const clOptions = { client : 'عملاء',employee : 'موظفين اقامات' , employee_office : 'موظفين مكتب' , employee_visit : 'موظفين زيارات' }
  const emOptions = { employee : 'موظفين اقامات' , employee_office : 'موظفين مكتب' , employee_visit : 'موظفين زيارات' }
  return(
    <Page
    title={'مستندات'} Icon={<FcDocument/>}>
      <PageNavigate handleModeChange={handleTargetChange} mode={target} options={route == 'employee' ? emOptions : route != 'client' ? clOptions : {}} />
      {
        loading ? <Spin/>
        :
        <>

        <div className=" w-full h-[70vh] " >
            {
              ( id ) ?
              <EmployeeCompletDoc target = {target} docId={id} />  
              :
              (target == 'client' && id )? 
              (<ClientCompletDoc target = {target} docId={id}  />)  :
              <>
              </>
                // <NotAvailable/>
            }
            {
              (!id )  &&
                <DocViewData data={data}  target={target}  />   
            }
        </div>
      </>
}
    </Page>
      
  )
}









function DocViewData({ data = [] ,  target = 'employee' }) {
  // console.log(data);
  return(
    <div className=" flex flex-col gap-4" >

      <NewItemDialog>
        <AddNewDoc target={target}/>
      </NewItemDialog>
      
      <div className=" grid grid-auto-fit-lg gap-4" >
        {
          data.length > 0 ?
          data.map(e => (
            <ViewTerm term={e} target={target}/>
          ) )
          : <Empty/>
        }
      </div>
    </div>
  )
}