

import axios from 'axios'
import Cookies from 'js-cookie'
import { model } from '..'

export
const stayModel = {
  create : async (modelDataData) => {
    let response = null
    const token = false;
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.put( model.backEndServer + '/stay/create' , modelDataData ,  {headers : {'access-token' : token, }})
                  .then(res => {
                    // console.log(res);
                    response = res.data;
                  })
                  .catch(err => {
                    // console.log(err);
                    response = err.response.data;
                  } );
    }
    catch(err)
    {
      // console.log(err)
      response = err;
    }
    return response
  },
  createPreStay : async (modelDataData) => {
    let response = null
    const token = false;
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.put( model.backEndServer + '/stay/create_pre_stay' , modelDataData ,  {headers : {'access-token' : token, }})
                  .then(res => {
                    // console.log(res);
                    response = res.data;
                  })
                  .catch(err => {
                    // console.log(err);
                    response = err.response.data;
                  } );
    }
    catch(err)
    {
      // console.log(err)
      response = err;
    }
    return response
  },
  getAll : async (setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer + '/stay/get_all' ,  {headers : {'access-token' : token, }})
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      console.log(err);
      return []
    }
    return []
  },
  getAllPreStay : async (setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer + '/stay/get_all_pre_stay' ,  {headers : {'access-token' : token, }})
      .then(res => {
        // console.log('preStay data is : ', res.data);
        setData(res.data);
      })
      .catch(err =>{
        console.log(err)
        setData([])
      })
    }catch(err)
    {
      console.log(err);
      setData([])
      
    }
    return []
  },

  update : async (modelData) => {
    let response = null
    const token = Cookies.get('_auth');
    try
    {
      const data = await axios.patch( model.backEndServer + '/stay/update' , modelData ,  {headers : {'access-token' : token, }})
        .then(res => {
          // console.log(res);
          response = res;
        })
        .catch(err => {
          console.log(err);
          response = err.response;
        } );
    }
    catch(err)
    {
      console.log(err)
      response = err;
    }
    return response
  },
  updateMoney : async (modelData) => {
    let response = null
    const token = Cookies.get('_auth');
    try
    {
      const data = await axios.patch( model.backEndServer + '/stay/update_money' , modelData ,  {headers : {'access-token' : token, }})
        .then(res => {
          console.log(res);
          response = res;
        })
        .catch(err => {
          console.log(err);
          response = err.response;
        } );
    }
    catch(err)
    {
      console.log(err)
      response = err;
    }
    return response
  },

  getById : async ( id , setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer + '/stay/get/'+ id ,  {headers : {'access-token' : token, }})
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      console.log(err);
      return []
    }
    return []
  },
  getClientById : async ( type , id , setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer + `/stay/get/${type}/${id}` ,  {headers : {'access-token' : token, }})
      .then(res => {
        console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      console.log(err);
      return []
    }
    return []
  },

  tableStanderd : {
    id : '#',
    clientName: "اسم المسئول عن الحالة" ,
    secClientName: "اسم المسئول الأخر" ,
    patientName: 'اسم الحالة' ,
    status : "الحالة",
    salary : "المرتب" , 
    paid : "مدفوع",
    subtract : "الباقي",
    total : "الأجمالي",
    workedDays : 'ايام العمل',
    freePeriod : 'ايام الراحه',
    additional : "اضافي",
    startDate : 'بداية الشهر ',
    endDate : 'نهاية الشهر ',
    
  },

}
 