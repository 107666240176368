import { Empty } from "antd";


export
function NotAvailable({des})
{
  return (
    <div className=" flex w-full justify-center items-center h-full " >
    <Empty
      imageStyle={{
        height: 60,
      }}
      description = {des}
    />
    </div>
  )
}