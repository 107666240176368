import ClipLoader from "react-spinners/ClipLoader";


export default
function LoadingScreen() {

  return (
    <div className=" w-full h-full p-4" >
      <div className=" flex flex-col items-center text-center w-full justify-center " >
                <ClipLoader/>
      </div>
    </div>
  )
}