import { FcAssistant, FcCheckmark } from "react-icons/fc";
import { IoMusicalNotes, IoPerson } from "react-icons/io5";
import { Checkbox, Empty } from "antd";
import { BiLogoWhatsapp, BiPhone } from "react-icons/bi";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Container from "../dashboard/container";
import Button from "../dashboard/asset/button";
import TextArea from "../dashboard/asset/textArea";
import { CiEdit } from "react-icons/ci";
import { model } from "../../model";
import { Field } from "../dashboard/containers/data/assets"
import TextField from "../dashboard/asset/textField";
import SelectInputField from "../dashboard/asset/selectInputField";
import SelectBox from "../dashboard/asset/selectBox";
import FindField from "../dashboard/asset/findField";
import { notesModel } from "../../model/notes";
import { LineNotify } from "../dashboard/asset/notify";
import { useUpdateData } from "../hooks/events";
import { FaNoteSticky, FaRegNoteSticky } from "react-icons/fa6";
import { updateDataEvent } from "../events";
import ScrollableDataView from "../dashboard/asset/dataView";
import { userModel } from "../../model/user";
import { employeeModel } from "../../model/employee";
import { clientModel } from "../../model/client";
import { dealModel } from "../../model/deal";
import { stayModel } from "../../model/stay";




export
function GetAllNotes({type = 'mini'})
{
  const [allNotes , setAllNotes] = useState([]);
  const [sortedNotes , setSortedNotes] = useState([]);
  
  useEffect(() =>{
    notesModel.getAll(setAllNotes)
  } , [])
  useUpdateData(() => {
    notesModel.getAll(setAllNotes)
  })

  useEffect(() => {
    const reversedNotes = allNotes.sort((a , b ) => b.id - a.id )
    setSortedNotes(reversedNotes);
  },[allNotes])

  // console.log(allNotes);
  return(
    <ScrollableDataView icon={<FaNoteSticky/>} title={'الملاحظات'} >
        {
          sortedNotes.length > 0 ?
          sortedNotes.map(e => (
            type == 'mini' ?
            <MiniToDoNotes target={e}  />
            :
            <ToDoNotes target={e}  />
          )) : <Empty/>
        }
    </ScrollableDataView>
    
      
  )
}



export
function ToDoNotes({target = {}})
{
  const [loading , setLoading]  =useState(false);
  const [values , setValues]  =useState({});
  const [logStatus , setLogStatus] = useState({});
  
  const handleInput = async (name , value) => {
    setLogStatus({})
    setValues(prev => ({...prev , [name] : value , 'id' : target.id }));
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    if(values.comment)
    {
      const res = await notesModel.update(values);
      if(res?.status == 200)
      {
        setLogStatus({ success : true , text : 'تم اضافة مهمة بنجاح'})
      }
      else
      {
        setLogStatus({ alert : true , text : 'حدث خطاء اثناء الأرسال'})
      }
    }
    else
      setLogStatus({ err : true , text : 'اكمل الحقول'})
    setLoading(false)
    updateDataEvent();
  }

  return(
    <Container
      onSubmit={handleSubmit}
      title2={
      <div className=" text-gray-400 text-sm flex items-center gap-2">
        <span>
          <IoPerson/>
        </span>
        <span>
        {
          target.authorName
        }
        </span>
      </div>}
      title={
        <div className=" flex items-center justify-between">
          <span className="flex items-center gap-2">
            <span>
              <FaRegNoteSticky/>
            </span>
              {
                target.name
              }
          </span>
          <span>
            {
              model.getRequestStatus(target)
            }
          </span>
        </div>
        }
        footer = {
          target.status == 'waiting' ?
          <Button loading = {loading}>
            تم التواصل
          </Button>
          :
          <div className="flex gap-2 items-center">
            <FcAssistant/>
            <Link to={'/users/'+target.receiverId} >
              {
                target.receiverName    
              }
            </Link>
          </div>
        }
        >
          <div className=" flex-col flex gap-4">
            <div className=" border-2  p-2 rounded-lg ">
              {
                target.body
              }
            </div>
            {
              target.targetId ?
              <div className=" border-2  p-2 rounded-lg " >
                <span>الهدف</span>
                <Field ownKey={model.getTargetSection(target)} value={target.targetName} reference={'/' + target.targetSection + '/' + target.targetId} />
                <Field ownKey={ 'نوع التواصل' } value={
              <div className=" grid grid-auto-fit-md gap-4">
                <div>
                  {
                    target.targetPhone
                  }
                </div>
                <div className=" flex items-center gap-4 text-2xl" >
                    {
                      target.whatsapp != 'none' && ( target.whatsapp == 'done' || target.status == 'waiting') && 
                      <div className=" flex items-center gap-4 border p-1 rounded-md">
                        <Link to={'https://wa.me/+20'+target.targetPhone}>
                            <BiLogoWhatsapp/>
                        </Link>
                        <div>
                          {
                            target.whatsapp == 'done' ?
                            <FcCheckmark/>
                            :
                            <Checkbox title="تم التواصل واتساب " onChange={ (e) => handleInput('whatsapp' , e.target.checked)} />
                          }
                        </div>
                      </div>
                    }
                    {
                      target.call != 'none' && ( target.call == 'done' || target.status == 'waiting') &&
                      <div className=" flex items-center gap-4 border p-1 rounded-md">
                        <Link to={'tel:+20'+target.targetPhone}>
                            <BiPhone/>
                        </Link>
                        <div>
                          {
                              <div>
                              {
                                target.call == 'done' ?
                                <FcCheckmark/>
                                :
                                <Checkbox title=" تم التواصل هاتفياً " onChange={(e) => handleInput('call' , e.target.checked)} />
                              }
                            </div>
                          }
                        </div>
                      </div>
                    }
                </div>
              </div>
            }  />
              </div>
              : ''
            }
            
            {
              target.status == 'done' ?
              target.comment
              :
              <TextArea err = {logStatus.err && 'ادخل تعليق'}   maxLength = {200} name = 'comment' placeholder = 'تعليق' onChange = {handleInput} />
            }
          </div>
    </Container>
  )
}
export
function MiniToDoNotes({target = {}})
{
  return(
    <Container
      
      title2={
      <div className=" text-gray-400 text-sm flex items-center gap-2">
        <span>
          <IoPerson/>
        </span>
        <span>
        {
          target.authorName
        }
        </span>
      </div>}
      title={
        <div className=" flex items-center justify-between">
          <span className="flex items-center gap-2">
              {
                target.name
              }
          </span>
          <span className=" text-sm" >
            {
              model.getRequestStatus(target)
            }
          </span>
        </div>
        }
        >
          <div className=" flex-col flex gap-4">
            <div className=" border-2  p-2 rounded-lg ">
              {
                target.body
              }
            </div>
          </div>
    </Container>
  )
}


export
function AddNewNotes() {

  const [values , setValues] = useState({});
  const [loading , setLoading] = useState(false);
  const [logStatus , setLogStatus] = useState({});
  const [allUsers , setAllUsers] = useState([]);
  const [section , setSection] = useState([]);
  const [targets , setTargets] = useState([]);
  const loadData = async () => {
    userModel.getAll(setAllUsers);
  }

  useEffect(() => {
    loadData();
  } , []);

  useEffect(() => {
    if(section == 'employee')
    {
      employeeModel.getAll(setTargets);
    }else
    if(section == 'client')
      {
      clientModel.getAll(setTargets);
      
    }else
    if(section == 'deal')
      {
      dealModel.getAll(setTargets);
      
    }else
    if(section == 'stay')
      {
      stayModel.getAll(setTargets);
      }
  },[section])



  const handleInput = (name , value) => {
    setLogStatus({ })
    setValues(prev => ({...prev , [name] : value}));
    if(name == "targetSection")
      setSection(value);
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    if(values.name && values.body)
    {
      const res = await notesModel.create(values);
      if(res?.status == 200)
      {
        setLogStatus({ success : true , text : 'تم اضافة مهمة بنجاح'})
      }
      else
      {
        setLogStatus({ alert : true , text : 'حدث خطاء اثناء الأرسال'})
      }
    }
    else
      setLogStatus({ err : true , text : 'اكمل الحقول'})
    setLoading(false)
    updateDataEvent();
  }
  return(
    <Container
      onSubmit={handleSubmit}
      footer={
        <Button loading = { loading }>
          اضافة
        </Button>
      }
      title={<TextField err = {!values.name && logStatus.err && 'هذا الحقل مطلوب'} placeholder = 'العنوان' name = 'name' onChange = {handleInput} >
          <CiEdit/>
        </TextField>}
    >
    <TextArea err = {!values.body && logStatus.err && 'هذا الحقل مطلوب'} maxLength = {200} name = 'body' placeholder = 'الرسالة' onChange = {handleInput} />
    <Field ownKey={ 'نوع التواصل' } value={
      <div className=" flex items-center gap-4 text-2xl" >
        <div className=" flex items-center gap-4 border p-1 rounded-md">
          <div >
            <BiLogoWhatsapp/>
          </div>
          <div>
            <div>
              <Checkbox title="  التواصل واتساب " onChange={(e) => handleInput('call' , e.target.checked)} />
            </div>
          </div>
        </div>
        <div className=" flex items-center gap-4 border p-1 rounded-md">
          <div >
            <BiPhone/>
          </div>
          <div>
            <div>
              <Checkbox title="  التواصل هاتفياً " onChange={(e) => handleInput('whatsapp' , e.target.checked)} />
            </div>
            
          </div>
        </div>
      </div>}  />
      <Field ownKey={'المستقبل'} value={
        <FindField data = {allUsers}  name = 'receiverId' /> } />
      <Field ownKey={
          <SelectBox onChange = {handleInput} placeholder = 'القسم' options = {{employee : 'موظف' , client : "عميل" , stay : "اقامة", deal : "منشأة"}} name  = 'targetSection' />
        }  value={ <div className=" grid grid-auto-fit gap-2 w-fit">
          <FindField onChange = {handleInput} name = 'targetId' placeholder = 'الهدف' data = {targets}/> 
        </div>} />
        <LineNotify alert={logStatus.alert} err={logStatus.err} success={logStatus.success} text={logStatus.text} />
    </Container>
  )
}