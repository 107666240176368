import { createRef, useEffect, useRef, useState } from "react";
import { employeeModel } from "../../../../../model/employee";
import Container from "../../../container";
import Button, { TabButton } from "../../../asset/button";
import { model } from "../../../../../model";
import { useDelay, useRefDimensions } from "../../../../hooks/customHook";
import TextField from "../../../asset/textField";
import SelectBox from "../../../asset/selectBox";
import { LineNotify } from "../../../asset/notify";
import { gender, job, periods, stayStatus } from "../../../asset/assets";
import FindField from "../../../asset/findField";
import { stayModel } from "../../../../../model/stay";
import { clientModel } from "../../../../../model/client";
import { Field, ScrollCard } from "../assets";
import { SpliterDiv } from "../../addNew";





export
function EmployeeData({modelId})
{
  const [values , setValues] = useState({id : modelId})
  const [person , setModel] = useState([]);
  const [personStay , setPersonStay] = useState([]);
  const [currentCard , setCurrentCard] = useState('1');
  const [patientData, setPatientData] = useState({});
  const [clientData, setClientData] = useState({});
  const [emptyField , setEmptyField] = useState(false);
  useDelay(() => {
    employeeModel.getStayByEmployeeId(modelId , setPersonStay);
    employeeModel.getById(modelId , setModel);
  },10)

  useEffect(() => {
    employeeModel.getStayByEmployeeId(modelId , setPersonStay);
    employeeModel.getById(modelId , setModel);
    // console.log(person);
  },[modelId])
  useEffect(() => {
    stayModel.getById(personStay.stayId , setPatientData);
    // console.log(patientData);
  },[personStay])

  useEffect(() => {
    clientModel.getById(patientData.mainClientId , setClientData);
    // console.log(patientData);
  },[patientData])


  const [customLog , setCustomLog] = useState({});
  const handleInput = (name , value) => {
    setCustomLog({})
    setValues(prev => ({...prev , [name] : value }));
    setValues(prev => ({...prev , id : modelId }));
  }
  useEffect(() => {
    console.log(values)
  },[values])
  
  
  const setChanges = async (e) =>{
    e.preventDefault()
    if(currentCard == '3') /// update main data
    {
      const res = await employeeModel.updateMainData(values)
      console.log(res);
      if(res.status === 200)
        {
          setEmptyField(true);
          setCustomLog(prev => ({...prev , msg : 'تمت التغيرات بنجاح' , success : true}))
          setCurrentCard(1)
          setCustomLog({})
          // setEmptyField(false);
        }
        else
        {
          setCustomLog(prev => ({...prev , msg : res.data.msg , err : true , success : false}))
        }
      }else
      if(currentCard == '4') /// update stay data
      {
        const res = await employeeModel.updateStayData(values)
        console.log(res);
        if(res.status === 200)
          {
            setEmptyField(true);
            setCustomLog(prev => ({...prev , msg : 'تمت التغيرات بنجاح' , success : true}))
            setCurrentCard(1)
            setCustomLog({})
            // setEmptyField(false);
          }
          else
          {
            setCustomLog(prev => ({...prev , msg : res.data.msg , err : true , success : false}))
          }
      // employeeModel.update(values)
    }
    
  }
  


  ///////////////////////////////
  
  const conRef = createRef(null);
  const dimensions = useRefDimensions(conRef);
  let width = `${dimensions.width}px`
  return(
    modelId > 0 &&
    <>
      <Container onSubmit={setChanges}  title={
        <div className=" flex justify-between">
        <span>
          {
            person.name
          }
        </span>
        <span>
          {
            model.getStatus(personStay)
          }
        </span>
        </div>
      } title2={person.job}
        header={
          <div className=" ">
            
            <TabButton activeColor="gray-100"  active={currentCard == '1'} OnClick={() => {setCurrentCard('1')}} secondary type='button'>
              البيانات الأساسية
            </TabButton>
            <TabButton activeColor="gray-100"  active={currentCard == '2'} OnClick={() => {setCurrentCard('2')}}  secondary type='button'>
              بيانات الأقامة
            </TabButton>
            <TabButton  activeColor="gray-100" active={currentCard == '3'} OnClick={() => {setCurrentCard('3')}}  secondary type='button'>
              تحديث البيانات
            </TabButton>
            {
              // personStay.length > 0 &&  toDo
              <TabButton  activeColor="gray-100" active={currentCard == '4'} OnClick={() => {setCurrentCard('4')}}  secondary type='button'>
                تحديث 
              </TabButton>
            }
          </div>
        }
        footer={ 
          
          <>
          {
          (currentCard == '3' || currentCard == '4') && 
            <Button>
              تأكيد التغيرات
            </Button>
          }
          <Button secondary OnClick={() => {modelId = null}} >
            الغاء
          </Button>
          </>
        }
      >
        <div className=" w-full bg-gray-100 shadow-lg border rounded-tl-2xl p-2  rounded-b-2xl  " ref={conRef}>
          <div className={`flex w-fit ease-in-out duration-300  ${currentCard == "2" ? " translate-x-1/4 " : currentCard == "3" ? " translate-x-2/4" : currentCard == '4' ? ' translate-x-3/4': ""}`}>
            <MainDataCard width = {width} person = {person}/>
            <StayDataCard stayData={personStay} width={width} patientData={patientData} clientData = {clientData}/>
            <UpdateMainDataCard width = {width} person = {person} customLog = {customLog} handleInput = {handleInput} emptyField = {emptyField} />
            <UpdateStayDataCard width = {width} person = {person} customLog = {customLog} handleInput = {handleInput} values={values} emptyFiled={emptyField} />
          </div>
        </div>
      </Container> 
    </>
  )
}



function UpdateStayDataCard({width  , customLog , handleInput , emptyFiled , values})
{
  const [stayData , setStayData] = useState([]);
  useEffect(() => {
    stayModel.getAll(setStayData);
  },[])
  return(
    <ScrollCard width={width} >
      <FindField data = {stayData} type = 'text' title = {'الأقامة'}  name = 'stayId' onChange = {handleInput}/>
        <TextField empty = {emptyFiled} type = 'date' title = {'تاريخ البداية'} name = 'startDate' onChange = {handleInput}/>
        <SpliterDiv>
          <TextField empty = {emptyFiled} type = 'number' placeholder = 'الراتب' name = 'salary' onChange = {handleInput}/>
            <span>في</span>
          <SelectBox options = {periods} value = 'month' name = 'wholeTime' onChange = {handleInput}/>
        </SpliterDiv>
            {
              values.wholeTime == 'day' &&
              <TextField empty = {emptyFiled} type = 'number' title = {'ايام العمل'}  name = 'workDays' onChange = {handleInput}/>
            }
        <SpliterDiv>
          <TextField empty = {emptyFiled} type = 'number' title = {'الأجازة'}  name = 'freePeriod' onChange = {handleInput}/>
          <SelectBox options = {periods} name = 'freePeriodType' onChange = {handleInput}/>
          <span>كل</span>
          <SelectBox options = {periods} value = 'month' name = 'freePeriodMain' onChange = {handleInput}/>
        </SpliterDiv>
        <TextField empty = {emptyFiled} type = 'number' title = {'ساعات العمل'}  name = 'workHours' onChange = {handleInput}/>
        <SpliterDiv>
          <SelectBox options = {stayStatus} value = {stayData.status} name = 'status' placeholder = 'الحالة' onChange = {handleInput} />
          {
            values.status != 'working' &&
            <TextField placeholder = 'تاريخ التوقف' empty = {emptyFiled} type = 'date'  name = 'stopDate' onChange = {handleInput}/>
          }
        </SpliterDiv>
        <LineNotify err ={customLog.err} success={customLog.success} alert={customLog.alert} text = {customLog.msg}/>
        
    </ScrollCard>
  )
}

function UpdateMainDataCard({width , person , customLog , handleInput , emptyField})
{


  return(
    <ScrollCard width={width} >
      <TextField empty = {emptyField} value = {person.name} err={customLog.nameErr ? customLog.msg : false} type = 'text' title = {'الأسم'}  name = 'name' onChange = {handleInput}/>
      <TextField empty = {emptyField} value = {person.cardId} err ={customLog.cardIdErr ? customLog.msg : false} type = 'number' title = {'البطاقة'}  name = 'cardId' onChange = {handleInput}/>
      <TextField empty = {emptyField} value = {person.phone} err ={customLog.phoneErr ? customLog.msg : false} type = 'tel' title = {'رقم الهاتف'} name = 'phone' onChange = {handleInput}/>
      <TextField empty = {emptyField} value = {person.phone2} type = 'tel' title = {'رقم هاتف اخر'} name = 'phone2' onChange = {handleInput}/>
      <TextField empty = {emptyField} value = {person.address} err={customLog.addressErr ? customLog.msg : false} type = 'text' title = {'العنوان'} name = 'address' onChange = {handleInput}/>
      <TextField empty = {emptyField} err={customLog.birthDateErr ? customLog.msg : false} type = 'date' title = {'تاريخ الميلاد'} name = 'birthDate' onChange = {handleInput}/>
      <TextField empty = {emptyField} value = {person.workDate} type = 'date' title = {'تاريخ التعيين'} name = 'workDate' onChange = {handleInput}/>
      <TextField empty = {emptyField} value = {person.nationality} type = 'text' title = {'الجنسية'} name = 'nationality' onChange = {handleInput}/>
      {/* <SelectBox value = {person.job} options = {'job'} title = {'الوظيفة'} name = 'job' onChange = {handleInput}/> */}
      <SelectBox value = {person.gender} options = {gender}  title = {'النوع'} name = 'gender' onChange = {handleInput}/>
      <LineNotify err ={customLog.err} success={customLog.success} alert={customLog.alert} text = {customLog.msg}/>
    </ScrollCard>
  )
}

function MainDataCard({width , person})
{

  return(
    <ScrollCard width = {width} >
      <Field ownKey={'الأسم'} value={person.name} />
      <Field ownKey={'رقم البطاقة'} value={person.cardId}/>
      <Field ownKey={'رقم الهاتف'} value={person.phone}/>
      <Field ownKey={'رقم هاتف ثاني'} value={person.phone2}/>
      <Field ownKey={'تاريخ الميلاد'} value={model.dateToText(person.birthDate)}/>
      <Field ownKey={'تاريخ التعيين'} value={model.dateToText(person.workDate)}/>
      <Field ownKey={'النوع'} value={model.getGender(person)}/>
      <Field ownKey={'الوظيفة'} value={model.getJob(person)}/>
      <Field ownKey={'العنوان'} value={person.address}/>
      <Field ownKey={'الجنسية'} value={person.nationality}/>
      <Field value={'بيانات الزيارات'} reference={'/visit/user/'+person.id}/>
    </ScrollCard> 
  )
}


function StayDataCard({stayData , width , patientData , clientData})
{
  
  
  return(
    <ScrollCard width={width} >
    {
      stayData.id ?
      <>
        <Field ownKey={'اسم الحالة'} value={patientData.patientName}  reference={`/stay/${patientData.id}`}/>
        <Field ownKey={'اسم المسئول عن الحالة'} value={clientData.name} reference={`/client/${clientData.id}`}/>
        <Field ownKey={'الراتب'} value={stayData.salary}/>
        <Field ownKey={'بداية الشهر'} value={model.dateToText(stayData.startDate)}/>
        <Field ownKey={'نهايةالشهر'} value={model.dateToText(model.getEndDate(stayData))}/>
        <Field ownKey={'ايام العمل'} value={stayData.workDays}/>
        <Field ownKey={'ساعات العمل'} value={stayData.workHours}/>
        <Field ownKey={'السلف'} value={stayData.paid}/>
        <Field ownKey={'الخصم'} value={stayData.deduct}/>
        <Field ownKey={'الأضافي'} value={stayData.additional}/>
        <Field ownKey={'الغياب'} value={model.getFreeInPeriod(stayData)}/>
        <Field ownKey={'عدد الأيام'} value={model.getWorkInPeriod(stayData)}/>
        <Field ownKey={'الباقي'} value={model.getTotal(stayData)}/>
      </>
      :
      <>
      <div>
        لا توجد بيانات اقامة لهذا الموظف 
      </div>
      </>
    }
  </ScrollCard>
  )
}