
import { useEffect, useRef, useState } from "react";
import { textBoxClassName, textValues } from "./assets";


/**
 * 
 * @param {style} 
 * @returns 
 */


export default
function SelectBox({
  value,
  onChange = () => null,
  placeholder,
  name,
  type,
  err,
  success,
  children,
  title,
  options = null ,
  empty,
  disabled
})
{
  const textRef = useRef()
  const [active, setActive] = useState();
  const FieldTitle = textValues.FieldTitle;
  const Child = textValues.ChildSection;
  const Options = textValues.SelectOptions;

  useEffect(() => {
    textRef.current.value = ''
  } , [empty])

  return (
    <div className="md:flex gap-3">
          

      <div>
          <FieldTitle title={placeholder || title} name={name} show={active || ( textRef.current ?  textRef.current.value : false)}/>
        <div className={textValues.fieldClassName(active , err , success)}>
          <Child children={children} textRef={textRef} active={active}/>                  
          <select
            ref={textRef}
            className={textValues.inputClassName}
            onChange={(v) =>{onChange(name , v.target.value)} }
            onFocus={() => setActive(true)}
            onBlur={() => setActive(false)}
            type={type}
            name={name}
            id={name}
            disabled = {disabled}
            defaultValue={value} >
            <Options options={options} value={value}/>
          </select>
        </div>
      </div>
    </div>
  )
}


// interface TextFieldProps 
//   {
//     value : String;
//     onChange: (val : string) => void;
//     placeholder?: String;
//     name?: String;
//     type?: 'email' | 'password' | 'text';
//   }