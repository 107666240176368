
/// external imported
import { FcDeleteRow, FcDocument } from "react-icons/fc";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BiCheck, BiEdit, BiError, BiPin, BiRecycle, BiSolidCommentEdit, BiSolidEdit, BiX } from "react-icons/bi";

/// local imported
import { updateDataEvent } from "../../events";
import Container from "../container";
import TextField from "../asset/textField";
import TextArea from "../asset/textArea";
import Button from "../asset/button";
import { docModel } from "../../../model/doc";
import { IoIosRemove, IoMdRemove } from "react-icons/io";
import { RiDeleteBinLine } from "react-icons/ri";
import { PopDialog } from '../asset/popDialog'







export
function AddNewDoc({target})
{
  const [values , setValues] = useState();
  const [loading , setLoading] = useState();
  const handleInput = (name , value) => {
    setValues(prev => ({...prev , [name] : value}));
  }

  
  const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      const res = await docModel.createTerm(values , target);
      updateDataEvent();
      setLoading(false);
  }

  
  return(
    <Container
      onSubmit={handleSubmit}
      title = {'اضافة بند جديد'}
      title2={target == 'client' ? 'عملاء' : target == 'employee_office' ? 'موظف مكتب' : target == 'employee_visit' ? 'موظف زيارات' : 'موظف اقامات'}
      footer={
        <Button loading = {loading} >
          اضافة البند
        </Button>
      }
    >
      <TextField  onChange = {handleInput}  name = 'title' placeholder = 'عنوان البند' />
      <TextArea  onChange = {handleInput}  name = 'term' maxLength = {500} />
    </Container>
  )
}



export
function ViewTerm({term = {} , target})
{

  const [changeTerm , setChangeTerm] = useState(false);
  const [showPop , setShowPop] = useState(false);
  const [values , setValues] = useState();
  const [loading , setLoading] = useState();
  const handleInput = (name , value) => {
    setValues(prev => ({...prev , [name] : value}));
  }
  
  const handleSubmit = async () => {
      // e.preventDefault();
      setLoading(true);
      const res = await docModel.update(values , target);
      setLoading(false);
      updateDataEvent();
      setChangeTerm(false);
  }

  const handleChange = () =>{
    setChangeTerm(true);
    setValues(prev => ({ ...prev , 'id' : term.id }))
  }

  const handleRemove = async () => {
      console.log('remove listner ')
      // setLoading(true);
      setShowPop(false);
      const res = await docModel.remove(term.id , target);
      console.log(res);
      setLoading(false);
      updateDataEvent();
      setChangeTerm(false);
  }

  return(<Container
    
    title={<div className=" flex justify-between gap-4" >
      
      <span className=" text-2xl text-sky-600" >
        {
          !changeTerm ?
          term.title
          :
          <TextField value = {term.title}  onChange = {handleInput}  name = 'title'/>
        }
      </span>
      {

        !changeTerm ?
        <div className=" flex gap-2 "  >
        <Button secondary type={'mm'} OnClick={handleChange} >
            <BiEdit/>
        </Button>
        
        <Button secondary loading = {loading} type={'button'} OnClick={() => setShowPop(true)} >
        <RiDeleteBinLine />
        </Button>
        </div>
          :
      <div className=" flex gap-2"  >
        <Button secondary type={'button'} OnClick={handleSubmit} loading = {loading} >
          <BiCheck/>
        </Button>
        <Button secondary type={'button'} OnClick={() => setChangeTerm(false)} >
          <BiX/>
        </Button>
      </div>
      }
    </div>}
    title2={<span className=" text-sky-600 text-sm ">
      <div>
        <span>
          #
          {
            term.id
          }
        </span>

      </div>
    </span>}
  >
    <PopDialog
      title={
        <div className=" flex gap-2 items-center text-red-600 " >
          <span>
            <RiDeleteBinLine/>
          </span>
          <span>
            {
              'تأكيد حذف هذا البند'
            }
          </span>
        </div>
      }
    setShow={setShowPop} show = {showPop} onSubmit={handleRemove}>
        <div>
          <div className=" text-lg" >
            {
              term.title
            }
          </div>
          <div className=" text-sm" >
            {
              term.term
            }
          </div>
        </div>
    </PopDialog>
    <div className=" text-lg">
      {
        !changeTerm ?
        term.term
        :
        <TextArea value = {term.term}  onChange = {handleInput}  name = 'term' maxLength = {500} />
      }
    </div>

  </Container>)

}


