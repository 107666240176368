import { useEffect, useState } from "react";
import { employeeModel } from "../../../../model/employee";
import MainEmployeeTable, { StayEmployeeTable } from "../table/employeeTable";
import Container from "../../container";
import { TabButton } from "../../asset/button";
import { IoSearchOutline } from "react-icons/io5";
import SelectBox from "../../asset/selectBox";
import TextFilterField from "../../asset/textFilterField";
import { useFindInArray } from "../../../hooks/customHook";
import { clientModel } from "../../../../model/client";
import MainClientTable from "../table/clientTable";
import StaysTable from "../table/stayTable";
import { stayModel } from "../../../../model/stay";
import { stayStatus } from "../../asset/assets";
import { suppliesModel } from "../../../../model/supplies";
import { priceModel } from "../../../../model/price";
import SuppliesMainTable from "../table/suppliesTable";
import PricingMainTable from "../table/pricingTable";
import { useUpdateData } from "../../../hooks/events";




export  function EmployeeTable()
{
  
  const [temData , setTemData] = useState([]);
  const [temStayData , setTemStayData] = useState([]);
  const [srchText , setSrchText] = useState({});
  const [mainData , setMainData] = useState(true);
  const [mainOptions , setMainOptions] = useState({});
  const [loading , setLoading] = useState(false);
  // const [srchKey , setSrchKey] = useState('');
  const [fieldData , setFieldData] = useState([]);
  const [fieldStayData , setFieldStayData] = useState([]);

  const loadData = async () => {
    setLoading(true);
    if(mainData)
    {
      const data = await 
      employeeModel.getAll(setFieldData) 
    }
    else
    {
      const data2 = await 
      employeeModel.getAllStay(setFieldStayData)
    }
    setLoading(false);
  }
  
  
  useEffect( () => {
    loadData();
    if(mainData)
    {
      setMainOptions({ name : 'الأسم' , cardId : 'رقم البطاقة', phone : 'الهاتف' ,})
      // setSrchText('name');
    }
    else
    {
      setMainOptions({id : 'كود الأقامة' , employeeName : 'اسم الموظف' ,}) ///// todo *  
      // setSrchText('id');
    }
  }, [mainData])

  useUpdateData(() => {
    loadData();
  })


  const handleInput = (n , v) => {
    setSrchText({
      name : n ,
      value : v
    })
    // console.log(n , v)
  }

  // console.log(fieldData);
  useFindInArray(fieldData , srchText.value , setTemData , (srchText.name || 'id')) ;
  useFindInArray(fieldStayData , srchText.value , setTemStayData , (srchText.name || 'id')) ;
  
  
  return(
      <>
        <Container title={'بيانات الموظفين  '}  
          header={
            <div className=" ">
              
              <TabButton activeColor="gray-100"  active={mainData} OnClick={() => setMainData(true)} secondary type='button'>
                البيانات الأساسية
              </TabButton>
              <TabButton activeColor="gray-100"  active={!mainData} OnClick={() => setMainData(false)}  secondary type='button'>
                بيانات الأقامة
              </TabButton>
            </div>
          }>
            <div dir="rtl">
              <div dir="rtl" className=" p-2 flex justify-around">
                <div className="flex w-fit">
                  <TextFilterField setValue = {setSrchText}   options = {mainOptions} placeholder = "بحث بواسطة ...">
                    <IoSearchOutline/>
                  </TextFilterField>
                </div>
                {
                  !mainData &&
                  <div>
                    <SelectBox options ={stayStatus} onChange = {handleInput} name = 'status' />
                  </div>
                }
                <div>
                </div>
              </div>
              <div className = " w-full overflow-x-scroll max-h-96 scroll-style">
                {
                  mainData &&
                  <MainEmployeeTable stander={employeeModel.MainStandardTable} data={temData} loading = {loading} />
                }
                {
                  !mainData &&
                  <StayEmployeeTable stander={employeeModel.StayStandardTable} data={temStayData} loading = { loading }/>
                }
              </div>
            </div>
        </Container>
      </>
  )
}

export  function ClientTable()
{
  
  const [temData , setTemData] = useState([]);
  const [srchText , setSrchText] = useState({});
  const [mainData , setMainData] = useState(true);
  const [loading , setLoading] = useState(false);
  // const [srchKey , setSrchKey] = useState('');
  
  const [fieldData , setFieldData] = useState([]);
  const loadData = async () => {
    setLoading(true);
    const data = await
    clientModel.getAll(setFieldData);
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, [])

  useUpdateData(() => {
    loadData();
  })
  
  // console.log(srchText);
  useFindInArray(fieldData , srchText.value , setTemData , (srchText.name || 'name')) ;
  
  
  return(
      <>
        <Container title={'بيانات العملاء  '}  
          >
            <div dir="rtl">
              <div dir="rtl" className=" p-2 flex justify-around">
                <div className="flex w-fit">
                  <TextFilterField setValue = {setSrchText}  options = {{ name : 'الأسم' , cardId : 'رقم البطاقة', phone : 'الهاتف' ,}} placeholder = "بحث بواسطة ...">
                    <IoSearchOutline/>
                  </TextFilterField>
                </div>
                <div>
                </div>
              </div>
              <div className = " w-full overflow-x-scroll max-h-96 scroll-style">
                {
                  mainData &&
                  <MainClientTable loading = {loading} stander={clientModel.MainStandardTable} data={temData} />
                }
                {
                  // !mainData &&
                  // <MainEmployeeTable stander={employeeModel.tableStanderd} data={temData} />
                }
              </div>
            </div>
        </Container>
      </>
  )
}

export  function SuppliesTable()
{
  
  const [temData , setTemData] = useState([]);
  const [srchText , setSrchText] = useState({});
  const [mainData , setMainData] = useState(true);
  // const [srchKey , setSrchKey] = useState('');
  
  const [fieldData , setFieldData] = useState([]);
  useEffect(() => {
    suppliesModel.getAll(setFieldData);
    
  }, [])
  
  // console.log(srchText);
  useFindInArray(fieldData , srchText.value , setTemData , (srchText.name || 'name')) ;
  
  
  return(
      <>
        <Container title={'قائمة المستزلمات '}  
          >
            <div dir="rtl">
              <div dir="rtl" className=" p-2 flex justify-around">
                <div className="flex w-fit">
                  <TextFilterField setValue = {setSrchText}  options = {{ name : 'الأسم'}} placeholder = "بحث بواسطة ...">
                    <IoSearchOutline/>
                  </TextFilterField>
                </div>
                <div>
                </div>
              </div>
              <div className = " w-full overflow-x-scroll max-h-96 scroll-style">
                {
                  mainData &&
                  <SuppliesMainTable stander={suppliesModel.standard} data={temData} />
                }
              
              </div>
            </div>
        </Container>
      </>
  )
}
export  function PricingTable()
{
  
  const [temData , setTemData] = useState([]);
  const [srchText , setSrchText] = useState({});
  const [mainData , setMainData] = useState(true);
  // const [srchKey , setSrchKey] = useState('');
  
  const [fieldData , setFieldData] = useState([]);
  useEffect(() => {
    priceModel.getAll(setFieldData);
  }, [])

  useEffect(() => {
    console.log(fieldData);
  } , [fieldData])
  
  // console.log(srchText);
  useFindInArray(fieldData , srchText.value , setTemData , ( srchText.name || 'area')) ;
  
  
  return(
      <>
        <Container title={'قائمة التسعير'}  
          >
            <div dir="rtl">
              <div dir="rtl" className=" p-2 flex justify-around">
                <div className="flex w-fit">
                  <TextFilterField setValue = {setSrchText}  options = {{ area : 'المنطقة' ,section : 'القسم' , type : 'النوع' }} placeholder = "بحث بواسطة ...">
                    <IoSearchOutline/>
                  </TextFilterField>
                </div>
                <div>
                </div>
              </div>
              <div className = " w-full overflow-x-scroll max-h-96 scroll-style">
                {
                  mainData &&
                  <PricingMainTable stander={priceModel.standard} data={temData} />
                }
              
              </div>
            </div>
        </Container>
      </>
  )
}

export  function StayTable()
{
  
  const [temData , setTemData] = useState([]);
  const [srchText , setSrchText] = useState({});
  const [loading , setLoading] = useState(false);
  
  const [fieldData , setFieldData] = useState([]);

  // const temData = useArrayFilter(fieldData , srchText.name , srchText.value );

  console.log(temData);
  useEffect(() => {
    stayModel.getAll(setFieldData);
    console.log(fieldData);
  }, [])
  
  const handleInput = (n , v) => {
    setSrchText({
      name : n ,
      value : v
    })
    console.log(n , v)
  }

  useFindInArray(fieldData , srchText.value , setTemData , (srchText.name || 'patientName')) ;
  // console.log(srchText);
  
  return(
      <>
        <Container title={'قائمة الأقامات'}  
          >
            <div dir="rtl">
              <div dir="rtl" className=" p-2 flex justify-around">
                <div className="flex w-fit">
                  <TextFilterField setValue = {setSrchText}  options = {{ name : 'الأسم' , cardId : 'رقم البطاقة', phone : 'الهاتف' ,}} placeholder = "بحث بواسطة ...">
                    <IoSearchOutline/>
                  </TextFilterField>
                </div>
                <div>
                  <SelectBox options ={stayStatus} onChange = {handleInput} name = 'status' />
                </div>
                <div>
                </div>
              </div>
              <div className = " w-full overflow-x-scroll max-h-96 scroll-style">
                <StaysTable stander={stayModel.tableStanderd} data={temData} loading = {loading} />
              </div>
            </div>
        </Container>
      </>
  )
}


