
import { useEffect, useRef, useState } from "react";
import { useFindInArray, useOutsideAlerter } from "../../hooks/customHook";
import { IoSearchOutline } from "react-icons/io5";
import { FilterBox, textValues } from "./assets";
import { BiCheck, BiCheckCircle, BiErrorCircle } from "react-icons/bi";


/**
 * 
 * @param {style} 
 * @returns 
 * require a name a nd id of element
 */

export default
function SelectInputField({
  value,
  onChange = () => null,
  placeholder,
  name,
  type,
  err,
  success,
  title,
  data,
  empty,
  disabled,
  endActionButton = null,
  textOfEndAction 
})
{
  const textRef = useRef()
  const containerRef = useRef();
  
  const [active , setActive] = useState(false);
  const [newData , setNewData] = useState(null);
  const [currentText , setCurrentText] = useState('');
  const [agentId , setAgentId] = useState([]);

  
  const FieldTitle = textValues.FieldTitle;
  const Child = textValues.ChildSection;

  useEffect(() => {
    textRef.current.value = value || '';
    if(value)
    {
      setCurrentText(value);
    }
  },[value])

  useEffect(() => {
    console.log(newData);
    if(newData)
    {
      textRef.current.value = newData.name;
      setCurrentText(newData.name);
    }
  },[newData])


  const handleChange = () => {
    setCurrentText(textRef.current.value);
    setActive(true);
  }
  
  const handlePickData = (e) =>{
    setNewData(e);
    if(e){
      onChange( name , e) ; 
      setActive(false);
      textRef.current.focus = false;
    } 
    else if(currentText)
    {
      onChange( name , {
        name : textRef.current.value ,
        id : 0
      }) ; 
      setActive(true);
    }
    else
    {
      onChange( name , '') ; 
    }
  }

  useOutsideAlerter(containerRef , () => {
    setActive(false);
  })


  return ( 
    <div ref={containerRef}>
        <FieldTitle title={ placeholder || title } name={name} show={active || textRef.current?.value}/>
      <div className=" relative">
        {
          data &&
          <FilterBox showNotFound = {false} setDataValue={handlePickData} endActionButton={endActionButton} textOfEndAction={textOfEndAction} active = {active} data = {data} filterText={currentText} />
        }
        <div className={textValues.fieldClassName(active , err , success )}>  
          <Child children={<IoSearchOutline/>} textRef={textRef} active={active}/>
          {
            newData &&
            <div className=" text-lg text-green-500">
              <BiCheckCircle/>
            </div>
          }
          <input
            ref={textRef}
            autoComplete="false"
            className={textValues.inputClassName}
            onChange={ handleChange }
            onFocus={() => setActive(true)}
            type={type}
            disabled = {disabled}
            name={name}
            id={name}
            defaultValue={value} />
        </div>
      </div>
        {
          success && 
          <div className=" text-green-500 text-sm flex items-center gap-3" >
              <BiErrorCircle/>
              <span >
                {
                  success
                }
              </span>
          </div>
        }
        {
          err && 
          <div className=" text-red-500 text-sm flex items-center gap-3" >
              <BiErrorCircle/>
              <span >
                {
                  err
                }
              </span>
          </div>
        }
    </div>
  )
}


