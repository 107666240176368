
import Container from '../dashboard/container'
import Button from '../dashboard/asset/button'
import TextField from '../dashboard/asset/textField'
import { useEffect, useState } from 'react'
import { LineNotify } from '../dashboard/asset/notify';
import SelectInputField from '../dashboard/asset/selectInputField';
import { model } from '../../model';
import { suppliesModel } from '../../model/supplies';
import { useUpdateData } from '../hooks/events';
import { updateDataEvent } from '../events';

/***
 * item
 * 
 * id
 * name
 * cost
 * count
 * wholesale
 * addDate
 * expireDate
 * 
 */


export default function Supplies({id}) {
  const [checked , setChecked] = useState(false);
  const [currentObj , setCurrentObj] = useState({name : ''})
  const [data , setData] = useState([]);
  const [errLog , setErrLog] = useState({});

  const [values , setValues] = useState({})

  useEffect(() => {
    suppliesModel.getAll(setData);
  } , [])

  useUpdateData(() =>{
    suppliesModel.getAll(setData);
  })
  
  useEffect(() => {
    handleInput('name' , {id : id})
  } , [id])


  const handleInput = ( name , value ) => {
    // console.log(name , value , values , currentObj);
    let nameValue = value?.name || value
    let idValue = value?.id ;
    setValues(prev => ({...prev , [name] : nameValue }));
    if(idValue >= 0)
    {
      setValues(prev => ({...prev , 'id' : idValue}));
    }
    
    setChecked(false);
    setErrLog({});
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    let statusOfRequest = 200;
    updateDataEvent();
    if(currentObj.id)
    {
      try
        {
          const response = await suppliesModel.update(values);
          statusOfRequest = response.status
          // console.log(response)
        }catch(err)
        {
          console.log(err);
          handleErr(400);
        }      
    
    }
    else
    {
      if(handleErr(statusOfRequest))
      {
        try
        {
          const response = await suppliesModel.create(values);
          statusOfRequest = response.status
          console.log(response);
          handleErr(statusOfRequest)
        }catch(err)
        {
          console.log(err);
          handleErr(400);
        }  
      }
    }
    // console.log(values , errLog);
  }

  useEffect(()=> {
    console.log(values);
    if(values.id)
    {
      let obj = data.find(e => e.id === values.id)
      setCurrentObj(obj);
    }
    else
      setCurrentObj({name : values.name || ''})
  },[values])


  const handleErr = (status) =>{
    setChecked(true);
    let isErr = checkErr()
    if(isErr)
      setErrLog({
        text : "اكمل الحقول اولاً",
        status : 'err'
    })
    else
    if(values.cost * 1 < values.wholesale * 1)
      setErrLog({
        text : "سعر البيع اقل من سعر الجملة",
        status : 'err'
    })
    else
    if(status > 300)
        setErrLog({
          text : " يوجد خطأ يرجي المحاولة لاحقاً ",
          status : 'err'
      })
    else
      setErrLog({
        text : "تم تسجيل صنف جديد",
        status : 'success'
    })
    return isErr
  }
  const errLine = (target , lineText = null)=>{
    if(!lineText) lineText = 'ادخل الحقل';
    return (!target && checked) && lineText
  }

  const checkErr = () => {
    return !( 
      values.itemName && 
      values.wholesale &&
      values.count &&
      values.cost &&
      values.expDate 
     )
  }

  return(
    <div>
      <Container
        onSubmit={handleSubmit}
        title={'اضافة سلعة'}
        footer={

          <Button>
            {
              currentObj.id ? 'تأكيد التغيرات' : 'اضافة صنف جديد'
            }
          </Button>
        }
      >

        <SelectInputField value = { currentObj.name || '' } data = {data}  success = {currentObj.id && 'الصنف موجود مسبقاً و سيتم التعديل عليه'} err = {errLine(values.name )} onChange = {handleInput} name = 'name'       type = 'text'   placeholder = 'اسم الصنف' />
        <TextField value = {currentObj.wholesale}  err = {errLine(values.wholesale )} onChange = {handleInput} name = 'wholesale'  type = 'number' placeholder = 'سعر الجملة' step = "0.01"  >جم</TextField>
        <TextField value = {currentObj.count} err = {errLine(values.count )} onChange = {handleInput} name = 'count'      type = 'number' placeholder = 'الكمية' />
        <TextField value = {currentObj.cost} err = {errLine(values.cost )} onChange = {handleInput} name = 'cost'       type = 'number' placeholder = 'سعر البيع' step = "0.01" >جم</TextField>
        <TextField onChange = {handleInput} name = 'addDate'    type = 'date'   placeholder = 'تاريخ الدخول' />
        <TextField err = {errLine(values.expDate )} onChange = {handleInput} name = 'expDate'    type = 'date'   placeholder = 'تاريخ الانتهاء' />
        
        <LineNotify text={errLog.text} success={errLog.status == 'success'} err={errLog.status == 'err'}  />
    
      </Container>
    </div>
  )
}