import { useEffect, useState } from "react"
import { employeeModel } from "../../../model/employee"
import { useFindInArray } from "../../hooks/customHook"




export 
const textValues = {
  fieldClassName : (active , err , success ) => {
    return ` sm:text-sm text-xs disabled:bg-gray-200
    disabled:bg-gray-400
    ${(!err && !success) && 'bg-white border-slate-400'}
    flex items-center p-1 overflow-hidden  border 
    rounded-lg w-full h-fit
    ${active && `  outline outline-1 ${err ? "outline-red-500 border-red-500" : " outline-blue-500 border-blue-500 "} `}
    ${err && " border-red-400 text-red-800 bg-red-50 "}
    ${success && " border-green-400 text-green-800 bg-green-50 "}` 
  } ,
  checkBoxClassName : (active , err , success) => {
    return ` 
    ${(!err && !success) && 'bg-gray border-slate-400'}
    flex items-center  overflow-hidden  border 
    w-fit h-fit
    ${active && `  outline outline-1 ${err ? "outline-red-500 border-red-500" : " outline-blue-500 border-blue-500 "} `}
    ${err && " border-red-400 text-red-800 bg-red-50 "}
    ${success && " border-green-400 text-green-800 bg-green-50 "}` 
  } ,
  FieldTitle : ({title , name , show}) => {
    return (
      <div className=" relative ">
        <div 
        className={` absolute z-10  w-fit overflow-hidden   duration-100 ease-linear 
        ${show    ? ' -translate-y-3 text-black bg-white -translate-x-2 font-bold text-sm ' 
                  : ' text-black/50 translate-y-1/4 -translate-x-8  font-normal  '} `} >
          { title &&
            <label htmlFor={name} className=" whitespace-nowrap w-fit px-1  rounded-md my-1 ">{title}</label>
          }
        </div>
      </div>
    )
    
  },
  ChildSection : ({children , active , textRef}) => {
    return(
      <div>
        { children &&
          <div onClick={() => textRef.current.focus()} className={`" px-1 " ${active && ' text-blue-500'}`}>
            {
              children
            }
          </div>
        }
      </div>
    )
  },
  SelectOptions : ({options , value}) =>{
    return(
      <>
        {
            options &&
            Object.keys(options).map(e => (
              <option selected = {e == value} value={e} >
                {options[e]}
              </option>))
          }
      </>
    )
  },                
  inputClassName : "w-full outline-none px-3 py-1.5 h-fit bg-inherit  "
} 


export
const gender  = {
  [null] : '',
  male : 'ذكر' ,
  female : 'انثي',
}


export 
const periods = {
  day : 'يوم',
  week : 'إسبوع',
  month : 'شهر',
  year : 'سنة',
}

export
const stayStatus = {
  '' : ' ',
  working : 'شغال',
  waiting : 'انتظار',
  stop : 'متوقفة',
  available : 'متاحة',
}


export

function FilterBox({active , data = [] , showNotFound = true , filterText  , endActionButton , textOfEndAction ,  setDataValue })
{
  const Button = ({children , action = () => null}) => {
    return(
      <div onClick={action} className=" flex gap-2 w-full ease-linear duration-200 border p-2 rounded-lg 
      hover:bg-sky-300 cursor-pointer
        hover:gap-4
        hover:outline
        hover:border-none
        hover:outline-1 outline-sky-700
      " >
        {
          children
        }
      </div>
    )
  }

  const [temData , setTemData] = useState([]);

  useFindInArray(data , filterText , setTemData , 'name')

  useEffect(() => {
    let found = null
    const handleSameText = () => {
      if(data.length > 0)
      {
        data.map(e => {
          if(e.name == filterText)
          {
            found = e
          }
        })
      }
      
      return found
    }
    setDataValue(handleSameText());
  },[filterText])

  return(
    <div >
      {
        temData.length > 0  && 
        active && 
        <div className=" scroll-style z-20 bg-white max-h-40 absolute overflow-y-scroll p-2 overflow-hidden gap-2 top-full w-full mt-1 rounded-lg  flex flex-col shadow-lg">
          {
            temData.length > 0 &&
            temData.map(e => (
              <Button action={() => setDataValue(e)}>
                <span>
                  {
                    e.id
                  }
                </span>
                <span>
                  |
                </span>
                <span>
                  {e.name || e.patientName}
                </span>
              </Button>
              ))
              ||
              (
              <div  className=" w-full ease-linear duration-200  p-2 rounded-lg text-center" >
                لا توجد بيانات
              </div> 
              )              
            
          } 
          {
            endActionButton &&
            <div className=" border-t-2 pt-2 ">
              <Button action={endActionButton} >
                {
                  textOfEndAction || 'اضافة'
                }
              </Button>
            </div>
          }
        </div>
      }
    </div>
  )
}
