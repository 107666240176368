import Container from "../container";
import Button from "./button";

export
function PopDialog({setShow = () => null , show = false  , onSubmit = () => null  , children , title})
{
  
  return( show && 
    <div className=" relative">
      <div 
      // onClick={setShow(false)}
      className=" fixed w-screen h-screen  bg-black/30 z-20 top-0 left-0 flex justify-center items-center">
        <Container
        title={title}
        footer={
          <div className=" flex w-full justify-around">
            <Button type={'button'} OnClick={onSubmit}>
              تأكيد
            </Button>
            <Button secondary type={'button'} OnClick={() => setShow(false)}>
              إلغاء
            </Button>
          </div>
        }
        >
          {
            children
          }
        </Container>
      </div>
    </div>
  )
}