import PropTypes from "prop-types"
import ClipLoader from "react-spinners/BarLoader";


export default
function Button({
  children,
  primary,
  secondary,
  OnClick,
  type,
  disabled,
  theme,
  loading = false,
})
{ 
  // !type &&
  // secondary ? type = 'button' : type = 'submit'
  return (
    <button 
    disabled = {disabled || loading}
    className={`" px-3 py-1.5 border rounded-lg  outline-none w-fit h-fit ease-linear duration-300
     disabled:bg-gray-400 disabled:text-white
    ${ (primary || (!primary && !secondary) ) 
      && " border-none bg-blue-600 text-white hover:bg-blue-500     " }
    ${ secondary    
      && " border-slate-400 bg-slate-50  text-slate-500 hover:bg-blue-100     " } 
      "`}
    onClick={ OnClick}
    type={type}
    >{loading ?
      <div>
        <ClipLoader
        // style={{
        //   backgroundColor : 'mediumpurple',
        // }}
        color={'white'}
        loading={loading}
        // cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      </div> 
      :children
      }</button>
  )
}

export
function TabButton({
  children,
  primary,
  secondary,
  OnClick,
  type,
  active,
  activeColor
})
{ 
  return (
    <button 
    className={`" px-3 py-1.5    outline-none w-fit h-fit
    ${active && `" bg-${activeColor} text-black  border border-b-0   translate-y-0.5  rounded-t-xl  
                    before:w-20 before:h-20 before:text-white before:contents['ali'] "`}
    ${ (primary || (!primary && !secondary) ) 
      && " border-none bg-blue-600 text-white hover:bg-blue-500     " }
    ${ secondary    
      && `" border-slate-400 bg-inherit  text-slate-500 ${!active && 'hover:bg-slate-100'}     "` } 
      "`}
    onClick={ OnClick}
    type={type}
    >
      {
        

        children
      
      }
      
      </button>
  )
}
