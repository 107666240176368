

function newEvent(eventName = null , obj = null) {
    const event = 
    new CustomEvent(eventName , {detail : obj});
    document.dispatchEvent(event);
}


export
const dialogEvents = {
  newEmployee : () => newEvent('showNewEmployeeDialog'),
  newClient   : () => newEvent("showNewClientDialog"),
  newStay     : () => newEvent("showNewStayDialog"),
  newUser     : () => newEvent("showNewUserDialog"),
  employData  : (obj) => newEvent("showEmployeeDialog" , {detail : obj}),
  suppliesData  : (obj) => newEvent("showSuppliesDialog" , {detail : obj}),
}

export const pageNameEvent = (pageName) => {
  newEvent('newPageEvent' , pageName);
  // console.log(" Evennted ");
}

export const updateDataEvent = () => {
  newEvent('updateData');
  // console.log(" Evennted ");
}

export const showMenuEvent = () => {
  newEvent('showMenu');
  // console.log(" Evennted ");
}



