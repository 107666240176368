import Button from '../../dashboard/asset/button'
import TextField from '../../dashboard/asset/textField'

import { MdOutlineMail } from "react-icons/md";
import { IoLockClosedOutline } from "react-icons/io5";
import { useState , ReactComponent } from 'react';

import axios from 'axios';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { useNavigate  } from 'react-router-dom';
import { LineNotify } from '../../dashboard/asset/notify';
import { ReactComponent as Logo } from '../../../svg/Logo.svg'
import { model } from '../../../model';


export default
function LogInPage()
{
  const signIn = useSignIn();
  const navigator = useNavigate();
  const [formData , setFormData] = useState({username : '' , password : ''})
  const [msg , setMsg] = useState();
  const [loading , setLoading] = useState(false);
  const handelInput = (name , value) =>{
    setFormData(prev => ({...prev , [name] : value}))
    // console.log(formData);
    setMsg(null);
  }
  const handelSubmit = async (e) =>
  {
    e.preventDefault();
    try
    {
      setLoading(true);
      const res = await axios.post( model.backEndServer + '/login' , formData);
      signIn({
        auth:{
          user:{
            name:'ali',
          },
          token : res.data.token,
          type:'Bearer',
        },
        expireIn : 3600,
        authState:{username: 'ali mohamed ali' , treat : 'asdkfkk'},
      })
      
      sessionStorage.removeItem('permissions_');
      navigator('/')
    }
    catch (err)
    {
      setMsg('بيانات غير صحيحة')
    }
    setLoading(false);
  }
  return(
  <div className="p-5 bg-slate-800 gap-4 flex h-full items-center justify-center">
    <form onSubmit={handelSubmit} className="flex flex-col gap-2 items-center gap-5 p-5 bg-slate-100 rounded-lg shadow-lg" >
      <div className=' h-20'>
        <svg className=' fill-sky-500'>
          <Logo/> 
        </svg>
      </div>
      <div>
      </div>
        <h2 className=' text-xl'>اهلاً بعودتك </h2>
      <TextField name = 'username' onChange = {handelInput} placeholder = 'اسم المستخد او البريد' type = "text" >
        <MdOutlineMail/>
      </TextField>
      <TextField name = 'password' onChange={handelInput} placeholder = 'كلمة المرور' type = "password" >
        <IoLockClosedOutline/>
      </TextField>
      <LineNotify err text={msg} />
      <Button type='submit' loading = {loading}>
        Log in
      </Button>
    </form>
  </div>

  )
}

