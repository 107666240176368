import { Empty } from "antd";




export default function EmptyData({des = 'لا توجد اي بيانات'}){
  return (
    <div className=" flex w-full justify-center items-center h-full " >
    <Empty
      imageStyle={{
        height: 60,
      }}
      description = {des}
    />
    </div>
  )
}