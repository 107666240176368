
import Container from '../dashboard/container'
import Button from '../dashboard/asset/button'
import TextField from '../dashboard/asset/textField'
import { useEffect, useState } from 'react'
import { LineNotify } from '../dashboard/asset/notify';
import SelectInputField from '../dashboard/asset/selectInputField';
import { useUpdateData } from '../hooks/events';
import { updateDataEvent } from '../events';
import { priceModel } from '../../model/price';
import { IoClose } from 'react-icons/io5';

/***
 * item
 * 
 * id
 * name
 * cost
 * count
 * wholesale
 * addDate
 * expireDate
 * 
 */


export default function AddNewPricing({id}) {
  const [checked , setChecked] = useState(false);
  const [area , setArea] = useState([]);
  const [sections , setSections] = useState([]);
  const [typeData , setTypeData] = useState([]);
  const [errLog , setErrLog] = useState({});
  const [loading , setLoading] = useState(false);
  const [currentObj , setCurrentObj] = useState({id : null});

  const [values , setValues] = useState({})

  /**
   * to do my updating code here
   */
  const updateCard = async () => {
    setLoading(true);
    await  priceModel.getById(id , setCurrentObj)
    setLoading(false);
  }

  useEffect(() => {
    if(id)
    {
      updateCard();
    }
  } , [id])

  const removeCurrentObj= () => {
    setCurrentObj({id : null});
  }

  /**
   * to do my updating code here
   */







  const updating = async () => {
    await priceModel.getAllArea(setArea);
    await priceModel.getAllSections(setSections);
    await priceModel.getAllType(setTypeData);
  }


  useEffect(() => {
    updating();
  } , [])
  
  useUpdateData(() =>{
    updating();
  })
  
  const handleInput = ( name , value ) => {
    // console.log(name , value , values , currentObj);
    let nameValue = value?.name || value
    let idValue = currentObj.id || 0 ;
    setValues(prev => ({...prev , [name] : nameValue }));
    if(idValue >= 0)
    {
      setValues(prev => ({...prev , 'id' : idValue}));
    }
    
    setChecked(false);
    setErrLog({});
  }
  const handleSubmit = async (e) => {
    e.preventDefault()
    let statusOfRequest;
    console.log(values);
    if(currentObj.id)
    {
      try
      {
        setLoading(true);
        const response = await priceModel.update(values);
        statusOfRequest = response.status
        console.log(response);
      }catch(err)
      {
        console.log(err);
      }  
      handleErr(statusOfRequest)
      console.log(statusOfRequest);
      updateDataEvent();
      setLoading(false);
    }
    else
    {
      if(handleErr(statusOfRequest))
        {
          try
          {
            setLoading(true);
            const response = await priceModel.create(values);
            statusOfRequest = response.status
            console.log(response);
          }catch(err)
          {
            console.log(err);
          }  
        }
        handleErr(statusOfRequest)
        console.log(statusOfRequest);
        updateDataEvent();
        setLoading(false);
    }
  }

  const handleErr = (status) =>{
    setChecked(true);
    let isErr = checkErr()
    if(status > 300)
    {
        setErrLog({
          text : " يوجد خطأ يرجي المحاولة لاحقاً ",
          status : 'err'
      })
      return true;  
    }
    else if(status === 200)
    {
        setErrLog({
          text : "تم تسجيل صنف جديد",
          status : 'success'
      })
      return false;
    }
    else
    if(isErr)
      setErrLog({
        text : "اكمل الحقول اولاً",
        status : 'err'
    })
    else
    if(values.cost * 1 < values.wholesale * 1)
      setErrLog({
        text : "سعر البيع اقل من سعر الجملة",
        status : 'err'
    })
    
    return isErr
  }
  const errLine = (target , lineText = null)=>{
    if(!lineText) lineText = 'ادخل الحقل';
    return (!target && checked) && lineText
  }

  const checkErr = () => {
    return !( 
      values.area && 
      values.section &&
      values.price &&
      values.type 
     )
  }

  return(
    <div>
      <Container
        onSubmit={handleSubmit}
        title={
          <div>
            {
              !currentObj.id ?
              <span>
                {
                  'اضافة تسعير'
                }
              </span>
              :
              <div className=' flex justify-between'>
                <div>
                  <span>
                    {
                      'تعديل تسعيرة بكود رقم' + ' # ' +
                      currentObj.id
                    }
                  </span>
                </div>
                <div className=' bg-red-400 h-fit rounded-sm p-1 text-white' onClick={removeCurrentObj}>
                  <IoClose/>
                </div>
              </div>
            }
          </div>
        }
        footer={

          <Button loading = {loading}>
            {
              currentObj.id ?
              'تأكيد التغيرات'
              :
            'اضافة سعر جديد'
            }
          </Button>
        }
      >

        <SelectInputField value = {currentObj.id && currentObj.area } disabled = {loading}  data = {area}  err = {errLine(values.area )} onChange = {handleInput} name = 'area'       type = 'text'   placeholder = 'المنطقة' />
        <SelectInputField value = {currentObj.id && currentObj.section } disabled = {loading}  showNotFound = {false} data = {sections}  err = {errLine(values.section )} onChange = {handleInput} name = 'section'       type = 'text'   placeholder = 'القسم' />
        <SelectInputField value = {currentObj.id && currentObj.type } disabled = {loading}  data = {typeData}   err = {errLine(values.type )} onChange = {handleInput} name = 'type'       type = 'text'   placeholder = 'الفئة' />
        <TextField        value = {currentObj.id && currentObj.price }  disabled = {loading}   err = {errLine(values.price )} onChange = {handleInput} name = 'price'      type = 'number' placeholder = 'السعر' />
        
        <LineNotify text={errLog.text} success={errLog.status == 'success'} err={errLog.status == 'err'}  />
      </Container>
    </div>
  )
}

