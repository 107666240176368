
import { createRef, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useRefDimensions } from "../../../hooks/customHook";



export
function Field({ children , ownKey , value , reference})
{
  return(
    <div className=" flex gap-3 items-center">
          <span className=" text-slate-400">
            {
              children || ownKey
            }
          </span>
          <span>
            :
          </span>
          {
            reference ?
            <Link to={reference}>
              <span>
                {
                  value
                }
              </span>
            </Link>
            : 
            <span>
              {
                value
              }
            </span>
          }
    </div>
  )
}


export
function ScrollCard({children , width}){
  // console.log(width);
  return(
    <div style={{minWidth : width}}  className={`"md:text-lg   m-2 p-4  w-full "`}>
      <div className=" grid grid-auto-fit-md gap-4 px-4">
        {
          children 
        }
      </div>
    </div>
  )
}



export function ScrollableCard({children , currentCard , width , setWidth = () => null})
{
    
  const conRef = createRef(null);
  const container = createRef(null);
  const [viewSector , setViewSector] = useState('0px');

  // document.querySelector('').childElementCount
  const dimensions = useRefDimensions(conRef);
  const wholeDim = useRefDimensions(container);
  useEffect(() => {
    let view = currentCard * wholeDim.width / container.current.children.length + 'px'
    setWidth(`${dimensions.width}px`)
    setViewSector(view)
    // console.log(viewSector);
    return
  },[dimensions]);
  return(
    <div className=" overflow-x-hidden ">
      <div className=" w-full bg-gray-100 border rounded-tl-2xl p-2  rounded-b-2xl  " ref={conRef}>
        <div
        ref={container}
        style={{
          transform : 'translateX('+viewSector+')'
        }}
        className={`flex w-fit ease-in-out duration-300  `}>      
        {
          children
        }
        </div>
      </div>
    </div>
  )
}