import {  useEffect, useRef, useState } from "react"
import { IoChevronUpSharp } from "react-icons/io5";
import { model } from "../../../../model";
import {dialogEvents} from '../../../events'
import { TableRowHeader , TableCell , TableStatusCell , TableMultiCell } from "./tableAsset";
import { clientModel } from "../../../../model/client";
import ClipLoader from "react-spinners/ClipLoader";
import { Empty } from "antd";



export default function StaysTable({stander , data , setAgent , loading = false})
{
  const [sort,setSort] = useState('name');
  const [goBack,setGoBack] = useState('go');
  
  
  const toSort = (a , b) =>
  {
    if(goBack == 'go')
    {
      // console.log(sort)
      if(sort == "total"){
        
        return (model.getTotal(a) > model.getTotal(b) ? 1 : -1)
      }
      else
      return (a[sort] > b[sort] ? 1 : -1)
    }
    else
    {
      if(sort == "total"){
        
        return (model.getTotal(a) < model.getTotal(b) ? 1 : -1)
      }
      else
      // console.log(goBack)
      return (a[sort] < b[sort] ? 1 : -1)  
    }
  }
  console.log(data);

  return (
    <div>
    {
      data.length > 0 ?
      <table>
        <TableRowHeader goBack={goBack} sort={sort} goBackMethod={setGoBack} sortMethod={setSort} element={stander}/>
        
        {
        
          data
          .sort((a,b) => toSort(a,b) )
          .map((user) => (
            <StayTableRow setAgent={setAgent} element={user}/>
          ))
        }
      </table> : !loading ?
        <div className=" text-nowrap ">
          <Empty  title = 'لا توجد بيانات' />
        </div>   : ''  
    }
    {
        loading && 
          <div className=" py-4  " >
            <ClipLoader/>
          </div>
      }

  </div>
  )
}



function StayTableRow({element })
{
  
  // console.log(element);
  const meRef = useRef(null);
  // useShowDialogEvent(meRef, e => console.log(e.detail));
  const handleInput = (e) => {
    dialogEvents.employData(element.id);
  }
  const [mainClientData , setMainClientData] = useState([]);
  const [secondClientData , setSecondClientData] = useState([]);
  useEffect(() => {
    clientModel.getById(element.mainClientId , setMainClientData);
    clientModel.getById(element.secondClientId , setSecondClientData);
  }, [])
  
  return(
    <tr 
    ref={meRef}
    onClick={handleInput}
    className="even:bg-gray-200 first-of-type:sticky top-0
    z-20
    border-slate-300
    hover:first-of-type:bg-slate-100
    first-of-type:bg-slate-100
    first-of-type:shadow-lg
    first-of-type:border-none
    first-of-type:hover:cursor-auto  border-b last-of-type:border-none  hover:cursor-pointer hover:bg-sky-200">
      
      < TableCell text =  {element.patientName } />
      < TableCell text =  {mainClientData.name } />
      < TableCell text =  {secondClientData.name } />
      < TableCell text =  {model.getStatus(element) } />
      < TableCell text =  {element.salary } />
      < TableCell text =  {element.paid } />
      < TableCell text =  {element.salary - element.paid } />
      
      < TableCell text =  {
        <div className={`  p-2 flex items-center justify-center rounded-lg
          ${ model.getTotal(element) > 0 ? ' bg-red-400 text-white ' : ' bg-green-200 ' }
        `}>
          {
            model.getTotal(element) 
          }
        </div>
        } />
      < TableCell text =  {model.getTotal(element) } />
      < TableCell text =  {model.getWorkInPeriod(element)} />
      < TableCell text =  {model.getFreeInPeriod(element)} />
      < TableCell text =  {element.additional} />
      < TableCell text =  {model.dateToText(element.startDate) } />
      < TableCell text =  {model.dateToText(model.getEndDate(element)) } />

      
    </tr> 
  ) 

}


