


import { useEffect, useState } from 'react';
import { MdNotificationsActive } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { model } from '../../../../model';
import { AccountBtn } from '../assets';
import { userModel } from '../../../../model/user';
import { getNotificationBody } from './notifyBody';
import { Empty } from 'antd';
import { io } from 'socket.io-client'
import { updateDataEvent } from '../../../events';
import { FaMarker } from 'react-icons/fa6';
import { BiCheckDouble } from 'react-icons/bi';
import LoadingScreen from '../../../dashboard/asset/loading';
import { useDelay } from '../../../hooks/customHook';








export
const sendNotification = (notify = null) => {
    const otherText =  'لديك اشعارات جديدة'
    if (Notification.permission === "granted") {
      var notification = new Notification( otherText, {silent : false , dir : 'ltr'  , data : 'adfaf' , requireInteraction : true});
    } else if (Notification.permission !== "denied") {
      // Ask for permission
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
          var notification = new Notification( otherText, {silent : false , dir : 'ltr'  , data : 'adfaf' , requireInteraction : true});
        }
      });
    }
}



const loadNotification = async ( setLoading = null , setNotify = () => null , setAuth = () => null , notSeen = false ) => {
  setLoading(true);
  await userModel.checkAuth(setAuth);
  const notify =  await userModel.getNotifications(notSeen);
  setNotify(notify);
  setLoading(false);
}


export
function NotificationDialog({setShowDialog , setNotifyNo = () => null})
{
  const [loading , setLoading] = useState(false);
  const [notifyLogs , setNotifyLogs] = useState([]);
  const [auth ,setAuth] = useState({})
  const [lastNotifyLogs ,setLastNotifyLogs] = useState(null)
  const navigator = useNavigate();

  

  useEffect(() => {
    loadNotification(setLoading , setNotifyLogs , setAuth , true);
  } , [])
  
  //// todo remove
  useDelay(() => {
    loadNotification(setLoading , setNotifyLogs , setAuth , true);
  },10)


  const socket = io(model.backEndServer , {transports : ["websocket"] ,   withCredentials: true,
    extraHeaders: {
      "my-custom-header": "abcd"
    } });
  socket.on("new_notification",async (data) => {
    if(data.userId == auth.userId)
      await loadNotification(setLoading , setNotifyLogs , setAuth , true);
    console.log(data);
  });
  const goToPage = async (dir) =>{
    navigator(dir)
    setShowDialog(false)
    await loadNotification(setLoading , setNotifyLogs , setAuth , true);
  }

  useEffect(() => {
    setNotifyNo(notifyLogs?.length);
    if(notifyLogs.length > 0 )
    {
      if(lastNotifyLogs !== null )
      {
        if(notifyLogs.length > lastNotifyLogs + 1)
          sendNotification({});
        else
          sendNotification(notifyLogs[0]);
      }
      setLastNotifyLogs(notifyLogs.length)
    }
  },[notifyLogs])

  return(
    <div className=' max-h-96 w-96 pl-1 pb-2 overflow-y-scroll scroll-style '>
      <AccountBtn title={'جميع الأشعارات'} icon={<MdNotificationsActive/>} onClick={() => goToPage('/inbox')} />
      <div className='border'> </div>

      {
        notifyLogs?.length > 0 ? 
        notifyLogs.sort((a , b) => new Date( b.date )  -  new Date( a.date)).map(e => (
          <LogBtn userId = {auth.userId} notifyLog={e}  onClick ={goToPage} />
        )) : 
        loading ? <LoadingScreen/> :
        <div className=' p-4 ' >
          <Empty className=' text-xs' />
        </div>
      }
    </div>
  )
}


export
function InBoxDialog({})
{
  const [loading , setLoading] = useState(false);
  const [notifyLogs , setNotifyLogs] = useState([]);
  const [auth ,setAuth] = useState({})
  const navigator = useNavigate();

  

  useEffect(() => {
    loadNotification(setLoading , setNotifyLogs , setAuth );
  } , [])

  
  //// todo remove
  useDelay(() => {
    loadNotification(setLoading , setNotifyLogs , setAuth );
  },10)

  const socket = io(process.env.REACT_APP_BACKEND_SERVER_SOCKET , {transports : ["websocket"] ,   withCredentials: true,
    extraHeaders: {
      "my-custom-header": "abcd"
    } });
  socket.on("new_notification",async (data) => {
    if(data.userId == auth.userId)
      await loadNotification( setLoading , setNotifyLogs , setAuth );
    
  });

  const goToPage = async (dir) =>{
    navigator(dir)
    await loadNotification(setLoading , setNotifyLogs , setAuth );
  }

  return(
    <div className=' bg-white h-full scroll-style '>
      <div className='flex flex-col gap-2'> </div>
      {
        notifyLogs.length > 0 ? 
        notifyLogs.sort((a , b) => new Date( b.date )  -  new Date( a.date)).map(log => (
          <LogBtn userId = {auth.userId} notifyLog={log} onClick ={goToPage} />
        )) : loading ?
        <LoadingScreen/> 
        :
        <Empty title = 'gg' />
      }
    </div>
  )
}




export
function LogBtn({ userId , notifyLog = {} , onClick })
{
  const notification = getNotificationBody(notifyLog , userId);
  const handleClick = async () =>
    {
      userModel.makeSeenNotify(notifyLog.id);
      onClick('/' + notifyLog.section + '/' + notifyLog.model_id)
      updateDataEvent();
    }
    
  return(
    <div onClick={handleClick} dir='rtl' 
    className={`'
    ${ !notifyLog.seen ? 'bg-sky-200' : ' '}
    border-b 
    flex items-center gap-2 text-right p-2 px-10 hover:bg-gray-100 duration-200 ease-linear cursor-pointer '`}>
      <div className=' flex gap-4 text-sm w-full items-center '>
        <div className=' relative text-2xl border rounded-full p-1'>
          <span>
            {
              notification.icon
            }
          </span>
          <div className=' absolute  -right-3 -bottom-3' >
            {
              notification.actionIcon
            }
          </div>
        </div>
        <div className='flex flex-col gap-2 w-full '>
          <div className=' text-lg w-full '>
            {
              notification.title
            }
          </div>
          <div>
            {
              notification.topic
            }
          </div>
          <div className=' text-gray-400 flex w-full justify-around'>
            <div className=' flex gap-1 items-center' >
              {
                notifyLog.seen &&
                <>
                  <BiCheckDouble className=' text-xl' />
                  {
                    model.timeToType(notifyLog.seen_date)
                  }
                </>
              }
            </div>
            <div className=' flex gap-2 items-center' >
              <FaMarker/>
              {
                model.timeToType(notifyLog.date)
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}




