import axios from "axios";
import { model } from "../../../model";



export
const applicantModel = {
  checkPhoneCardId : async (phone , cardId)=>
  {
    let respond = {};
    await axios.post(model.backEndServer + '/applicant/check_phone_cardId' , {phone , cardId})
    .then(res =>{ 
      respond = res;
      //console.log(res) 
    })
    .catch(err =>{ 
      respond = err.response;
      })
    return respond;
  },
  uploadData : async (values , images)=>
  {
    let respond = {};
    await axios.post(model.backEndServer + '/applicant/upload_data' ,
       {values , images} )
    .then(res =>{ 
      respond = res;
      //console.log(res) 
    })
    .catch(err =>{ 
      respond = err.response;
      })
    return respond;
  },
  uploadImages : async (images)=>
  {
    let respond = {};
    await axios.post(model.backEndServer + '/applicant/upload_images' , images )
    .then(res =>{ 
      respond = res;
      //console.log(res) 
    })
    .catch(err =>{ 
      respond = err.response;
      })
    return respond;
  }
}



