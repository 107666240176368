import axios from 'axios'
import Cookies from 'js-cookie'
import { model } from '..';


export
const searchModel = {
  getAll : async ( searchText , setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.post( model.backEndServer +  '/search/get_all' ,{search : searchText} ,  {headers : {'access-token' : token, }})
      .then(res => {
        console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },

}
 