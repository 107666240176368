import { IoChevronUpSharp } from "react-icons/io5";
import { model } from "../../../../model";
import { perm, permIntoArabic } from "../../../permissions";

export
function TableHeaderCell({sortMethod, text ,goBackMethod,sort,goBack, sorId ,  sticky})
{
  
  return(
    <td 
    onClick={() => {
      sortMethod(sorId);
      (sort == sorId && goBack == "go") ? goBackMethod('back') : goBackMethod('go');
    }}
    className={`py-2 whitespace-nowrap cursor-pointer 
    hover:bg-sky-200 ease-in-out duration-300 px-4 
    first-of-type:bg-slate-100 
    first-of-type:w-10
    ${sticky ? "sticky right-10 bg-slate-100 shadow-md " : ""}
    right-0 first-of-type:sticky z-10`}>
      <div className="flex">
        <div>
        {text}
        </div>
        <div className="text-sky-700 justify-center flex-col font-bold text flex"  >
          {
            (sort == sorId) ? <IoChevronUpSharp className={` ${ goBack == "go" ? "rotate-180" : ""}  ease-in-out duration-300`} /> : ""
          }
        </div>
      </div>
    </td>
  )
}



export
function TableRowHeader({sortMethod ,goBackMethod,sort,goBack, element , stickyName = true})
{
  return(
    <tr 
    className="even:bg-gray-200 first-of-type:sticky top-0
    z-20
    border-slate-400
    hover:first-of-type:bg-slate-100
    first-of-type:bg-slate-100
    first-of-type:shadow-lg
    first-of-type:border-none
    first-of-type:hover:cursor-auto  border-b last-of-type:border-none  hover:cursor-pointer hover:bg-sky-200">
      
      {
        Object.keys(element).map(e => (
          < TableHeaderCell  goBack = {goBack} sort = {sort} sticky={(e == 'name' && stickyName)} goBackMethod = {goBackMethod} sortMethod={sortMethod} sorId = {e}  text = {element[e]}/>
        ))
      }
    </tr> )

}
export
function JobTableRowHeader({sortMethod ,goBackMethod,sort,goBack, element , stickyName = true})
{
  return(
    <tr 
    className="even:bg-gray-200 first-of-type:sticky top-0
    z-20
    border-slate-400
    hover:first-of-type:bg-slate-100
    first-of-type:bg-slate-100
    first-of-type:shadow-lg
    first-of-type:border-none
    first-of-type:hover:cursor-auto  border-b last-of-type:border-none  hover:cursor-pointer hover:bg-sky-200">
      
      {
        Object.keys(element).map(e => (
          < TableHeaderCell  goBack = {goBack} sort = {sort} sticky={(e == 'name' && stickyName)} goBackMethod = {goBackMethod} sortMethod={sortMethod} sorId = {e}  text = {permIntoArabic[element[e]] || element[e]}/>
        ))
      }
    </tr> )

}


export
function TableCell({text , sticky})
{
  return(
    <td className={`py-2 whitespace-nowrap  px-4 first-of-type:bg-slate-100 
    first-of-type:w-10
    ${sticky ? "sticky right-10 bg-slate-100 shadow-md " : ""}
    right-0 first-of-type:sticky z-10`}>
      {text}
    </td>
  )
}

export
function TableStatusCell({element})
{
  
  return(
    <td className={`py-2 whitespace-nowrap  px-4 first-of-type:bg-slate-100 
    first-of-type:w-10
    
    right-0 first-of-type:sticky z-10`}>
      <div className={` align-middle text-center rounded-md
      ${element.status == "working" ? "bg-green-50 text-green-800 border border-green-800" : ""}
      ${element.status == "stop" ? "bg-red-50 text-red-800 border border-red-800" : ""}
      ${element.status == "available" ? "bg-sky-50 text-sky-800 border border-sky-800" : ""}
      ${element.status == "waiting" ? "bg-yellow-50 text-yellow-800 border border-yellow-800" : ""}
      `}>
        {model.getStatus(element)}
      </div>
    </td>
  )
}


export
function TableMultiCell({text , sticky})
{
  return(
    <td className={`py-2 whitespace-nowrap  px-4 first-of-type:bg-slate-100 
    first-of-type:w-10
    ${sticky ? "sticky right-10 bg-slate-100 shadow-md " : ""}
    right-0 first-of-type:sticky z-10`}>
      {
      text.map( txt => ( <span>
        {txt + " | "} 
      </span>))
      }
    </td>
  )
}
