import Button from "../../asset/button";
import Container from "../../container";
import SelectBox from "../../asset/selectBox";
import TextField from "../../asset/textField";
import FindField from "../../asset/findField";
import { employeeModel } from "../../../../model/employee";
import { useEffect, useState } from "react";
import { clientModel } from "../../../../model/client";
import { CiCircleCheck, CiEdit, CiWarning } from "react-icons/ci";
import { BiEdit, BiErrorCircle } from "react-icons/bi";
import { LineNotify } from "../../asset/notify";
import { gender, periods } from "../../asset/assets";
import { stayModel } from "../../../../model/stay";
import TextArea from "../../asset/textArea";
import { useGetAllJobs, useGetPermissions } from "../../../hooks/customHook";
import { model } from "../../../../model";
import { visitModel } from "../../../../model/visit";
import { userModel } from "../../../../model/user";
import { NotAvailable } from "../../asset/notAvailable";
import { updateDataEvent } from "../../../events";




export
function SpliterDiv({children})
{
  return(
    <div className=" w-full grid grid-auto-fit-xs md:flex items-center gap-2 ">
      {
        children
      }
    </div>
  )
}



export  function AddNewEmployee()
{
  const [emptyFiled , setEmptyField] = useState(false);
  const jobs = useGetAllJobs();
  const permissions = useGetPermissions();
  const [loading , setLoading] = useState(false);
  const [values , setValues] = useState({
    address : "",
    birthDate : "",
    cardId : "",
    gender : gender.male,
    name : "",
    nationality : "",
    phone : "",
    phone2 : "",
    workDate : ""
  })

  const log = {
    err : false,
    success : false,
    alert : false,
    msg : '',
    phoneErr : '',
    nameErr : '',
    cardIdErr : '',
    addressErr : '',
    birthDateErr : '',
  }
  const [customLog , setCustomLog] = useState(log);
  const [res , setRes] = useState(null);
  useEffect(() => {
    const isErr = (fieldName) =>
    {
      try{
        console.log(res[fieldName])
        return (res[fieldName] == true || res['key'] == fieldName)
      }catch
      {
        return false
      }
    }

    setCustomLog(prev => ({...prev , 'nameErr'        : isErr('name') }) );
    setCustomLog(prev => ({...prev , 'cardIdErr'      : isErr('cardId') }) );
    setCustomLog(prev => ({...prev , 'phoneErr'       : isErr('phone') }) );
    setCustomLog(prev => ({...prev , 'addressErr'     : isErr('address') }) );
    setCustomLog(prev => ({...prev , 'birthDateErr'   : isErr('birthDate') }) );
  }, [res])
  

  
  
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true);
    const res = await employeeModel.create(values);
    console.log(res.status);
    setCustomLog(prev => ({...prev , msg   : res.msg}) );
    
    if(res.msg)
    {
      setCustomLog(prev => ({...prev , err   : true}) );
    }
    if(res.status == 400)
    {
      setCustomLog(prev => ({...prev , alert   : true}) );
    }
    if(res.status == 200)
    {
      setCustomLog(prev => ({...prev , msg   : 'تم تسجيل موظف جديد بنجاح'}) );
      setCustomLog(prev => ({...prev , success   : true}) );
    }
    setRes(res);
    setLoading(false)
    updateDataEvent()
  }
  const handleInput = (name , value) => {
    setCustomLog({})
    setValues(prev => ({...prev , [name] : value }));
  }


  return( (permissions && permissions.addEmployee) &&
      <>
      <Container onSubmit={handleSubmit} title={'اضافة موظف جديد '}  footer={<>
        <Button type='submit' loading = {loading}>
          تأكيد
        </Button>
      </>}>
        <TextField empty = {emptyFiled} err={customLog.nameErr ? customLog.msg : false} type = 'text' title = {'الأسم'}  name = 'name' onChange = {handleInput}/>
        <TextField empty = {emptyFiled} err ={customLog.cardIdErr ? customLog.msg : false} type = 'number' title = {'البطاقة'}  name = 'cardId' onChange = {handleInput}/>
        <TextField empty = {emptyFiled} err ={customLog.phoneErr ? customLog.msg : false} type = 'tel' title = {'رقم الهاتف'} name = 'phone' onChange = {handleInput}/>
        <TextField empty = {emptyFiled} type = 'tel' title = {'رقم هاتف اخر'} name = 'phone2' onChange = {handleInput}/>
        <TextField empty = {emptyFiled} err={customLog.addressErr ? customLog.msg : false} type = 'text' title = {'العنوان'} name = 'address' onChange = {handleInput}/>
        <TextField empty = {emptyFiled} err={customLog.birthDateErr ? customLog.msg : false} type = 'date' title = {'تاريخ الميلاد'} name = 'birthDate' onChange = {handleInput}/>
        <TextField empty = {emptyFiled} type = 'date' title = {'تاريخ التعيين'} name = 'workDate' onChange = {handleInput}/>
        <TextField empty = {emptyFiled} type = 'text' title = {'الجنسية'} name = 'nationality' onChange = {handleInput}/>
        <SelectBox value = 'employee' options = {jobs} title = {'الوظيفة'} name = 'job' onChange = {handleInput}/>
        <SelectBox options = {gender}  title = {'النوع'} name = 'gender' onChange = {handleInput}/>
        <LineNotify err ={customLog.err} success={customLog.success} alert={customLog.alert} text = {customLog.msg}/>
      </Container>
      </>
  ) || <NotAvailable/>
}


export  function AddNewClient()
{
  const [emptyFiled , setEmptyField] = useState(false);
  const permissions = useGetPermissions();
  const [loading , setLoading] = useState(false);
  const [values , setValues] = useState({
    area : "",
    block : "",
    city : "",
    famousPlace : "",
    flat : "",
    location : "",
    name : "",
    phone : "",
    phone2 : "",
    street : ""
  })

  
  const log = {
    err : false,
    success : false,
    alert : false,
    msg : '',
    phoneErr : '',
    nameErr : '',
    cardIdErr : '',
    addressErr : '',
    birthDateErr : '',
  }
  const [customLog , setCustomLog] = useState(log);
  
  const [res , setRes] = useState(null);
  useEffect(() => {
    const isErr = (fieldName) =>
    {
      try{
        console.log(res[fieldName])
        return (res[fieldName] == true || res['key'] == fieldName)
      }catch
      {
        return false
      }
    }

    setCustomLog(prev => ({...prev , 'nameErr'        : isErr('name') }) );
    setCustomLog(prev => ({...prev , 'phoneErr'       : isErr('phone') }) );
    setCustomLog(prev => ({...prev , 'flat'           : isErr('flat') }) );
    setCustomLog(prev => ({...prev , 'block'          : isErr('block') }) );
    setCustomLog(prev => ({...prev , 'street'          : isErr('street') }) );
    setCustomLog(prev => ({...prev , 'area'           : isErr('area') }) );
    setCustomLog(prev => ({...prev , 'city'           : isErr('city') }) );
  }, [res])
  

  
  
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const res = await clientModel.create(values);
    console.log(res.status);
    setCustomLog(prev => ({...prev , msg   : res.msg}) );
    
    if(res.msg)
    {
      setCustomLog(prev => ({...prev , err   : true}) );
    }
    if(res.status == 400)
    {
      setCustomLog(prev => ({...prev , alert   : true}) );
    }
    if(res.status === 200)
    {
      setCustomLog(prev => ({...prev , msg   : 'تم تسجيل عميل جديد بنجاح'}) );
      setCustomLog(prev => ({...prev , success   : true}) );
    }
    setRes(res);
    setLoading(false)
    updateDataEvent()
  }
  const handleInput = (name , value) => {
    setCustomLog({})
    setValues(prev => ({...prev , [name] : value }));
  }

  return( (permissions && permissions.addClient) &&
      <>
      <Container onSubmit={handleSubmit} title={'اضافة عميل جديد '}  footer={<>
        <Button type='submit' loading = {loading} >
          تأكيد
        </Button>
      </>}>
        <TextField empty = {emptyFiled} err={customLog.nameErr ? customLog.msg : false} type = 'text' title = {'الأسم'}  name = 'name' onChange = {handleInput}/>
        <TextField empty = {emptyFiled} err={customLog.nameErr ? customLog.msg : false} type = 'tel' title = {'رقم الهاتف'} name = 'phone' onChange = {handleInput}/>
        <TextField empty = {emptyFiled} type = 'tel' title = {'رقم هاتف اخر'} name = 'phone2' onChange = {handleInput}/>
        <div className="flex gap-4" >
          <TextField empty = {emptyFiled} err={customLog.flat ? customLog.msg : false } type = 'text' title = {'الشقة'} name = 'flat' onChange = {handleInput}/>
          <TextField empty = {emptyFiled} err={customLog.block ? customLog.msg : false } type = 'text' title = {'عمارة'} name = 'block' onChange = {handleInput}/>
        </div>
        <div className="flex gap-4" >
          <TextField empty = {emptyFiled} err={customLog.street ? customLog.msg : false } type = 'text' title = {'شارع'} name = 'street' onChange = {handleInput}/>
          <TextField empty = {emptyFiled} err={customLog.area ? customLog.msg : false } type = 'text' title = {'منطقة'} name = 'area' onChange = {handleInput}/>
        </div>
        
        <div className="flex gap-4" >
          <TextField empty = {emptyFiled} err={customLog.city ? customLog.msg : false } type = 'text' title = {'محافظة'} name = 'city' onChange = {handleInput}/>
          <TextField empty = {emptyFiled} type = 'text' title = {'مكان مشهور'} name = 'famousPlace' onChange = {handleInput}/>
        </div>
        <TextField empty = {emptyFiled} type = 'text' title = {'الموقع'} name = 'location' onChange = {handleInput}/>
        <LineNotify err ={customLog.err} success={customLog.success} alert={customLog.alert} text = {customLog.msg}/>
      </Container>
      </>
  ) || <NotAvailable/>
}

export  function AddNewUser()
{
  const [dataField , setDataField] = useState([])
  const [emptyFiled , setEmptyField] = useState(false);
  const permissions = useGetPermissions();
  const [loading , setLoading] = useState(false);

  useEffect(() => {
    employeeModel.getAll(setDataField);
  },[])
  const jobs = useGetAllJobs();
  const log = {
    alert : false,
    success : false,
    err : false,
  }
  const [customLog , setCustomLog] = useState(log);

  const [values , setValues] = useState({
    
    password : '',
    userId : '',
    username : '',
    job : 11
  })

  
  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const res = await userModel.create(values)
    console.log(res);

    if(res.key)
    {
      setCustomLog({[res.key] : true , msg : res.msg})
    }
    else
    {
      console.log(values);
      setCustomLog({success : true , msg : 'تم اضافة مستخدم جديد بنجاح'})
      setEmptyField(true);
      updateDataEvent();
    }
    setLoading(false)
    updateDataEvent();
  }
  const handleInput = (name , value) => {
    setCustomLog({})
    setValues(prev => ({...prev , [name] : value }));
  }
  

  return( (permissions && permissions.addUser) &&
      <>
      <Container onSubmit={handleSubmit} title={'اضافة مستخدم جديد '}  footer={<>
        <Button type='submit' loading = {loading}>
          تأكيد
        </Button>
      </>}>
        <FindField data = {dataField} err={customLog.userId ? customLog.msg : false} type = 'text' title = {'الموظف'}  name = 'userId' onChange = {handleInput}/>
        <TextField empty = {emptyFiled} err={customLog.username ? customLog.msg : false} type = 'text' title = {'البريد'}  name = 'username' onChange = {handleInput}/>
        <TextField empty = {emptyFiled} err={customLog.password ? customLog.msg : false} type = 'tel' title = {'كلمة السر'} name = 'password' onChange = {handleInput}/>
        <SelectBox value = '11' options = {jobs} title = {'الوظيفة'} name = 'job' onChange = {handleInput}/>
        <LineNotify err ={customLog.err} success={customLog.success} alert={customLog.alert} text = {customLog.msg}/>
      </Container>
      </>
  ) 
}

export  function AddNewEmployeeStay()
{
  
  const [fieldData , setFieldData] = useState([]);
  const [stayData , setStayData] = useState([]);
  const [emptyFiled , setEmptyField] = useState(false);
  const permissions = useGetPermissions();
  const [loading , setLoading] = useState(false);

  const [values , setValues] = useState({
    employeeId       : '',
    stayId           : '',
    freeDays         : '',
    freePeriod       : '',
    salary           : '',
    workDate         : '',
    workDays         : '',
    freePeriodMain   : 'month',
    freePeriodType   : 'day',
    wholeTime        : 'month',
  })

  
  const log = {
    err : false,
    success : false,
    alert : false,
    msg : '',
    phoneErr : '',
    nameErr : '',
    cardIdErr : '',
    addressErr : '',
    birthDateErr : '',
  }
  const [customLog , setCustomLog] = useState(log);
  const [res , setRes] = useState(null);
  useEffect(() => {
    const isErr = (fieldName) =>
    {
      try{
        console.log(res[fieldName])
        const valid = (res[fieldName] == true || res['key'] == fieldName)
        if(valid)
        {
          setCustomLog(prev => ({...prev , alert   : false}) );
          setCustomLog(prev => ({...prev , success   : false}) );
        }
        return valid 
      }catch
      {
        return false
      }
    }

    setCustomLog(prev => ({...prev , 'employeeId'      : isErr('employeeId') }) );
    setCustomLog(prev => ({...prev , 'stayId'          : isErr('stayId') }) );
    setCustomLog(prev => ({...prev , 'startDate'       : isErr('startDate') }) );
    setCustomLog(prev => ({...prev , 'salary'          : isErr('salary') }) );
    setCustomLog(prev => ({...prev , 'freePeriod'      : isErr('freePeriod') }) );
    setCustomLog(prev => ({...prev , 'workHours'       : isErr('workHours') }) );
  }, [res])
  

  
  const handleSubmit = async (e) => {
    e.preventDefault()
    // console.log(values)
    setLoading(true);
    const res = await employeeModel.createStay(values);
    console.log(res.status);
    setCustomLog(prev => ({...prev , msg   : res.msg}) );
    
    if(res.msg)
    {
      setCustomLog(prev => ({...prev , err   : true}) );
    }
    if(res.status == 400)
    {
      setCustomLog(prev => ({...prev , alert   : true}) );
      setCustomLog(prev => ({...prev , success   : false}) );
      setCustomLog(prev => ({...prev , err   : false}) );
    }
    else if(res.status === 200)
    {
      setCustomLog(prev => ({...prev , msg   : 'تم تسجيل عميل جديد بنجاح'}) );
      setCustomLog(prev => ({...prev , success   : true}) );
      setCustomLog(prev => ({...prev , alert   : false}) );
      setCustomLog(prev => ({...prev , err   : false}) );
    }
    else
    {

    }
  
    setRes(res);
    setLoading(false);
    updateDataEvent()
  }
  const handleInput = (name , value) => {
    setCustomLog({});
    setValues(prev => ({...prev , [name] : value }));
  }
  
  useEffect(() => {
    employeeModel.getAll(setFieldData);
    stayModel.getAll(setStayData);
  }, [])
  console.log ( 'custom log is : ' , customLog);
  return( (permissions && permissions.addEmployeeStay) &&
      <>
      <Container onSubmit={handleSubmit} title={' اضافة اقامة موظف'}  
        footer={<>
          <Button type='submit' loading = {loading} >
            تأكيد
          </Button>
        
        </>}>
        <FindField data = {fieldData} type = 'text' title = {'الموظف'}  name = 'employeeId' onChange = {handleInput}/>
        <FindField data = {stayData} type = 'text' title = {'الأقامة'}  name = 'stayId' onChange = {handleInput}/>
        <TextField empty = {emptyFiled} type = 'date' title = {'تاريخ البداية'} name = 'workDate' onChange = {handleInput}/>
        <SpliterDiv>
          <TextField empty = {emptyFiled} type = 'number' placeholder = 'الراتب' name = 'salary' onChange = {handleInput}/>
            <span>في</span>
          <SelectBox options = {periods} value = 'month' name = 'wholeTime' onChange = {handleInput}/>
        </SpliterDiv>
            {
              values.wholeTime == 'day' &&
              <TextField empty = {emptyFiled} type = 'number' title = {'ايام العمل'}  name = 'workDays' onChange = {handleInput}/>
            }
        <SpliterDiv>
          <TextField empty = {emptyFiled} type = 'number' title = {'الأجازة'}  name = 'freePeriod' onChange = {handleInput}/>
          <SelectBox options = {periods} name = 'freePeriodType' onChange = {handleInput}/>
          <span>كل</span>
          <SelectBox options = {periods} value = 'month' name = 'freePeriodMain' onChange = {handleInput}/>
        </SpliterDiv>
        <TextField empty = {emptyFiled} type = 'number' title = {'ساعات العمل'}  name = 'workHours' onChange = {handleInput}/>
        <LineNotify err ={customLog.err} success={customLog.success} alert={customLog.alert} text = {customLog.msg}/>
        
      </Container>


      </>
  )|| <NotAvailable/>
}


export  function AddNewVisit()
{
  
  const log = {
    err : false,
    success : false,
    alert : false,
    flat :'',
    block : '',
    street : '',
    area : '',
    city : '',

  }

  const [values , setValues] = useState({
    'discount': 0
  });
  const [clientsData , setClientsData] = useState([]);
  const [loading , setLoading] = useState(false);
  const [showAddressFields , setShowAddressField] = useState(false);
  const [client , setClient] = useState(null);
  const [daysCount , setDaysCount ] = useState(0);
  const [scale , setScale ] = useState('day');
  const [visitNumbers , setVisitsNumbers ] = useState(1);
  
  const [timesArg , setTimesArg ] = useState([]);
  const [dateArg , setDateArg ] = useState([]);
  const [visits , setVisits ] = useState([]);


  const [customLog , setCustomLog] = useState(log);
  const [emptyFiled , setEmptyField] = useState(log);

  const permissions = useGetPermissions();

  useEffect(() => {
    clientModel.getAll(setClientsData);
  },[])
  useEffect(() => {
    console.log(timesArg)
  },[timesArg])
  useEffect(() => {
    values.clientId &&
    clientModel.getById( values.clientId , setClient);
  },[values.clientId])
  
  useEffect(() => {
    let useUserAddress = !showAddressFields;
    if(!useUserAddress)
      setValues(prev => ({...prev , 
            'flat' :'',
            'block' :'',
            'street' :'',
            'area' :'',
            'city' :'',
            'location' :'',
            
          }))
  },[showAddressFields])


  

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let respond = {};
    Object.keys(visits).map( async e => {
      const res = await visitModel.create(visits[e]);
      respond = res;
      console.log(res);
    })
    if(respond.key)
      {
        setCustomLog({[respond.key] : true , msg : respond.msg , err : true})
      }
      else
      {

        setCustomLog(prev => ({...prev , 'success' : 
          (respond.status == 200)
        }))
        setEmptyField(true);
      }
      setLoading(false)
      updateDataEvent();
  }

  const handleVisits = (e) => {
    // console.log(visits.length);
    setLoading(true);
    if(!values.clientId) return console.log(' Invalid Client ')
    if(Object.keys(dateArg).length > 0 && Object.keys(timesArg).length > 0)
    {
      setVisits({});
      Object.keys(dateArg).forEach((i , n)=> {
        // console.log('from date arg i , n  ' ,  dateArg[i])
        Object.keys(timesArg).map(e => {
          setVisits(prev => ({...prev , [(n + e)] : {
             'time'     : timesArg[e].value ,
             'clientId' : values.clientId ,
             'date'     : dateArg[i] ,
             'flat'     : values.flat ,
             'block'    : values.block ,
             'street'   : values.street ,
             'area'     : values.area ,
             'city'     : values.city ,
             'location' : values.location ,
             'cost'     : values.cost ,
             'comment'  : values.comment ,
             'status'   : 'waiting',
             'discount' : values.discount
            }}))
        })
      });
    }else if(Object.keys(timesArg).length == 0)
    {
      setCustomLog({date : true , msg : 'ادخل التاريخ و الوقت بشكل صحيح' })
    }
    setLoading(false);
    updateDataEvent()
  }


  const handleInput = (name , value) => {
    setValues(prev => ({...prev , [name] : value}));
    setCustomLog({})
  }

  
  const
  handleScale = (name , e) =>{
    setScale(e);
    setCustomLog({})
  }

  useEffect(() =>{
    
    var firstDate = new Date();
    if(values.date) firstDate = new Date(values.date);
    console.log(firstDate)
    let increase = 0 ;
    setDateArg({})
      if(scale == 'day')
      {
        /// ratio = 1
        for(let i = 0 ; i < daysCount ; i++)
          {
            var newDate = new Date(firstDate);
            newDate.setDate(newDate.getDate() + i)
            let date = (newDate.toLocaleDateString());
            setDateArg(prev => ({...prev , [i] : date }))
            
          }
      }
      else
      if(scale == 'dayAndDay')
      {
        
        for(let i = 0 ; i < daysCount ; i+= 1)
          {
            var newDate = new Date(firstDate);
            newDate.setDate(newDate.getDate() + increase)
            let date = (newDate.toLocaleDateString());
            setDateArg(prev => ({...prev , [i] : date }))
            increase += 2;
          }    
           
      }
      else
      if(scale == 'week')
      {
        /// ratio = 7
        for(let i = 0 ; i < daysCount ; i+= 1)
          {
            var newDate = new Date(firstDate);
            newDate.setDate(newDate.getDate() + increase)
            let date = (newDate.toLocaleDateString());
            setDateArg(prev => ({...prev , [i] : date }))
            increase += 7;
          } 
      }
      else
      if(scale == 'month')
      {
        
        for(let i = 0 ; i < daysCount ; i+= 1)
          {
            var newDate = new Date(firstDate);
            newDate.setMonth(newDate.getMonth() + increase)
            let date = (newDate.toLocaleDateString());
            setDateArg(prev => ({...prev , [i] : date }))
            increase += 1;
          } 
      }
  } ,[daysCount , scale , values] )


  const
  handleTimes = ( name , e ) => {
    setTimesArg(prev => ({...prev , [name] : {'name' : 'time'+ (name + 1) , 'value' : e } }))
    setCustomLog({})
  }

  const
  handleVisitsNumber = (name , e) =>{
      if(e == 0)
      {
        setVisitsNumbers(1);
      }
      else
      {
        setVisitsNumbers(e);
        
      }
  }
  /**
   * userId
   * date
   * hour
   * clientId
   * flat
   * block
   * street
   * area
   * city
   * location
   * cost
   * discount
   * status
   * comment
   */
  
  return( ( permissions && permissions.visitAdmin ) &&
      <div className=" grid grid-auto-fit-md">
        <Container onSubmit={handleSubmit} title={'اضافة زيارة جديدة '}  
          footer={<>
            <Button OnClick={handleVisits} secondary type={'button'} loading = {loading} >     معاينة الزيارات </Button> 
            <Button disabled={ visits.length == 0 }  type='submit' loading = {loading}> تأكيد </Button>
            </>}>
            <>
              <FindField empty = {emptyFiled} data = {clientsData} type = 'text' title = {'العميل'}  name = 'clientId' onChange = {handleInput}/>
              <div className="flex flex-col gap-4">
                <div>
                  {
                    !showAddressFields?
                      <span>
                        {
                          client &&
                          model.getAddress(client)
                        }
                      </span>
                      :<div className=" grid grid-auto-fit gap-2">
                        <div className="flex gap-4" >
                          <TextField empty = {emptyFiled} err={customLog.flat ? customLog.msg : false } type = 'text' title = {'الشقة'} name = 'flat' onChange = {handleInput}/>
                          <TextField empty = {emptyFiled} err={customLog.block ? customLog.msg : false } type = 'text' title = {'عمارة'} name = 'block' onChange = {handleInput}/>
                        </div>
                        <div className="flex gap-4" >
                          <TextField empty = {emptyFiled} err={customLog.street ? customLog.msg : false } type = 'text' title = {'شارع'} name = 'street' onChange = {handleInput}/>
                          <TextField empty = {emptyFiled} err={customLog.area ? customLog.msg : false } type = 'text' title = {'منطقة'} name = 'area' onChange = {handleInput}/>
                        </div>
                        
                        <div className="flex gap-4" >
                          <TextField empty = {emptyFiled} err={customLog.city ? customLog.msg : false } type = 'text' title = {'محافظة'} name = 'city' onChange = {handleInput}/>
                          <TextField empty = {emptyFiled} type = 'text' title = {'مكان مشهور'} name = 'famousPlace' onChange = {handleInput}/>
                        </div>
                        <TextField empty = {emptyFiled} type = 'text' title = {'الموقع'} name = 'location' onChange = {handleInput}/>
                      </div>

                  }
                </div>
                <div>
                  <Button secondary type={'button'} OnClick={() => setShowAddressField(prev => !prev)} >
                    {
                      !showAddressFields ?
                      <div className=" flex items-center gap-1">
                          <CiEdit/>
                        <span>
                        عنوان اخر
                        </span>
                      </div>
                    :  <div className=" flex items-center gap-1">
                          <CiEdit/>
                        <span>
                            استخدام عنوان العميل
                        </span>
                      </div>
                    }
                  </Button>
                </div>
              </div>
              <TextArea empty = {emptyFiled} name = 'comment'  onChange = {handleInput} placeholder = 'تفاصيل'/>
              <div className=" grid grid-auto-fit-xs gap-4 items-center">
                <TextField empty = {emptyFiled} type = 'number' onChange = {handleVisitsNumber} placeholder = 'عدد الزيارات' name = 'visitNumbers'/>
                <span>زيارة في </span>
                <SelectBox empty = {emptyFiled} onChange = {handleScale} options ={{day : 'اليوم' , dayAndDay : 'يوم و يوم',  week : 'اسبوع' , month : 'شهر'}} />
                <TextField empty = {emptyFiled} type = 'number' onChange = {(n , e) => setDaysCount(e)}  placeholder = 'عدد الأيام' name = 'daysCount'  />
              </div>
              <div className=" grid grid-auto-fit-xs gap-4">
                <TextField empty = {emptyFiled} type = 'date' onChange = {handleInput} name = 'date' />
                {
                  Array.apply(null , {length : visitNumbers }).map( (e , i ) =>
                    <TextField empty = {emptyFiled} type = 'time' name = {i} onChange = {handleTimes} />
                  )
                }

                {/* <TimesNumber n = {visitNumbers} handleInput={handleInput}/> */}
              </div>
              <SpliterDiv>
                <TextField empty = {emptyFiled} type = 'number' name ='cost' onChange ={handleInput} placeholder = 'سعر الزيارة'/>
                <TextField empty = {emptyFiled} type = 'number' name ='discount' value = {0} onChange ={handleInput} placeholder = 'الخصم'/>
                <div className=" flex gap-4 border p-1 rounded-lg">
                  <span>
                    اجمالي تكلفة الزيارة الواحده
                  </span>
                  <span className=" text-xl font-bold">
                    {
                      (values.cost - ( values.cost * values.discount / 100))
                    }
                  </span>
                </div>
              </SpliterDiv>
              
            </>
        <LineNotify err ={customLog.err} success={customLog.success} alert={customLog.alert} text = {customLog.msg}/>
        </Container>
      </div>
  ) || <NotAvailable/>
}




export  function AddNewStay()
{
  
  const [fieldData , setFieldData] = useState([]);
  const [emptyFiled , setEmptyField] = useState(false);
  const permissions = useGetPermissions();

  const [values , setValues] = useState({
    mainClientId  : '' ,
    cardId        : '' ,
    patientName   : '' ,
    age           : '' ,
    gender        : "male" ,
    workDate      : '' ,
    salary        : '' ,
    workDays      : '' ,
    freePeriod    : '' ,
    startDate     : '' ,
    paid          : '' ,
    freePeriodType: "day" ,
    freePeriodMain: "month" ,
    wholeTime     : "month" ,
  });



  
  const log = {
    err : false,
    success : false,
    alert : false,
    msg : '',
    phoneErr : '',
    nameErr : '',
    cardIdErr : '',
    addressErr : '',
    birthDateErr : '',
  }
  const [customLog , setCustomLog] = useState(log);
  const [res , setRes] = useState(null);
  useEffect(() => {
    const isErr = (fieldName) =>
    {
      try{
        console.log(res[fieldName])
        return (res[fieldName] == true || res['key'] == fieldName)
      }catch
      {
        return false
      }
    }

    setCustomLog(prev => ({...prev , 'clientId'        : isErr('clientId') }) );
    setCustomLog(prev => ({...prev , 'clientCardId'       : isErr('clientCardId') }) );
    setCustomLog(prev => ({...prev , 'salary'           : isErr('salary') }) );
    setCustomLog(prev => ({...prev , 'paid'          : isErr('paid') }) );
    setCustomLog(prev => ({...prev , 'freePeriod'          : isErr('freePeriod') }) );
  }, [res])
  

  
  
  const handleSubmit = async (e) => {
    e.preventDefault()
    const res = await stayModel.create(values);
    console.log(res.status);
    setCustomLog(prev => ({...prev , msg   : res.msg}) );
    
    if(res.msg)
    {
      setCustomLog(prev => ({...prev , err   : true}) );
    }
    if(res.status == 400)
    {
      setCustomLog(prev => ({...prev , alert   : true}) );
    }
    if(res.status === 200)
    {
      setCustomLog(prev => ({...prev , msg   : 'تم تسجيل اقامة جديدة بنجاح'}) );
      setCustomLog(prev => ({...prev , success   : true}) );
      setValues({})
      setEmptyField(true);
    }
    setRes(res);
  }
  const handleInput = (name , value) => {
    setCustomLog(prev => ({...prev ,log }) );
    setValues(prev => ({...prev , [name] : value }));
  }

  

  useEffect(() => {
    clientModel.getAll(setFieldData);
  }, [])

  
  
  return( (permissions && permissions.addStay) &&
      <>
        <Container onSubmit={handleSubmit} title={'اضافة إقامة جديدة '}  
          footer={<>
            <Button type='submit'> تأكيد </Button>
            <Button secondary type={'button'} >     معاينة العقد </Button> </>}>
            <>
              <FindField data = {fieldData} type = 'text' title = {'العميل'}  name = 'mainClientId' onChange = {handleInput}/>
              <TextField empty = {emptyFiled} type = 'number' title = {'الرقم القومي'} name = 'cardId' onChange = {handleInput}/>
              <TextField empty = {emptyFiled} type = 'text' title = {'اسم الحاله'}  name = 'patientName' onChange = {handleInput}/>
              <SpliterDiv>
                <SelectBox options = {gender} title = {'النوع'}  name = 'gender' onChange = {handleInput}/>
                <TextField empty = {emptyFiled} type = 'number' title = {'السن'} name = 'age' onChange = {handleInput}/>
              </SpliterDiv>
              <SpliterDiv>
                <TextField empty = {emptyFiled} type = 'number' placeholder = 'الراتب' name = 'salary' onChange = {handleInput}/>
                  <span>في</span>
                <SelectBox options = {periods} value = 'month' name = 'wholeTime' onChange = {handleInput}/>
              </SpliterDiv>
              {
                values.wholeTime == 'day' &&
                <TextField empty = {emptyFiled} type = 'number' title = {'ايام العمل'}  name = 'workDays' onChange = {handleInput}/>
              }
              <SpliterDiv>
                <TextField empty = {emptyFiled} type = 'number'  title = {'عدد سعات العمل'}  name = 'hours' onChange = {handleInput}/>
                <TextField empty = {emptyFiled} type = 'number'  title = {'المدفوع'}  name = 'paid' onChange = {handleInput}/>
              </SpliterDiv>
              <SpliterDiv>
                <TextField empty = {emptyFiled} type = 'number' title = {'الأجازة'}  name = 'freePeriod' onChange = {handleInput}/>
                <SelectBox options = {periods} name = 'freePeriodType' onChange = {handleInput}/>
                <span>كل</span>
                <SelectBox options = {periods} value = 'month' name = 'freePeriodMain' onChange = {handleInput}/>
              </SpliterDiv>
              <SpliterDiv>
                <TextField empty = {emptyFiled} type = 'date'    title = {'تاريخ البداية'} name = 'startDate' onChange = {handleInput}/>
                <TextField empty = {emptyFiled} type = 'number' title = {'رسوم التوصيل'}  name = 'deliveryFees' onChange = {handleInput}/>
                {/* <TextField empty = {emptyFiled} type = 'date'    title = {'تاريخ النهاية'} name = 'endDate' onChange = {handleInput}/> */}
              </SpliterDiv>
              <TextArea empty = {emptyFiled} type = 'number' title = {'بند اضافي'} maxLength = {200}  name = 'comment' onChange = {handleInput}/>
            </>
        <LineNotify err ={customLog.err} success={customLog.success} alert={customLog.alert} text = {customLog.msg}/>
        </Container>
      </>
  ) || <NotAvailable/>
}

export  function AddNewClinetDoc()
{
  
  const [fieldData , setFieldData] = useState([]);
  const [values , setValues] = useState({});
  const [emptyFiled , setEmptyField] = useState(false);


  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(values)
  }
  const handleInput = (name , value) => {
    setValues(prev => ({...prev , [name] : value }));
  }
  

  useEffect(() => {
    clientModel.getAll(setFieldData);
  }, [])
  
  return(
      <>
        <Container onSubmit={handleSubmit} title={'اضافة إقامة جديدة '}  
          footer={<Button type='submit'> تأكيد </Button>}>
            <>
              <FindField data = {fieldData} type = 'text' title = {'العميل'}  name = 'clientId' onChange = {handleInput}/>
              <TextField empty = {emptyFiled} type = 'number' title = {'الرقم القومي'} name = 'age' onChange = {handleInput}/>
              <TextField empty = {emptyFiled} type = 'text' title = {'اسم الحاله'}  name = 'patientName' onChange = {handleInput}/>
              <SpliterDiv>
                <SelectBox options = {gender} title = {'النوع'}  name = 'age' onChange = {handleInput}/>
                <TextField empty = {emptyFiled} type = 'number' title = {'السن'} name = 'age' onChange = {handleInput}/>
              </SpliterDiv>
              <SpliterDiv>
                <TextField empty = {emptyFiled} type = 'number' placeholder = 'الراتب' name = 'salary' onChange = {handleInput}/>
                  <span>في</span>
                <SelectBox options = {periods} value = 'month' name = 'age' onChange = {handleInput}/>
              </SpliterDiv>
              
              <TextField empty = {emptyFiled} type = 'number' title = {'ايام العمل'}  name = 'workDays' onChange = {handleInput}/>
              <SpliterDiv>
                <TextField empty = {emptyFiled} type = 'number'  title = {'المدفوع'} placeholder = 'المدفوع' name = 'paid' onChange = {handleInput}/>
              </SpliterDiv>
              <SpliterDiv>
                <TextField empty = {emptyFiled} type = 'number' title = {'الأجازة'}  name = 'freeDays' onChange = {handleInput}/>
                <SelectBox options = {periods} name = 'age' onChange = {handleInput}/>
                <span>كل</span>
                <SelectBox options = {periods} value = 'month' name = 'age' onChange = {handleInput}/>
              </SpliterDiv>
              <SpliterDiv>
                <TextField empty = {emptyFiled} type = 'date'    title = {'تاريخ البداية'} name = 'workDate' onChange = {handleInput}/>
                <TextField empty = {emptyFiled} type = 'date'    title = {'تاريخ النهاية'} name = 'workDate' onChange = {handleInput}/>
              </SpliterDiv>
            </>
        </Container>
      </>
  )
}


