import { Page, Text, Image, View, Document, StyleSheet, PDFViewer, Font } from '@react-pdf/renderer';
import FontBold from '../../../fonts/Cairo-VariableFont_slnt,wght.ttf'
import logo from '../../../../images/logo.png'
import { model } from '../../../../model';
import { useEffect, useRef } from 'react';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';



export
const fontsizes = {
  sm : 7,
  nm : 9 ,
  lg : 10.5 ,
  xl : 15
}



Font.register({
  family : 'OunFont' , 
  src : FontBold
  // fonts : [
  //   {
  //     fontWeight : 700 ,
  //   }
  // ]
});

export
const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    fontFamily : "OunFont",
    textAlign : "right",
    direction : "rtl",
    padding : 2,
    color : "#31a4ee",
  
  },
  header: {
    flexDirection: 'row',
    margin: 5,
    marginBottom : 2 ,
    padding: 10,
    flexGrow: 2,
    display : 'flex',
    alignContent : 'center',
    alignItems : "center",
    justifyContent : 'space-between',
    // fontFamily : "OunFont",
    fontWeight : 50 ,
    fontSize : fontsizes.xl ,
    borderBottomWidth : 1 ,
    borderBottomColor : "gray",
  },
  section: {
    padding: 10,
    flexGrow: 2,
    color : "#0b3f60",
    

    // display : 'flex',
    // justifyContent : 'space-between',
    // fontFamily : "NotoKufi",
    fontSize : fontsizes.lg,
  },
  img : {
    width : 60,
    height : 60,
  },
  value : {alignItems : 'flex-start' , display : "flex" , flexDirection : 'row' , justifyContent : 'center'}
  
});


export
function DocHeader({title , barcodeImg , documentNO})
{
  return(
    <View>
    <View style={styles.header}>
      <View style={{alignItems : "center"}}>
        <Image src={logo} style={styles.img} />
          <Text style={{fontSize : fontsizes.lg , }}>بيت التمريض</Text>
          {/* <Text style={{fontSize : fontsizes.lg , }}>  للخدمات الطبية المنزلية  </Text> */}
        <View>
        </View>
      </View>
    
      <View>
        <View >
          <Text style={{fontSize : fontsizes.xl , }} > 
          {
            title
          }
          </Text>
        </View>
        <View style={{display : 'flex' , flexDirection : 'row' , gap : 4  , alignItems : 'center' , color : 'black' , fontWeight : 800 , fontSize : fontsizes.nm}}>
          {
            barcodeImg &&
            <Image src={barcodeImg} style={{width : 150}}/>
          } 
          <Text>
            {
              documentNO
            }
          </Text>
        </View>
      </View>
    </View>
  </View>
  )
}

export
function DocFooter({qrCodeImage })
{
  return(
    <View style={{fontSize : fontsizes.lg}}>
      <View style={{fontSize : fontsizes.lg , padding : 5 , width : 50 }}>
      { qrCodeImage &&
        <Image src={qrCodeImage} />
      }
      </View>
      <View style={{display : "flex" , flexDirection : 'row' , justifyContent : "space-around" , position : "fixed" , bottom : 0  , borderTopWidth : 1 , padding : 5}}>
        <Text>Nursing House</Text>
        <Text>www.nursinghouse.net</Text>
        <Text>+20 120 112 0500</Text>
        <Text>بيت التمريض</Text>
      </View>
  </View>
  )
}




export
function Term({head , value , data })
{
  let newValue = value;
  // console.log(data);
  Object.keys(data).map(e => {
    let  uniqeKey =  `{ ${e} }` ;
    let  uniqeValue  = data[e];
    switch (e) {
      case 'salary':
        uniqeKey = '{ المرتب }';
        break;
      case 'wholeTime':
        uniqeKey = '{ مدة التعاقد }';
        break;
      case 'startDate':
        uniqeKey = '{ تاريخ البداية }';

        break;  
      case 'endDate':
        uniqeKey = '{ تاريخ النهاية }';
        
        break;
      case 'workHours':
        uniqeKey = '{ ساعات العمل }';
        
        break;
      case 'freePeriod':
        uniqeKey = '{ الأجازة }';
        break;
      case 'freePeriodType':
        uniqeKey = '{ معيار الأجازة }';
        break;
      case 'freePeriodMain':
        uniqeKey = '{ مدة الأجازة }';
        break;
      case 'paid':
        uniqeKey = '{ المدفوع }';
        break;
      case 'deliveryFees':
        uniqeKey = '{ رسوم توصيل }';
        break;

      default:
        break;
    }
    switch (uniqeValue) {
      case 'day':
        uniqeValue = 'يوم'
        break;
      case 'week':
        uniqeValue = 'اسبوع'
        break;
      case 'month':
        uniqeValue = 'شهر'
        break;
      case 'year':
        uniqeValue = 'سنة'
        break;
    
      default:
        break;
    }
    if( e == 'wholeTime' || e == 'workHoursPeriod')
    {
      if(data[e] == 'day') uniqeValue = 'يومياً'
      if(data[e] == 'week') uniqeValue = 'اسبوعياً'
      if(data[e] == 'month') uniqeValue = 'شهرياً'
      if(data[e] == 'year') uniqeValue = 'سنوياً'
    }
    else if( e == 'freePeriod' || e == 'freePeriodMain')
    {
      if(data[e] == 'day') uniqeValue = 'يوم'
      if(data[e] == 'week') uniqeValue = 'اسبوع'
      if(data[e] == 'month') uniqeValue = 'شهر'
      if(data[e] == 'year') uniqeValue = 'سنة'
    }
    else if(e == 'startDate')
    {
      
      if(data[e] == '') uniqeValue = model.dateToText('today' , true);
      else uniqeValue = model.dateToText(data[e] , true);
    }
    else if(e == 'freePeriod')
      {
        if(data[e] == '' || data[e] == 0) uniqeValue = 'لا يوجد'
      }
      
      
      let res = newValue.replace(uniqeKey , uniqeValue);
      newValue = res;
      
      
        
          if(data["wholeTime"] == 'day')
            {
              res = newValue.replace(
                '{ تاريخ النهاية }' , 
                data.workDays + ' يوم من تاريخه'
              );
              newValue = res;
            } 
          else if(data["wholeTime"] == 'week')
            {
              res = newValue.replace(
                '{ تاريخ النهاية }' , 
                'اسبوع من تاريخه'
              );
              newValue = res;
            } 
          else if(data["wholeTime"] == 'month')
            {
            res = newValue.replace(
              '{ تاريخ النهاية }' , 
              'شهر من تاريخه'
            );
            newValue = res;
          } 
          else if(data["wholeTime"] == 'year')
            {
            res = newValue.replace(
              '{ تاريخ النهاية }' , 
              'سنة من تاريخه'
            );
            newValue = res;
          }  
    // console.log(e + ' -- ' + uniqeKey);
  })
  
  return (
    <View style={{borderRightWidth : 2 , padding: 5 , borderColor:'#31a4ee' , margin : 5}}>
      <Text style={{fontSize : fontsizes.lg  , paddingRight : 10}}>
        {
          head
        }
      </Text>
      <Text>
        {
          newValue
        } 
      </Text>
    </View>
  )
}


export
function BarcodeQrCodePlace({barcodeNo , takeBarcode , takeQrcode})
{
  const barcodeRef = useRef(null)
  const qrcodeRef = useRef(null)
  const getImageScreened = () => {
    takeBarcode(barcodeRef.current)
    takeQrcode(qrcodeRef.current)
  }
  useEffect(() => {
    getImageScreened();
  })
  return(
    <div className="p-0 flex items-center fixed -right-96 " >
      <div ref={barcodeRef} className="w-fit">
        <Barcode value={barcodeNo} displayValue = {false} height={20}  />
      </div>
      <div ref={qrcodeRef} className="w-fit">
        <QRCode value="https://nursinghouse.net/ar" className="w-20"  />
      </div>    
  </div>
  )
}
