
// import {createTw} from 'react-pdf-tailwind'
import { Page, Text, Image, View, Document, PDFViewer } from '@react-pdf/renderer';
import { model } from '../../../model';
import { BarcodeQrCodePlace, DocFooter, DocHeader, Term, fontsizes, styles } from './asset';
import { useEffect, useState } from 'react';
import { docModel } from '../../../model/doc';
import { useScreenshot } from 'use-react-screenshot';




// Create styles




function DocInfo({ companyName , client})
{
  return(
    <View style={{borderWidth : 1 , padding : 10 , marginVertical : 5 , borderRadius : 10, borderColor : "#31a4ee"}} >
            <Text style={{}}>
               طرف اول :  {
                companyName
               }
            </Text>
            <Text style={{}}>
               طرف ثان :  {
                client.name
               }
            </Text>
               
               <View style={{display:'flex' , flexDirection : "row" , textAlign : "right" , justifyContent : "space-between" , direction : "rtl" }} > 
               <View style={styles.value}>
                  <Text style={{}}> {
                   client.nationality == '' ? 'مصري' : client.nationality
                  }</Text>
                  <Text style={{}}> / الجنسيه </Text>
               </View>
               <View style={styles.value}>
                  <Text style={{}}> {client.cardId}</Text>
                  <Text style={{}}> / رقم تحقيق هوية </Text>
               </View>
               </View>
               <View style={{display:'flex' , flexDirection : "row" , textAlign : "right" , justifyContent : "space-between" , direction : "rtl" }} > 
                  
                  <View style={styles.value}>
                      <Text style={{}}> {client.phone}</Text>
                      <Text style={{}}> / رقم هاتف </Text>
                  </View>
                  <View style={styles.value}>
                      <Text style={{maxWidth : 200}}>  { client.address}</Text>
                      <Text style={{fontSize:fontsizes.lg}}>  / العنوان </Text>
                  </View>
               </View>
              
          </View>
  )
}

function WholeTerms({data , terms })
{
  useEffect(() => {
    console.log(terms , data);
  })
  return (
    <View >
      {
        terms.map(e => (
          <Term head = {e.title} value={e.term} data={data}  />
        ))
      }
      {
        data.comment &&
        <View style={{borderRightWidth : 2 , padding: 5 , borderColor:'#31a4ee' , margin : 5}}>
          <Text style={{fontSize : fontsizes.lg  , paddingRight : 10}}>
            بند اضافي
          </Text>
          <Text>
            {
              data.comment
            } 
          </Text>
      </View>
      }
    </View>
  )
}




export default  function EmployeePDF ({ target = 'employee' , data , client}){
  const firstOne  = "شركة بيت التمريض للرعايه المنزلية وتوريد العمالة ومقرها 127 أجول جمال ميدان الألف مسكن ب . ض 547-387-709"
  const [terms , setTerms] = useState(null);
  useEffect(() => {
    docModel.getEmployeeDoc(setTerms , target);
  } , [])

  const [barcodeImg , takeBarcode] = useScreenshot()
  const [qrCodeImage , takeQrcode] = useScreenshot();

  // console.log(data)


return terms &&  (
  <>
    <PDFViewer className="p-0 w-full h-full rounded-md bg-white my-5">  
      <Document  style={styles.document}>
        <Page size="A4" style={styles.page} >
          <DocHeader title={'عقد عمل محدد المدة'} barcodeImg={barcodeImg} documentNO={data.id} />
          <View style={styles.section}>
            <Text style={{textDecoration : 'underline'}} >  / تحرر هذا العقد بين كلاً من </Text>
            <DocInfo companyName={firstOne} data={data} client = {client} />
            <WholeTerms data={data} terms={terms} />
            <View style={{display : 'flex' , flexDirection  : 'row' , fontSize : fontsizes.xl , justifyContent : "space-around"}}>
              <Text>
                ( العميل ) طرف ثان 
              </Text>
              <Text>
                  طرف اول  ( الشركة ) 
              </Text>
            </View>
          </View>
          <DocFooter qrCodeImage={qrCodeImage}/>
        </Page>
      </Document>
    </PDFViewer>
    <BarcodeQrCodePlace barcodeNo={data.id} takeBarcode={takeBarcode} takeQrcode={takeQrcode} />
    
  </>
  )}




