import { createRef, useEffect, useRef, useState } from "react";
import { employeeModel } from "../../../../../model/employee";
import Container from "../../../container";
import Button, { TabButton } from "../../../asset/button";
import { model } from "../../../../../model";
import { useDelay, useRefDimensions } from "../../../../hooks/customHook";
import TextField from "../../../asset/textField";
import { LineNotify } from "../../../asset/notify";
import FindField from "../../../asset/findField";
import { stayModel } from "../../../../../model/stay";
import { clientModel } from "../../../../../model/client";
import { Field, ScrollCard, ScrollableCard } from "../assets";



export
function ClientData({modelId})
{
  const [values , setValues] = useState({id : modelId})
  const [person , setModel] = useState([]);
  const [currentCard , setCurrentCard] = useState(0);
  const [mainStayData , setMainStayData] = useState({});
  const [employeeStayData , setEmployeeStayData] = useState({});
  const [employeeData , setEmployeeData] = useState({});
  const [secondStayData , setSecondStayData] = useState({});
  const [secondEmployeeStayData , setSecondEmployeeStayData] = useState({});
  const [secondEmployeeData , setSecondEmployeeData] = useState({});
  const [emptyField , setEmptyField] = useState(false);

  useDelay(() => {
    clientModel.getById(modelId , setModel);
  }, 5)
  useEffect(() => {
    clientModel.getById(modelId , setModel);
  },[modelId])

  useEffect(() => {
    stayModel.getClientById('mainClient' , modelId , setMainStayData);
    stayModel.getClientById('secondClient' , modelId , setSecondStayData);
  },[person])

  useEffect(() => {
    employeeModel.getByStayId(mainStayData.id , setEmployeeStayData);
  },[mainStayData])

  // //console.log(employeeStayData)

  useEffect(() => {
    employeeModel.getById(employeeStayData.employeeId , setEmployeeData);
  },[employeeStayData])
  useEffect(() => {
    employeeModel.getByStayId(secondStayData.id , setSecondEmployeeData);
  },[secondStayData])

  // //console.log(employeeStayData)

  useEffect(() => {
    employeeModel.getById(secondEmployeeStayData.employeeId , setSecondEmployeeData);
  },[secondEmployeeStayData])

  const [customLog , setCustomLog] = useState({});
  const handleInput = (name , value) => {
    setCustomLog({})
    setValues(prev => ({...prev , [name] : value }));
    setValues(prev => ({...prev , id : modelId }));
  }
  // useEffect(() => {
  //   // //console.log(values)
  // },[values])
  // //console.log(employeeData)
  
  
  const setChanges = async (e) =>{
    e.preventDefault()
    if(currentCard == 2)
      {
        // console.log('want to change is ');
        const res = await clientModel.updateMainData(values);
        if(res.status == 200)
          {
            setEmptyField(true);
            setCustomLog(prev => ({...prev , msg : 'تمت التغيرات بنجاح' , success : true}))
            // setEmptyField(false);
          }
          else
          {
            setCustomLog(prev => ({...prev , msg : res.data.msg , err : true , success : false}))
          }
      }
      else if(currentCard == 3)
      {
        // todo
      }
    
  }
  const [width , setWidth] = useState();
  //console.log(currentCard);
  return(
    modelId > 0 &&
    <>
      <Container onSubmit={setChanges}  title={person.name} title2={'عميل'}
        
        header={
          <div className=" ">
            
            <TabButton activeColor="gray-100"  active={currentCard == 0} OnClick={() => {setCurrentCard(0)}} secondary type='button'>
              البيانات الأساسية
            </TabButton>
            <TabButton activeColor="gray-100"  active={currentCard == 1} OnClick={() => {setCurrentCard(1)}}  secondary type='button'>
              بيانات الأقامة
            </TabButton>
            <TabButton  activeColor="gray-100" active={currentCard == 2} OnClick={() => {setCurrentCard(2)}}  secondary type='button'>
              تحديث البيانات
            </TabButton>
            {
              // personStay.length > 0 &&  toDo
              <TabButton  activeColor="gray-100" active={currentCard == 3} OnClick={() => {setCurrentCard(3)}}  secondary type='button'>
                تحديث 
              </TabButton>
            }
          </div>
        }
        footer={ 
          <>
          {
          (currentCard == 2 || currentCard == 3) && 
            <Button>
              تأكيد التغيرات
            </Button>
          }
          <Button secondary OnClick={() => {modelId = null}} >
            الغاء
          </Button>
          </>
        }
      >
          <ScrollableCard currentCard={currentCard} setWidth={setWidth} >
            <MainDataCard width = {width} person = {person}/>
            <StayDataCard stayData={mainStayData} secondEmployeeData = {secondEmployeeData} secondStayData={secondStayData} width={width} employeeData={employeeData} />
            <UpdateMainDataCard width = {width} person = {person} customLog = {customLog} handleInput = {handleInput} emptyFiled={emptyField} />
          <UpdateStayDataCard width = {width} person = {person} customLog = {customLog} handleInput = {handleInput} />
          </ScrollableCard>
      </Container> 
    </>
  )
}



function UpdateStayDataCard({width , person , customLog , handleInput})
{
  return(
    <ScrollCard width={width} >
      <FindField data = {person} type = 'text' title = {'اسم الحالة'} placeholder = 'علي محمد' name = 'stayId' onChange = {handleInput}/>
      <TextField value = {person.name} err={customLog.nameErr ? customLog.msg : false} type = 'text' title = {'الراتب'}  name = 'name' onChange = {handleInput}/>
      <TextField value = {person.name} err={customLog.nameErr ? customLog.msg : false} type = 'date' title = {'بداية الشهر'}  name = 'name' onChange = {handleInput}/>
      <TextField value = {person.name} err={customLog.nameErr ? customLog.msg : false} type = 'number' title = {'ايام العمل'}  name = 'name' onChange = {handleInput}/>
      <TextField value = {person.name} err={customLog.nameErr ? customLog.msg : false} type = 'number' title = {'ايام الراحة'}  name = 'name' onChange = {handleInput}/>
      <TextField value = {person.name} err={customLog.nameErr ? customLog.msg : false} type = 'number' title = {'ايام الغياب'} placeholder = 'علي محمد' name = 'name' onChange = {handleInput}/>
      <TextField value = {person.name} err={customLog.nameErr ? customLog.msg : false} type = 'number' title = {'خصم'}  name = 'name' onChange = {handleInput}/>
      <TextField value = {person.name} err={customLog.nameErr ? customLog.msg : false} type = 'number' title = {'اضافي'}  name = 'name' onChange = {handleInput}/>
      <TextField value = {person.name} err={customLog.nameErr ? customLog.msg : false} type = 'number' title = {'ساعات العمل'}  name = 'name' onChange = {handleInput}/>
      <TextField value = {person.name} err={customLog.nameErr ? customLog.msg : false} type = 'number' title = {'سلف'}  name = 'name' onChange = {handleInput}/>
      <LineNotify err ={customLog.err} success={customLog.success} alert={customLog.alert} text = {customLog.msg}/>
    </ScrollCard>
  )
}

function UpdateMainDataCard({width , person , customLog , handleInput , emptyFiled})
{


  return(
    <ScrollCard width={width} >
        <TextField empty = {emptyFiled} err={customLog.nameErr ? customLog.msg : false} type = 'text' title = {'الأسم'}  name = 'name' onChange = {handleInput}/>
        <TextField empty = {emptyFiled} err={customLog.nameErr ? customLog.msg : false} type = 'tel' title = {'رقم الهاتف'} name = 'phone' onChange = {handleInput}/>
        <TextField empty = {emptyFiled} type = 'tel' title = {'رقم هاتف اخر'} name = 'phone2' onChange = {handleInput}/>
        <TextField empty = {emptyFiled} type = 'tel' title = {'رقم قومي'} name = 'cardId' onChange = {handleInput}/>
        <div className="flex gap-4" >
          <TextField empty = {emptyFiled} err={customLog.flat ? customLog.msg : false } type = 'text' title = {'الشقة'} name = 'flat' onChange = {handleInput}/>
          <TextField empty = {emptyFiled} err={customLog.block ? customLog.msg : false } type = 'text' title = {'عمارة'} name = 'block' onChange = {handleInput}/>
        </div>
        <div className="flex gap-4" >
          <TextField empty = {emptyFiled} err={customLog.street ? customLog.msg : false } type = 'text' title = {'شارع'} name = 'street' onChange = {handleInput}/>
          <TextField empty = {emptyFiled} err={customLog.area ? customLog.msg : false } type = 'text' title = {'منطقة'} name = 'area' onChange = {handleInput}/>
        </div>
        
        <div className="flex gap-4" >
          <TextField empty = {emptyFiled} err={customLog.city ? customLog.msg : false } type = 'text' title = {'محافظة'} name = 'city' onChange = {handleInput}/>
          <TextField empty = {emptyFiled} type = 'text' title = {'مكان مشهور'} name = 'famousPlace' onChange = {handleInput}/>
        </div>
        <TextField empty = {emptyFiled} type = 'text' title = {'الموقع'} name = 'location' onChange = {handleInput}/>
        <LineNotify err ={customLog.err} success={customLog.success} alert={customLog.alert} text = {customLog.msg}/>
          </ScrollCard>
  )
}

function MainDataCard({width , person})
{
  return(
    <ScrollCard width = {width} >
      <Field ownKey={'الأسم'} value={person.name} />
      <Field ownKey={'رقم البطاقة'} value={person.cardId}/>
      <Field ownKey={'رقم الهاتف'} value={person.phone}/>
      <Field ownKey={'رقم هاتف ثاني'} value={person.phone2}/>
      <Field ownKey={'العنوان'} value={model.getAddress(person)}/>
      <Field ownKey={'الجنسية'} value={person.nationality}/>
      <Field value={'بيانات الزيارات'} reference={'/visit/client/'+person.id}/>
      
      </ScrollCard>
  )
}


function StayDataCard({stayData = {} , secondStayData = {} ,  width , employeeData = {} , secondEmployeeData = {}})
{
  // //console.log(employeeData);
  
  return(
    <ScrollCard width={width} >
      <div>
        <div className=" py-2 translate-x-5 text-lg">
          <span>بيانات الأقامة الأساسية</span>
        </div>
        
        {
          stayData.id ?
          <>
            <Field ownKey={'اسم الحالة'} value={stayData.patientName}  reference={`/stay/${stayData.id}`}/>
            <Field ownKey={'اسم المقيم مع الحالة'} value={employeeData.name} reference={`/employee/${employeeData.id}`}/>
            <Field ownKey={'الراتب'} value={stayData.salary}/>
            <Field ownKey={'بداية الشهر'} value={model.dateToText(stayData.startDate)}/>
            <Field ownKey={'نهايةالشهر'} value={model.dateToText(model.getEndDate(stayData))}/>
            {/* <Field ownKey={'ايام العمل'} value={stayData.workDays}/> */}
            <Field ownKey={'ساعات العمل'} value={stayData.hours}/>
            <Field ownKey={'المدفوع'} value={stayData.paid}/>
            {/* <Field ownKey={'الخصم'} value={stayData.deduct}/> */}
            <Field ownKey={'الأضافي'} value={stayData.additional}/>
            <Field ownKey={'الغياب'} value={model.getFreeInPeriod(stayData)}/>
            <Field ownKey={'عدد الأيام'} value={model.getWorkInPeriod(stayData)}/>
            <Field ownKey={'الباقي'} value={model.getTotal(stayData)}/>
            <Field ownKey={'العقود'} value={"طباعة العقد"} reference={'/docs/client/'+stayData.id}/>
          </>
          :
          <>
          <div>
            لا توجد بيانات اقامة اساسية 
          </div>
          </>
        }
      </div>
      <div>
        <div className=" py-2 translate-x-5 text-lg">
          <span>بيانات الأقامة الفرعية</span>
        </div>
        
        {
          secondStayData.id ?
          <>
            <Field ownKey={'اسم الحالة'} value={secondStayData.patientName}  reference={`/stay/${secondStayData.id}`}/>
            <Field ownKey={'اسم المقيم مع الحالة'} value={secondEmployeeData.name} reference={`/employee/${secondEmployeeData.id}`}/>
            <Field ownKey={'الراتب'} value={secondStayData.salary}/>
            <Field ownKey={'بداية الشهر'} value={model.dateToText(secondStayData.startDate)}/>
            <Field ownKey={'نهايةالشهر'} value={model.dateToText(model.getEndDate(secondStayData))}/>
            {/* <Field ownKey={'ايام العمل'} value={stayData.workDays}/> */}
            <Field ownKey={'ساعات العمل'} value={secondStayData.hours}/>
            <Field ownKey={'المدفوع'} value={secondStayData.paid}/>
            {/* <Field ownKey={'الخصم'} value={stayData.deduct}/> */}
            <Field ownKey={'الأضافي'} value={secondStayData.additional}/>
            <Field ownKey={'الغياب'} value={model.getFreeInPeriod(secondStayData)}/>
            <Field ownKey={'عدد الأيام'} value={model.getWorkInPeriod(secondStayData)}/>
            <Field ownKey={'الباقي'} value={model.getTotal(secondStayData)}/>
          </>
          :
          <>
          <div>
            لا توجد بيانات اقامة فرعية 
          </div>
          </>
        }
      </div>
  </ScrollCard>
  )
}