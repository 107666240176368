import { useEffect, useState } from "react";


function useCustomDialogsEvent(eventKey , callBack)
{
  useEffect(() => {
    document.addEventListener(eventKey, callBack )
    return document.removeEventListener(eventKey, (e) => {
      // console.log(e.detail  , "removed");
    })
  } , [])
}
function useCustomPageEvent(callBack)
{
  useEffect(() => {
    document.addEventListener('newPageEvent', callBack )
    // console.log('evented 2')
    return document.removeEventListener('newPageEvent', (e) => {
      // console.log(e)
    })
  } , [])
}

function useUpdateData(callBack)
{
  useEffect(() => {
    document.addEventListener('updateData', callBack )
    // console.log('evented 2')
    return document.removeEventListener('updateData', (e) => {
      // console.log(e)
    })
  } , [])
}


function useShowMenuEvent(callBack)
{
  useEffect(() => {
    document.addEventListener('showMenu', callBack )
    // console.log('evented 2')
    return document.removeEventListener('showMenu', (e) => {
      // console.log(e)
    })
  })
}




const customDialogsEvents = {
  useEmployee : (callBack) =>( useCustomDialogsEvent('showEmployeeDialog', callBack)),
  useSupplies : (callBack) =>( useCustomDialogsEvent('showSuppliesDialog', callBack)),
  useNewClient : (callBack) =>( useCustomDialogsEvent('showNewClientDialog', callBack)),
  useNewStay : (callBack) =>( useCustomDialogsEvent('showNewStayDialog', callBack)),
  useNewUser : (callBack) =>( useCustomDialogsEvent('showNewUserDialog', callBack)),
  useNewEmployee : (callBack) =>( useCustomDialogsEvent('showNewEmployeeDialog', callBack)),
}


export {
  customDialogsEvents,
  useCustomPageEvent,
  useUpdateData,
  useShowMenuEvent
}