import axios from 'axios'
import Cookies from 'js-cookie'
import { model } from '..';


export
const backupModel = {
  getAll : async (setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer + '/backup/get_all' ,  {headers : {'access-token' : token, }})
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },

  create : async () => {
    let response = null
    const token = false;
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.post( model.backEndServer + '/backup/create' , { } ,  {headers : {'access-token' : token, }} )
                  .then(res => {
                    // console.log(res);
                    response = res.data;
                  })
                  .catch(err => {
                    // console.log(err);
                    response = err.response.data;
                  } );
    }
    catch(err)
    {
      // console.log(err)
      response = err;
    }
    return response
  },


  
  
  update : async (dataModel) => {
    let response = null
    const token = Cookies.get('_auth');
    try
    {
      const data = await axios.patch( model.backEndServer + '/backup/update' , dataModel ,  {headers : {'access-token' : token, }})
        .then(res => {
          console.log(res);
          response = res;
        })
        .catch(err => {
          console.log(err);
          response = err.response;
        } );
    }
    catch(err)
    {
      console.log(err)
      response = err;
    }
    return response
  },
  

}
 