import Page from "./page";
import ScrollableDataView from "../dashboard/asset/dataView";
import { PopDialog } from "../dashboard/asset/popDialog";
import TextField from "../dashboard/asset/textField";
import {model} from '../../model'
import { userModel } from '../../model/user'
import { BiHistory, BiX } from "react-icons/bi";
import { CiEdit } from "react-icons/ci";
import Button from "../dashboard/asset/button";
import { useEffect, useState } from "react";
import { Empty, Switch } from 'antd'
import { useNavigate , useParams } from "react-router-dom";
import NewItemDialog from "../dashboard/asset/newItemDialog";
import { AddNewUser } from "../dashboard/containers/addNew";
import { useUpdateData } from "../hooks/events";
import { useDelay, useGetAllJobs, useGetPermissions } from "../hooks/customHook";
import { updateDataEvent } from "../events";
import SelectBox from "../dashboard/asset/selectBox";
import LoadingScreen from "../dashboard/asset/loading";

export default function UserPage({})
{
  const { route } =useParams()
  const [loading , setLoading] = useState([]);
  const [users , setUsers] = useState([]);
  const permissions = useGetPermissions();

  const loadData = async () => {
    setLoading(true);
    await userModel.getAll(setUsers);
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, [])

  useUpdateData(() => {
    loadData();
  })

  return permissions.viewUser ? (<Page>
    <NewItemDialog>
      <AddNewUser/>
    </NewItemDialog>
    <ScrollableDataView>
      {
        users.length > 0 ?
        users.map(user => (
          <UserBlock user={user} />
        ))
        :
        loading ? 
        <LoadingScreen/>
        :
        <Empty/>
      }
    </ScrollableDataView>
    {
      permissions.addUser &&
      <UserCardDataCard userId={route} personal = { !route }/>
    }
  </Page>
  ) : <Empty/>
}




export
function UserCardDataCard({ userId = 0 , personal = false })
{
  const [user , setUser] = useState({})
  const [showEditPassword , setEditShowPassword] = useState(false);
  const [showEditJob , setEditShowJob] = useState(false);
  const [loading , setLoading] = useState(false);
  const [suLoading , setSuLoading] = useState(false);
  const [values , setValues] = useState({});
  const navigator = useNavigate();
  const jobs = useGetAllJobs()

  const loadModel = async () => {
    setLoading(true);
    const user = await userModel.getById(userId , setUser);
    // alert('want to load ' + userId)
    // console.log(user);
    setLoading(false);
  }
  useEffect(() => {
    loadModel();
  },[userId])
  useUpdateData(() => {
    loadModel();
  });
  const handleSwitch = async (active) => {
    setSuLoading(true);
    await userModel.active( user.id , active);
    updateDataEvent();
    setSuLoading(false);
  }

  useEffect(() => {
    console.log(user);
  } , [user])

  const handleInput = ( name , value ) => {
    setValues(prev => ({ ...prev , [name] : value , 'id' : user.id  }))
  }

  const handleChangePassword = async () => {
    setLoading(true);
    const data = await userModel.updatePasswordFromAdmin(values);
    setEditShowPassword(false);
    setLoading(false);
    updateDataEvent();
  }
  const handleChangeJob = async () => {
    setLoading(true);
    const data = await userModel.updateJobFromAdmin(values);
    setEditShowJob(false);
    setLoading(false);
    updateDataEvent();
  }

  

  const goToEmployee = () => {
    navigator('/employee/'+ user.userId )
  }

  return !loading ? ( ( user.name ) &&
    <div  >
      <div className="  flex gap-4 items-center flex-col text-center " >
        <div className=" w-40 h-40 hover:bg-slate-200 hover:shadow-lg rounded-2xl p-2 cursor-pointer " >
          <UserAvatar user={user}/>
        </div>
        <div>
          <div className=" text-xl font-bold" >
            {
              user.name
            }
          </div>
          <div className=" text-lg" >
            {
              user.id
            }
          </div>
        
        </div>
      </div>
      <div >
        <div className=" flex border-b-2 p-2 justify-around" >
          <div className=" text-2xl " >
            بيانات اساسية
          </div>
          {
            (!personal) &&
            <Button secondary type={'button'} OnClick = {goToEmployee}   >
              <CiEdit/>
            </Button>
          }
        </div>
        <div>
          <PopDialog show = {showEditJob} setShow={setEditShowJob} onSubmit={handleChangeJob} >
            <TextField placeholder = 'كلمة المرور الخاصة بك' name = 'userPass' onChange = {handleInput} type = 'password'  />
            <SelectBox options = {jobs} placeholder = 'الوظيفة الجديدة' name = 'job' onChange = {handleInput} />
          </PopDialog>
          <PopDialog show = {showEditPassword} setShow={setEditShowPassword} onSubmit={handleChangePassword} >
            <TextField placeholder = 'كلمة المرور الخاصة بك' name = 'userPass' onChange = {handleInput} type = 'password'  />
            <TextField placeholder = 'كلمة المرور الجديدة' name = 'newPass' onChange = {handleInput} type = 'password' />
          </PopDialog>
          <InfoLine base="تاريخ الميلاد" data={model.dateToText(user.birthDate)} />
          <InfoLine base="تاريخ التعيين" data={model.dateToText(user.workDate)} />
          <InfoLine base="البريد" data={user.username} />
          <InfoLine base="الهاتف" data={user.phone} />
          <InfoLine base="النوع" data={model.getGender(user)} />
          <InfoLine base="العنوان" data={user.address} />
          {
            (!personal) &&
            <div className=" p-2 border-2 mb-5" >
              <InfoLine base="امكانية الوصول" data={
                <div >
                  <Switch loading = {suLoading} onChange={ (e) =>  handleSwitch(e)} defaultValue = { user.active }  />
                </div>
                } />
              <InfoLine base="الوظيفة" data={user.job} onEdit={ () => setEditShowJob(true)} loading = {loading} />
              <InfoLine base="كلمة المرور" data="**********" onEdit={ () => setEditShowPassword(true)} loading = {loading} />
            </div>
          }
        </div>
      </div>
    </div>
  ) : <LoadingScreen/>
}


function InfoLine ({base = '' , data = '' , onEdit = null , loading = false })
{

  return(
    <div className=" flex gap-4 text-lg p-1 items-center " >
      <div className=" text-slate-500" >
        {
          base
        }
      </div>
      <div>
        {
          data
        }
      </div>
        {
          onEdit &&
          <Button OnClick={onEdit} secondary type={'button'} loading = { loading } >
            <CiEdit/>
          </Button>
        }
    </div>
  )
}


function UserBlock({user = {}})
{
  const navigator = useNavigate();
  const handleClick = () =>{
    navigator('/user/' + user.id);
  }
  

  return(
    <div
      onClick={handleClick}
      className=" flex flex-col gap-2 items-center cursor-pointer bg-slate-100 rounded-xl p-2
      hover:bg-slate-200 duration-150 ease-linear 
      hover:shadow-lg  " >
      <div className=" w-24 h-24">
        <UserAvatar user = {user}   />
      </div>
      <div className="  h-fit w-32 text-center whitespace-nowrap overflow-x-hidden font-bold truncate " >
        {
          user.name
        }
      </div>
      <div className="  h-fit w-32 text-center whitespace-nowrap overflow-x-hidden truncate " >
        {
          user.job
        }
      </div>
    </div>
  )
}
function UserAvatar({ user = {} })
{
  
  const [online , setOnline] = useState(false);
  const loadOnlineData = async () => {
    await 
    userModel.getOnline( user.id , setOnline);
  }

  useEffect(() => {
    loadOnlineData();
  })

  useUpdateData(() =>{
    loadOnlineData();
  })

  useDelay(() => {
    loadOnlineData();
  },10)


  // console.log('the online status is : ' , user.online , user)
  return(
    <div className=" relative w-full h-full bg-sky-400 rounded-full p-0.5  " >
        <div className=" w-full h-full rounded-full overflow-hidden bg-slate-300 ">
          <img src={user.image} className=" w-full h-full" />
        </div>
        {
          online ?
          <div className=" w-4 h-4 absolute bottom-2 bg-green-300 border-green-600 border-2 rounded-full" />
          : user.active ?
          <div className=" w-fit h-fit p-0.5 text-xs px-1 absolute bottom-1 bg-green-50 text-green-600  border-green-600 border rounded-lg">
            {
              model.timeToType(user.lastSeen)
            }
          </div>
          : ''
          
        }
        {
          !user.active && 
           <div className=" w-4 h-4 absolute bottom-4  text-red-500 text-3xl  rounded-full" >
            <BiX className=" rounded-full border-red-600 border-2" />
           </div>
        }
      
      </div>
  )
}














