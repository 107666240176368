
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

import Page from "../pages/page";
import LoadingScreen from './asset/loading';
import { useEffect, useState } from 'react';
import { Empty } from 'antd'
import { model } from '../../model';
import axios from "axios"

export default function Dashbord()
{

  const [images , setImages] = useState([]);
  const [loading , setLoading] = useState(false);
  
  const loadData = async () => {
    setLoading(true);
    const data = await axios.get(model.backEndServer + '/get_images')
    setImages(data.data);
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  } , [])
  
  return(
    <Page>
      <ImageDataViewer  data={images} loading = { loading } />
      {/* <img src={require(('file://' + images[0]))} alt="alt" /> */}
    </Page>
  )
}


function ImageDataViewer({ data = [] , loading = false}) {
  
  return(
    <div className=" flex bg-slate-200 rounded-xl border overflow-x-scroll p-4 scroll-style " >
      <PhotoProvider className=" flex gap-4" >
        
        {
          data.length > 0 ? 
          data.map(e => (
            <PhotoView src={e}>
              <img className=" w-20 h-20 m-2 cursor-pointer hover:border-sky-200  border-4 duration-300 ease-linear rounded-md"  src={e} alt='' />
            </PhotoView>
          ))
          :
          <Empty/>
        }
        {
          loading && <LoadingScreen/>
        }
      </PhotoProvider>
    </div>
  )
}



function Thumbnail({ src = null  , alt = '' })
{
  return(
    <img className=" w-20 h-20 m-2 cursor-pointer hover:border-sky-200  border-4 duration-300 ease-linear rounded-md"  src={src} alt={alt} />
  )
}

