import {  useRef, useState } from "react"
import { IoChevronUpSharp } from "react-icons/io5";
import { model } from "../../../../model";
import {dialogEvents} from '../../../events'
import { TableRowHeader , TableCell , TableStatusCell , TableMultiCell } from "./tableAsset";



export default function SuppliesMainTable({stander , data , setAgent})
{
  const [sort,setSort] = useState('name');
  const [goBack,setGoBack] = useState('go');
  
  
  const toSort = (a , b) =>
  {
    if(goBack == 'go')
    {
      // console.log(sort)
      if(sort == "total"){
        
        return (model.getTotalGain(a) > model.getTotalGain(b) ? 1 : -1)
      }
      else
      if(sort == "gainPercentage"){
        return (model.getGainPercentage(a) > model.getGainPercentage(b) ? 1 : -1)
      }
      else
      return (a[sort] > b[sort] ? 1 : -1)
    }
    else
    {
      if(sort == "total"){
        
        return (model.getTotal(a) < model.getTotal(b) ? 1 : -1)
      }
      else
      // console.log(goBack)
      return (a[sort] < b[sort] ? 1 : -1)  
    }
  }


  return (
    <div>
      <table>
        <TableRowHeader goBack={goBack} sort={sort} goBackMethod={setGoBack} sortMethod={setSort} element={stander}/>
        
        {
        
          data
          .sort((a,b) => toSort(a,b) )
          .map((user) => (
            <MainTableRow setAgent={setAgent} element={user}/>
          )) ||
           <tr>
            لا توجد بيانات
          </tr>
        }
      </table>

    </div>
  )
}




function MainTableRow({element })
{
  
  const meRef = useRef(null);
  // useShowDialogEvent(meRef, e => console.log(e.detail));
  const handleInput = (e) => {
    dialogEvents.suppliesData(element.id);
  }
  return(
    <tr 
    ref={meRef}
    onClick={handleInput}
    className="even:bg-gray-200 first-of-type:sticky top-0
    z-20
    border-slate-300
    hover:first-of-type:bg-slate-100
    first-of-type:bg-slate-100
    first-of-type:shadow-lg
    first-of-type:border-none
    first-of-type:hover:cursor-auto  border-b last-of-type:border-none  hover:cursor-pointer hover:bg-sky-200">
      
      < TableCell text =  {element.id } />
      < TableCell text =  {element.name } sticky={true} />
      < TableCell text = {element.cost}/>
      < TableCell text = {element.wholesale}/>
      < TableCell text = {element.count}/>
      < TableCell text = { model.getGainPercentage(element)}/>
      < TableCell text = { model.getTotalGain(element) }/>
      < TableCell text = {model.dateToText(element.addDate)}/>
      < TableCell text = {model.dateToText(element.expDate)}/>

    </tr> 
  ) 

}


