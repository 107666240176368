
import Button from "../../../asset/button";
import Container from "../../../container";
import { clientModel } from "../../../../../model/client";
import { model } from "../../../../../model";
import { updateDataEvent } from "../../../../events";
import { employeeModel } from "../../../../../model/employee";
import { PhoneButton, VisitAddressLine, VisitCancelReasonLine, VisitClientName, VisitCommentLine, VisitEmployeeName, VisitSalary } from "./assets";
import { useEffect, useState } from "react";
import { visitModel } from "../../../../../model/visit";
import TextField from "../../../asset/textField";
import TextArea from "../../../asset/textArea";
import { PopDialog } from "../../../asset/popDialog";
import { useUpdateData } from "../../../../hooks/events";


export
function VisitAdminCard({visit = {}})
{
  
  const [client , setClient] = useState({});
  const [employee , setEmployee] = useState({});
  const [showPop , setShowPop] = useState(false);
  const [cancelReason , setCancelReason] = useState();
  const [loading , setLoading] = useState(false);

  useUpdateData(() => {
    clientModel.getById(visit.clientId , setClient)
    employeeModel.getByUserId(visit.employeeId , setEmployee)
  })
  useEffect(() => {
    clientModel.getById(visit.clientId , setClient)
    employeeModel.getByUserId(visit.employeeId , setEmployee)
  } , [visit])
  
  const handleDone = async () => {
    setLoading(true);
    await visitModel.done(visit.id);
    updateDataEvent();
    setLoading(false);
  }
  const handleCancel = async (e) => {
    setLoading(true);
    const res = await visitModel.cancel(visit.id , cancelReason );
    console.log(res.status)
    setLoading(false);
    setShowPop(false);
  }

  const
  handleBackStep = async () => {
    setLoading(true);
    await visitModel.updateBackStep(visit.id)
    updateDataEvent()
    setLoading(false);
    return
  }
  const
  handleCollected = async () => {
    setLoading(true);
    await visitModel.collected(visit.id)
    updateDataEvent()
    setLoading(false);
    return
  }

  

  return visit.clientId && ( 
    <Container
      title={
        <div className=" flex justify-between">
          <VisitClientName clientName={client.name} />
        </div>
      }
      title2={
        <div className=" flex justify-between">
          <div className="flex gap-2">
            <div className=" text-lg">
              {
                model.visitStatus(visit)
              }
            </div>
            {
              visit.status == 'done' &&
              <div className=" text-lg">
                {
                  model.visitCollected(visit)
                }
              </div>
            }
          </div>
          <VisitSalary visit={visit}/>
        </div>
        
      }
      header={
        <div className=" flex items-center text-lg justify-between">
            <span className=" text-lg">
            {
              model.dateToText(visit.date)
            }
          </span>
          {  model.timeToText(visit.time)}
        </div>
        
      } 
      footer={
        <>
          {
            <div className="flex justify-around gap-4 border-t-2 w-full pt-2 ">
              {
                visit.status == "waiting" &&
                <>
                {
                  new Date().toLocaleDateString() == new Date(visit.date).toLocaleDateString() &&
                  <Button loading = {loading} OnClick={handleDone} type={'button'} >
                    {
                      "زيارة مكتملة"
                    }
                  </Button>
                }
                  <Button loading = {loading} OnClick={() => setShowPop(true)} secondary type={'button'} >
                    {
                      "الغاء الزيارة"
                    }
                  </Button>
                </>
              }
              {
                visit.status == "canceled" &&
                <>
                  <Button loading = {loading} OnClick={handleBackStep} type={'button'} >
                    {
                      "استعادة"
                    }
                  </Button>
                </>
              }
              {
              (  visit.status == "done"  && !visit.collected ) &&
                <>
                  <Button loading = {loading} OnClick={handleCollected} type={'button'} >
                    {
                      "تم تحصيل " + (visit.cost - (visit.cost * visit.discount / 100) + visit.medicalStuff) + ' جم '
                    }
                  </Button>
                </>
              }
            </div>

          }
        </>
      }
      >
        <PopDialog setShow = {setShowPop} show={showPop} title={'إلغاء الزيارة'} onSubmit={handleCancel} >
          <TextArea name = 'cancelReason' placeholder = 'السبب' onChange = {(n , v) => setCancelReason(v)}/>
        </PopDialog>
        <div className="flex flex-col text-lg gap-3 mt-2 ">
          {
            <>
                <VisitAddressLine visit={visit} client={client} />
                <VisitCommentLine visit={visit}/>
                {
                  visit.status == 'canceled' &&
                  <VisitCancelReasonLine visit={visit}/>
                }
              <div className=" grid grid-auto-fit-xs">
                <PhoneButton phone={client.phone}/>
                <PhoneButton phone={client.phone2}/>
              </div>
            </>
          }
          <VisitEmployeeName employeeName={employee.name} userId={visit.employeeId}/>
          
        </div>
      </Container>
  )
}


export
function VisitUserCard({visit = {}})
{
  const [accept , setAccept] = useState(false);
  const [showPop , setShowPop] = useState(false);
  const [values , setValues] = useState(false);
  const [loading , setLoading] = useState(false);
  useUpdateData(()=> {
    setAccept(visit.status == 'approved' || visit.status == 'arrived')
  })
  useEffect(() => {
    setAccept(visit.status == 'approved' || visit.status == 'arrived')
  } , [visit])

  const
  handleNextStep = async () => {
    setLoading(true)
    await visitModel.updateNextStep(visit.id , values.medicalStuff)
    updateDataEvent()
    setLoading(false);
    return
  }

  const
  handleDone = async () => {
    await handleNextStep();
    setShowPop(false);
    return
  }


  const
  handleBackStep = async () => {
    setLoading(true);
    await visitModel.updateBackStep(visit.id)
    updateDataEvent()
    setLoading(false);
    return
  }

  return visit.id && ( 
    <Container
      title={
        <div className=" flex justify-between">
          <div>
            {
              model.timeToText(visit.time)
            }
          </div>
          {
            visit.status == 'done' &&
          <div>
            {
              model.visitCollected(visit)
            }
          </div>
          }
        </div>
      }
      title2={
        <div className=" flex justify-between">
          <span className=" text-lg">
            {
              model.dateToText(visit.date)
            }
          </span>

          {
            (accept || visit.status == 'done' )&&
            <VisitSalary visit={visit}/>
          }
        </div>
        
      }
      header={
        <VisitAddressLine visit={visit}/>
      } 
      footer={ !(visit.status == 'done' || visit.status == 'cancel') &&
        <div className="flex justify-around gap-4 border-t-2 w-full pt-2 ">
          {
            visit.status != 'arrived' &&
          (  new Date().toLocaleDateString() == new Date(visit.date).toLocaleDateString() &&
            <Button loading = {loading} OnClick={handleNextStep}  type={'button'} >
              {
                visit.status == 'approved' && "وصلت الوجهة"
              }
              
              {
                visit.status == 'waiting' && "قبول الزيارة"
              }
            </Button>)
            ||
            <Button loading = {loading} OnClick={() => setShowPop(true)}  type={'button'} >
              {
                 "تأكيد الزيارة"
              }
            </Button>
          }
          
            {
              (visit.status == 'arrived' || visit.status == 'approved') &&
              <Button loading = {loading} OnClick={handleBackStep} secondary type={'button'} >
                الغاء
              </Button>
            }
        </div>
      }
      >
        <div className="flex flex-col text-lg gap-4 mt-2 ">
          <PopDialog setShow={setShowPop} show = {showPop} onSubmit={handleDone} title={'تكلفة المستلزمات'} >
            <TextField name = 'medicalStuff' type = 'number' onChange ={(n , v) => setValues({[n] : v})} />
          </PopDialog>
          {
            accept &&
            <>
              <VisitClientName clientName={visit.name}/>
              <div className=" grid grid-auto-fit-xs">
                <PhoneButton phone={visit.phone}/>
                <PhoneButton phone={visit.phone2}/>
              </div>
            </>
          }
          <VisitCommentLine visit={visit}/>
        </div>
      </Container>
  )
}


