import axios from 'axios'
import Cookies from 'js-cookie'
import { model } from '..';



export
const visitModel = {
  getAll : async (setData) => {
    try 
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer +  '/visit/get_all' ,  {headers : {'access-token' : token, }})
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },

  
  getAllByClientId : async ( id ,setData) => {
    try 
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer +  '/visit/get_by_client/'+id ,  {headers : {'access-token' : token, }})
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },

  getAllByUserId : async ( id ,setData) => {
    try 
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer +  '/visit/get_by_user/'+id ,  {headers : {'access-token' : token, }})
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },

  create : async (modelData) => {
    let response = null
    const token = Cookies.get('_auth');
    console.log(modelData);
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.put( model.backEndServer +  '/visit/create' , modelData ,  {headers : {'access-token' : token, }} )
                  .then(res => {
                    // console.log(res);
                    response = res.data;
                  })
                  .catch(err => {
                    // console.log(err);
                    response = err.response.data;
                  } );
    }
    catch(err)
    {
      // console.log(err)
      response = err;
    }
    return response
  },

  cancel : async (id , cancelReason) => {
    let response = null
    const token = Cookies.get('_auth');
    console.log({id , cancelReason});
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.patch( model.backEndServer +  '/visit/cancel' , { id , cancelReason } ,  {headers : {'access-token' : token , }} )
                  .then(res => {
                    console.log(res);
                    response = res.data;
                  })
                  .catch(err => {
                    console.log(err);
                    response = err.response.data;
                  } );
    }
    catch(err)
    {
      console.log(err)
      response = err;
    }
    return response
  },

  updateNextStep : async (visitId , medicalStuff) => {
    let response = null
    const token = Cookies.get('_auth');
    // console.log(modelData);
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.patch( model.backEndServer +  '/visit/update_next_visit' , {id : visitId , medicalStuff} ,  {headers : {'access-token' : token , }} )
                  .then(res => {
                    console.log(res);
                    response = res.data;
                  })
                  .catch(err => {
                    console.log(err);
                    response = err.response.data;
                  } );
    }
    catch(err)
    {
      console.log(err)
      response = err;
    }
    return response
  },
  updateBackStep : async (visitId) => {
    let response = null
    const token = Cookies.get('_auth');
    // console.log(modelData);
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.patch( model.backEndServer +  '/visit/update_back_visit' , {id : visitId} ,  {headers : {'access-token' : token , }} )
                  .then(res => {
                    console.log(res);
                    response = res.data;
                  })
                  .catch(err => {
                    console.log(err);
                    response = err.response.data;
                  } );
    }
    catch(err)
    {
      console.log(err)
      response = err;
    }
    return response
  },
  
  done : async (visitId) => {
    let response = null
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.patch( model.backEndServer +  '/visit/done' , {id : visitId} ,  {headers : {'access-token' : token , }} )
                  .then(res => {
                    console.log(res);
                    response = res.data;
                  })
                  .catch(err => {
                    console.log(err);
                    response = err.response.data;
                  } );
    }
    catch(err)
    {
      console.log(err)
      response = err;
    }
    return response
  },

  collected : async (visitId) => {
    let response = null
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.patch( model.backEndServer +  '/visit/collected' , {id : visitId} ,  {headers : {'access-token' : token , }} )
                  .then(res => {
                    console.log(res);
                    response = res.data;
                  })
                  .catch(err => {
                    console.log(err);
                    response = err.response.data;
                  } );
    }
    catch(err)
    {
      console.log(err)
      response = err;
    }
    return response
  },

  getById : async (userId , setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer +  `/visit/get/${userId}` ,  {headers : {'access-token' : token, }} )
      .then(res => {
        console.log(res.data);
        setData(res.data);
      })
      .catch(err => {
        console.log(err);
      })
    }catch(err)
    {
      console.log(err);
      return []
    }
    return []
  },
  



}
 