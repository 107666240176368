import React, { useEffect, useState } from 'react';

import { IoIosArrowBack , IoIosArrowForward, IoIosContract, IoIosPaper, IoMdContract, IoMdMedical, IoMdPaper } from "react-icons/io";
import { Button, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import { FcAbout, FcAddDatabase, FcAddRow, FcAdvance, FcAdvertising, FcAreaChart, FcAssistant, FcCompactCamera, FcCustomerSupport, FcDataSheet, FcDatabase, FcDocument, FcHome, FcInTransit, FcList, FcManager, FcMoneyTransfer, FcNeutralDecision, FcPlus } from "react-icons/fc";
import Sider from 'antd/es/layout/Sider';
import { useShowMenuEvent } from '../../hooks/events';
import { useChangeWindowSize, useCheckBlockRoute, useGetPermissions } from '../../hooks/customHook';
import { BiArrowBack, BiCart, BiMenu, BiPaperclip, BiPaperPlane, BiPlusMedical, BiSolidChevronLeft, BiX } from 'react-icons/bi';
import { FaComputer, FaHospital } from 'react-icons/fa6';






const iconsClassName = ' text-lg md:w-6 md:h-6 '



function getItem(label, key, icon, children , type) {
  return {
    key,
    icon,
    children,
    label,
    type
  };
}
function getItemTiltle(title, icon, children , type) {
  return {
    icon,
    children,
    title,
    type
  };
}
const items = (per = {}) => {
  per.length > 0 &&
  per.forEach(e => {
    // if(e.job.includes('view'))
      console.log(e);
  })
  // console.log(per.visitUser)
  return [
      Object.keys(per).length > 0 && getItem('الرئيسية', '/', <FcHome className={iconsClassName}/> , null ,) ,
      // getItem('لوحة التحكم', '/dashboard', <FcDatabase  className={iconsClassName} />),
      per.addUser && getItem('المستخدمين', '/user', <FaComputer className={iconsClassName} /> ),
      per.addEmployee && getItem('الموظفين', '/employee', <FcAssistant className={iconsClassName} /> ),
      (per.visitAdmin || per.visitUser) && getItem('الزيارات', '/visit', <FcInTransit className={iconsClassName}/> ),
      (per.addClient || per.addStay || per.preStay)&& getItem('العملاء', '/client', <FcManager className={iconsClassName} /> ),
      // per.addStay && getItem('الأقامات', '/stay', <FcHome className={iconsClassName} />),
      (per.viewSupplies || per.addSupplies) && getItem('المخزن', '/supplies', <BiCart className={iconsClassName} />),
      per.addPricing && getItem('التسعير', '/pricing', <BiArrowBack className={iconsClassName} />),
      per.viewRequest && getItem('الطلبات', '/requests', <FcAdvertising className={iconsClassName} />),
      per.jobAdmin && getItem('الوظائف و الصلاحيات', '/jobs', <FcAbout  className={iconsClassName}/> ),
      per.addPayment && getItem('المعاملات', '/payment', <FcMoneyTransfer  className={iconsClassName}/> ),
      per.viewDeals && getItem('التعاقدات', '/deals', <FaHospital  className={iconsClassName}/> ),
      per.viewDocument && getItem('المستندات', '/docs', <FcDocument  className={iconsClassName}/>, [
        getItem('عقد الموظفين', '/docs/employee' , <IoIosPaper className={iconsClassName}/>),
        getItem('عقد العملاء', '/docs/client' , <IoIosPaper className={iconsClassName}/>),
      ]),
    ];

}

export default function NavSlide({theme = 'light'}) {
  const [collapsed, setCollapsed] = useState(false);
  const [blockArea , currentRoute] = useCheckBlockRoute();
  const [width , height] = useChangeWindowSize();
  const [screenMenu , setScreenMenu] = useState();
  const [showMenu , setShowMenu] = useState(false);



  useEffect(() => {
    if(width < 600)
      {
        setCollapsed(true)
      }
      else
      {
        setCollapsed(false);
      }
      
  },[width])
  useShowMenuEvent(()=>{
    setShowMenu(true);
    //console.log('show enu')
  })
  //console.log(showMenu)

  return !blockArea && ( width >= 430 &&
    (
      <div className=' flex flex-col max-h-screen scroll-style  overflow-y-scroll bg-white justify-between'>
        <div>
          <Logo collapsed={collapsed}/>
          <SideNavBarSolo collapsed={collapsed} theme={theme} currentRoute={currentRoute} />
        </div>
        <CollapseButton setCollapsed={setCollapsed} collapsed={collapsed} theme={theme}/>
      </div>
    ) ||
    ( showMenu &&
      <div className=' fixed top-0 left-0  items-center justify-center z-50 w-full bg-white h-full'>
        <ScreenLogo setShow={setShowMenu} />
        <ScreenBarSolo collapsed={false} theme={theme} currentRoute={currentRoute} setShowMenu={setShowMenu} />
      </div>
    )
  )
};

function CollapseButton ({setCollapsed , collapsed , theme}) 
{
  return(
    <div dir='ltr'
    className={` h-fit ${theme == 'light' ? ' bg-white  text-slate-900 ' : ' bg-slate-950  text-slate-50 '} z-20`}>
      <div className='  '>
          <Button 
            type="text"
            icon={collapsed ? <IoIosArrowBack /> : <IoIosArrowForward /> }
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width : 50,
              height : 50,
            }}
        />
      </div>
</div>
  )
}

function ScreenLogo ({setShow})
{
  return(
    <div className='flex sticky top-0 shadow-md z-20 bg-white py-3 items-center justify-around p-2'>
      <div className=' text-3xl border rounded-md shadow-sm'
        onClick={() => setShow(false)}
      >
        <BiX/>
      </div>
      <img   style={{
      fontSize: '16px',
      width: 40,
      height: 40,
    }} src="/logo.png" alt="logo" />
    </div>
  )
}
function Logo ({collapsed})
{
  return(
    <div className='flex sticky top-0 shadow-md z-20 bg-white py-3 items-center justify-around p-2'>
      <img   style={{
      fontSize: '16px',
      width: 40,
      height: 40,
    }} src="/logo.png" alt="logo" />
  
    <span className={` ${collapsed && ' absolute opacity-0 hidden '} ease-linear  opacity-100 `}>بيت التمريض</span>    
</div>
  )
}

export
function SideNavBarSolo({collapsed , theme , currentRoute })
{
  const navigate = useNavigate();
  const [show , setShow] = useState(true);
  const perm = useGetPermissions();
  return(
    <Sider 
        trigger={null} 
        collapsible 
        collapsed={collapsed} 
        theme={theme}
        >
      <div>
        <Menu 
          className='  '
          onClick={(e)=> {
            navigate(e.key);

          }}
          selectedKeys={[currentRoute]}
          mode="inline"
          theme={theme}
          inlineCollapsed={collapsed}
          items={items(perm)}
        />
      </div>
    </Sider>
  )
}

export
function ScreenBarSolo({collapsed , theme , currentRoute , setShowMenu = () => null })
{
  const navigate = useNavigate();
  const [show , setShow] = useState(true);
  const perm = useGetPermissions();
  return(
    <Sider 
        trigger={null} 
        collapsible 
        collapsed={collapsed} 
        theme={theme}
        
        >
      <div>
        <Menu 
          className='  '
          onClick={(e)=> {
            navigate(e.key);
            setShowMenu(false);
            
          }}
          selectedKeys={[currentRoute]}
          mode="inline"
          style={{
            border: 'none',
            width : '100vw'
          }}
          theme={theme}
          inlineCollapsed={collapsed}
          items={items(perm)}
        />
      </div>
    </Sider>
  )
}
