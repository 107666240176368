import { useParams } from "react-router-dom"
import { AddNewVisit } from "../dashboard/containers/addNew";
import Page from "./page";
import { FcInTransit } from "react-icons/fc";
import VisitsData, { SingleVisit } from "../dashboard/containers/data/visit";
import { useEffect, useState } from "react";
import { visitModel } from "../../model/visit";
import NewItemDialog from "../dashboard/asset/newItemDialog";



export default function Visit()
{
  const {route} = useParams();

  return(
    <Page title={'الزيارات'} Icon={<FcInTransit/>}>
      <div className=" flex flex-col gap-4" >
        <NewItemDialog>
          <AddNewVisit/>
        </NewItemDialog>
      
        <VisitsData />
      
        <SingleVisit  visitId = {route} />
        
      </div>
    </Page>
  )
}