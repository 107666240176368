

export default
function Container({children , dir = 'rtl' , header , title , title2 , footer , onSubmit})
{
  return(
    <form onSubmit={(e) => onSubmit(e)} >
      <div dir={dir} className="p-5 bg-white gap-4 
      sm:w-fit  w-[80vw] text-sm sm:text-lg border-2 
      rounded-2xl flex flex-col overflow-hidden ">
      <div>
        {
          title && 
          <div className=" text-whte border-r-8 border-blue-500 p-1 pr-5
            translate-x-5 text-xl 
          ">
          {
            title
          }
          </div>
        }
        <div >
          {
            title2
          }
        </div>
      </div>
      <div>
        <div >
          {
            header
          }
        </div>
        <div className=" 
          grid grid-auto-fit gap-4 place-items-right w-full " >
            {
              children 
            }
        </div>
      </div>
        <div className="  max-fit w-full grid grid-auto-fit-xs gap-4 place-items-center">
          {
            footer
          }
      </div>
      </div>
    </form>
  )
}