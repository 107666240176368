import { useEffect, useState } from "react"
import { BiCommentDetail, BiMoneyWithdraw, BiPhone } from "react-icons/bi"
import { CiLocationOn, CiMedicalCase } from "react-icons/ci"
import { FcAddressBook, FcCommandLine, FcComments, FcFeedback, FcLikePlaceholder, FcLockLandscape, FcManager, FcTemplate } from "react-icons/fc"
import { IoCalendarClear, IoPerson } from "react-icons/io5"
import { Link } from "react-router-dom"
import Button from "../../../asset/button"
import TextField from "../../../asset/textField"
import SelectBox from "../../../asset/selectBox"
import { model } from "../../../../../model"

export
function VisitSalary({visit})
{
  return(
    <div>
      <div className="flex flex-col items-center">
        <span className=" text-xl font-bold flex items-center gap-2 text-sky-500">
          <div className="">
            <BiMoneyWithdraw/>
          </div>
          {
            visit.cost - (visit.cost * visit.discount / 100)
          } جم
        </span>
        <span className=" line-through text-lg " >
          {
            visit.cost 
          } جم
        </span>
      </div>
      {
        visit.medicalStuff ?
        <div className=" flex gap-2 items-center justify-center text-lg text-purple-700">
          <CiMedicalCase/>
          <span>
            {
              visit.medicalStuff
            }
          </span>
          <span>
            جم
          </span>
        </div> : ''
      }
    </div>

  )
}


export
function VisitCancelReasonLine({visit , client})
{
  return( visit.cancelReason &&
    <div className=" border relative border-sky-500 rounded-md p-1 pt-3 " >
      <span className=" text-sky-500 font-bold absolute -translate-y-6 w-7 bg-white">
        <FcFeedback className=" w-full h-full"/>
      </span>
      <span>
            {
              visit.cancelReason
            }
        </span>
    </div>
  )
}

export
function VisitCommentLine({visit , client})
{
  return(
    <div className=" border relative border-sky-500 rounded-md p-1 pt-3 " >
      <span className=" text-sky-500 font-bold absolute -translate-y-6 w-7 bg-white">
        <FcTemplate className=" w-full h-full"/>
      </span>
      <span>
            {
              visit.comment
            }
        </span>
    </div>
  )
}

export
function VisitAddressLine({visit , client})
{
  return(
    <div className=" border-2 relative border-sky-500 rounded-md p-1 pt-3 " >
      <span className=" text-sky-500 font-bold absolute -translate-y-6 w-7 bg-white">
        <CiLocationOn className=" w-full h-full"/>
      </span>
      <span>
            {
              model.getVisitAddress(client , visit)
            }
        </span>
    </div>
  )
}

export
function VisitClientName({clientName})
{
  return(
    <div className=" flex items-center gap-2">
      <div className="">
        <FcManager/>
      </div>
      {
        clientName
      }
    </div>
  )
}
export
function VisitEmployeeName({employeeName , userId})
{
  return( employeeName &&
    <Link to={'/visit/'+userId}>
      <div className=" flex items-center gap-2">
        <div className="">
          <IoPerson/>
        </div>
        {
          employeeName
        }
      </div>
    </Link>
  )
}


export
function PhoneButton({phone})
{
  return phone != 0 && ( 
    <div className="  m-1 w-fit " >
      <a href={"tel:+20" + phone} 
      className=" flex items-center gap-4 p-2 border rounded-lg shadow-sm bg-sky-300
       hover:bg-sky-200 hover:text-blue-600 " >
        <div>
          <BiPhone/>
        </div>
        <div>
          +20{phone}
        </div>
      </a>
    </div>
  )
}


export
function VisitsDataHeader({setDate , setStatus , today , date , visitsNumber})
{
  const [yse , setYes] = useState();
  const [tomo , setTomo] = useState();
  const [dateToPicker , setDateToPicker] = useState();
  useEffect(()=>{
    let yesterday = new Date(today)
    yesterday.setDate(yesterday.getDate() - 1)
    let tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    setYes(yesterday.toLocaleDateString())
    setTomo(tomorrow.toLocaleDateString());
    let ddd = new Date(date);
    ddd.setDate(ddd.getDate() + 1)
    try
    {
      setDateToPicker(ddd.toISOString().substring(0 , 10))
    }catch{}
  },[date])


  

  return(
    <div className="sticky -top-5 w-full justify-center border p-2 overflow-hidden   bg-white shadow-md z-20 gap-4 flex flex-col">
      <div className=" flex gap-4 w-full justify-around">
        <TextField value = {dateToPicker} type = 'date'  onChange = {(n , v) => setDate(new Date(v).toLocaleDateString())} />
        <SelectBox onChange = {(n , v) => {setStatus(v)}} name = 'status'  options ={{[false] : 'الجميع', waiting : 'انتظار' , approved : 'في الطريق', arrived : 'وصل الحالة' ,  done : 'تمت' , canceled : 'ملغية'}} />
      </div>
      <div className=" flex gap-4  h-fit items-center justify-around  ">
        <Button  OnClick={()=> setDate(yse)}  secondary disabled={date == yse } >
          امس
        </Button>
        <Button   OnClick={()=> setDate(today.toLocaleDateString())} secondary disabled={date == today.toLocaleDateString()} >
          اليوم
        </Button>
        <Button  OnClick={()=> setDate(tomo)}  secondary disabled={date == tomo } >
          غدا
        </Button>
        <Button  OnClick={()=> setDate(false)}  secondary disabled={date == false} >
          جميع الأيام
        </Button>
      </div>
      {
        visitsNumber ?
        <div className="flex gap-4" >
          <span>
            {
              visitsNumber
            }
          </span>
          <span>
            زيارة متاحة
          </span>
        </div>
        :
        <div>
          لا توجد زيارات متاحة
        </div>
      }
    </div>
  )
}

export
function VisitsDataUserHeader({setDate , setStatus , today , date , focusMood = false})
{
  
  const [focus , setFocus] = useState();
  const [tomo , setTomo] = useState();
  const [dateToPicker , setDateToPicker] = useState();
  useEffect(()=>{
    let tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    setTomo(tomorrow.toLocaleDateString());
    let ddd = new Date(date);
    ddd.setDate(ddd.getDate() + 1)
    try
    {
      setDateToPicker(ddd.toISOString().substring(0 , 10))
    }catch{}
  },[date])

  const
  handleSetDate = (d)=>
    {
      setStatus('waiting');
      if(d == 'today')
        {
          setDate(today.toLocaleDateString());
          setFocus('today');
        }
        else 
        {
          setDate(tomo);
          setFocus('tomorrow')
        }
    }
    const
    handleSetStatus = () => {
      setDate(false)
      setStatus('done');
      setFocus('done');
  }

  
  return( !focusMood &&
    <div className="sticky -top-5 w-full justify-center border p-2 overflow-hidden   bg-white shadow-md z-20 gap-4 flex flex-col">
      
      <div className=" flex gap-4  h-fit items-center justify-around  ">
        <Button OnClick={() => handleSetStatus()} type={'button'} disabled={focus == 'done'}  >
          {
            "زيارات تمت "
          }
        </Button>
        <Button   OnClick={()=> handleSetDate('today')} secondary disabled={focus == 'today'} >
          اليوم
        </Button>
        <Button  OnClick={()=> handleSetDate('tomorrow')}  secondary disabled={focus == 'tomorrow'} >
          غدا
        </Button>
        
      </div>
      <div>

      </div>
    </div>
  )
}