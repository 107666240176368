import { useEffect, useState } from "react"
import CheckBoxField, { CheckBox } from "../dashboard/asset/checkBox"
import { BiSelectMultiple, BiSelection } from "react-icons/bi"

export const availableJobs = {
  '' : null,
  nurse : 'ممرض' ,
  nurseAid: 'مساعد تمريض',
  elderlySitter: 'مرافقة مسنين',
  babySitter: 'مرافقة اطفال',
  callCenter: ' خدمة عملاء ',
  salesMan: 'مندوب',
  secretary: 'سكرتاريا',
}



const skills = {
  standard : {
    'computer' : ' التعامل مع الكمبيوتر و الهاتف ',
    'english' : ' اجادة الأنجليزية ',
    'motorcycleDrive' : ' قيادة الدراجة النارية ',
    'carDrive' : ' قيادة السيارة ',
    'elderlyCare' : ' رعاية كبار السن ',
    'babyCare' : ' رعاية الأطفال ',
    'disabledCare' : ' رعاية زوي الهمم ',
    'canula' : ' كانيولا ',
    'catheter' : ' رايل ',
    'ryle' : ' قسطرة ',
    'care' : ' عمل كير ',
    'overweight' : ' التعامل مع الأوزان العالية ',
    'woundCare' : ' الغيار علي الجروح ',
  },
  callCenter : [
    'computer',
    'english',
  ],

  secretary : [
    'computer',
    'english',
  ],
  salesMan : [
    'computer',
    'english',
    'motorcycleDrive',
    'carDrive',
  ],
  elderlySitter : [
    'elderlyCare',
    'babyCare',
    'disabledCare',
    'care',
    'overweight',
    'woundCare',
  ],

  babySitter : [
    'elderlyCare',
    'babyCare',
    'disabledCare',
    'care',
    'overweight',
    'woundCare',
  ],
  
  nurse : [
    'elderlyCare',
    'babyCare',
    'disabledCare',
    'canula',
    'catheter',
    'ryle',
    'care',
    'overweight',
    'woundCare',
    'motorcycleDrive',
  ],
  nurseAid : [
    'elderlyCare',
    'babyCare',
    'disabledCare',
    'canula',
    'catheter',
    'ryle',
    'care',
    'overweight',
    'woundCare',
    'motorcycleDrive',
  ],

}





export function JobHandler({inputValues , values = {} , visible = false})
{
  const [mySkills , setMySkills] = useState({});
  const addSkillHandler = (n , value) => {
    setMySkills(prev => ({...prev , [n] : value}))
  }
  useEffect(() => {
    setMySkills({});
  },[values.job])
  useEffect(() => {
    console.log(mySkills)
    inputValues('skills' , mySkills)
  } , [mySkills])



  return( visible &&
    <div>
      <div className=" flex flex-col gap-4 items-center ">
        <div className=" border px-2 py-1 rounded-lg shadow-lg">
          <span className=" text-sky-600  mr-2 font-bold text-xl " >
            {
              availableJobs[values.job]
            }
          </span>
        </div>
      <div className=" w-full justify-between text-sky-500 text-lg flex items-center gap-4 ">
        <div>
          <span>
          اختر مهاراتك  
          </span>
        </div>
        <span>
          <BiSelectMultiple/>
        </span>
      </div>
      <div>
        {
          
          skills[values.job].length &&
            skills[values.job].map(e => (<CheckBox onChange={addSkillHandler} name={e} selected={values.skills[e]} >
              {
                skills.standard[e]
              }
            </CheckBox>))
        }
      </div>
    </div>
    </div>
  )
}



