import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Home from "../pages/home";
import LogInPage from "../pages/auth/login";
import Dashboard from '../dashboard';
import { useEffect, useRef, useState } from "react";
import { pageNameEvent } from "../events";
import Employee from "../pages/employee";
import Client from "../pages/client";
import Visit from "../pages/visit";
import Stay from "../pages/stay";
import RequireAuth from '@auth-kit/react-router/RequireAuth';
import Account from "../pages/account";
import Documents from "../pages/documents";
import { Applicant } from "../pages/applicant";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { customDialogsEvents } from "../hooks/events";
import JobAndPermissions from "../pages/job";
import Inbox from "../pages/inbox";
import FilterVisit from "../pages/filterVisit";
import SuppliesPage from "../pages/supplies";
import SearchPage from "../pages/search";
import PricingPage from "../pages/pricing";
import SettingPage from "../pages/setting";
import RequestsPage from "../pages/requests";
import { NotAvailable } from "../dashboard/asset/notAvailable";
import DealsPage from "../pages/deals";
import PaymentPage from "../pages/payment";
import UserPage from "../pages/userPage";
import { useDelay } from "../hooks/customHook";
import { userModel } from "../../model/user";
import { io } from 'socket.io-client'
import { model } from "../../model";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import { sendNotification } from "../navigations/header/notification";



export default
function RouterHandler()
{
  const location = useLocation();
  const ref = useRef();
  const [auth , setAuth] = useState({});
  
  const signOut = useSignOut();
  const navigator = useNavigate();
  const doSignOut = () =>
  {
    signOut()
    navigator('/login')
  }

  const loadData = async () => {
    await
    userModel.checkAuth(setAuth);
  }

  useEffect(() => {
    loadData();
  } , [])

  const socket = io(model.backEndServer , {transports : ["websocket"]});
  socket.on("new_notification", (data) => {
    sendNotification()
  });
  socket.on("logout", (data) => {
    // console.log(auth.userId , data.targetId);
    if((auth.userId == data.targetId) || data.targetId === 'all')
    {
      // console.log('you have to logout right now' , data.message , data.targetId , auth.userId);
      doSignOut();
    }
  });


  useDelay(() => {
    userModel.setOnline();
  } , 20 )


  const goToUpPage = () => {
    try{
      console.log(ref);
      ref.current.scrollTo({
        top : 0 ,
        left : 0,
        behavior:'smooth'
      })
  
    }catch(err){}
  }
  customDialogsEvents.useEmployee(()=>{
    goToUpPage();
  })
  customDialogsEvents.useSupplies(()=>{
    goToUpPage();
  })

  pageNameEvent(location.pathname == '/home' ? '/' : location.pathname);
  useEffect(()=>{
    pageNameEvent(location.pathname == '/home' ? '/' : location.pathname);
  },[])
  return(
    <div ref={ref} className=" h-full overflow-scroll scroll-style">
      <Routes>
        <Route path='/' element ={
          <RequireAuth fallbackPath = "/login">
            <Home/>    
          </RequireAuth>
        } />
        <Route path='/home' element ={
          <RequireAuth fallbackPath = "/login">
            <Home/>    
          </RequireAuth>
        } />
        <Route path='/home/home' element ={
          <RequireAuth fallbackPath = "/login">
            <Home/>    
          </RequireAuth>
        } />
        <Route path='/home/home/home' element ={
          <RequireAuth fallbackPath = "/login">
            <Home/>    
          </RequireAuth>
        } />
        
        <Route path='/dashboard' element ={
            <Dashboard/>
        }  />


        <Route path='/user/:route' element ={
          <RequireAuth fallbackPath="/login">
            <UserPage/>
          </RequireAuth>
        }  />
        <Route path='/user' element ={
          <RequireAuth fallbackPath="/login">
            <UserPage/>
         </RequireAuth>
        }  />
        <Route path='/payment' element ={
          <RequireAuth fallbackPath="/login">
            <PaymentPage/>
          </RequireAuth>
        }  />
        <Route path='/payment/:route' element ={
          <RequireAuth fallbackPath="/login">
            <PaymentPage/>
          </RequireAuth>
        }  />
        <Route path='/settings' element ={
          <RequireAuth fallbackPath="/login">
            <SettingPage/>
          </RequireAuth>
        }  />
        <Route path='/deals' element ={
          <RequireAuth fallbackPath="/login">
            <DealsPage/>
          </RequireAuth>
        }  />
        <Route path='/deals/:route' element ={
          <RequireAuth fallbackPath="/login">
            <DealsPage/>
          </RequireAuth>
        }  />


        <Route path='/requests/:route' element ={
          <RequireAuth fallbackPath="/login">
            <RequestsPage/>
          </RequireAuth>
        }  />
        <Route path='/requests' element ={
          <RequireAuth fallbackPath="/login">
            <RequestsPage/>
          </RequireAuth>
        }  />


        <Route path='/employee/:route' element ={
          <RequireAuth fallbackPath="/login">
            <Employee/>
          </RequireAuth>
        } />
        <Route path='/employee' element ={
          <RequireAuth fallbackPath="/login">
            <Employee/>
          </RequireAuth>
        } />


        <Route path='/pricing' element ={
          <RequireAuth fallbackPath="/login">
            <PricingPage/>
          </RequireAuth>
        } />
        <Route path='/pricing/:route' element ={
          <RequireAuth fallbackPath="/login">
            <PricingPage/>
          </RequireAuth>
        } />


        <Route path='/supplies' element ={
          <RequireAuth fallbackPath="/login">
            <SuppliesPage/>
          </RequireAuth>
        } />
        <Route path='/supplies/:route' element ={
          <RequireAuth fallbackPath="/login">
            <SuppliesPage/>
          </RequireAuth>
        } />


        <Route path='/client/:route' element ={
          <RequireAuth fallbackPath="/login">
            <Client/>
          </RequireAuth>
        } />
        <Route path='/client' element ={
          <RequireAuth fallbackPath="/login">
            <Client/>
          </RequireAuth>
        } />


        <Route path='/visit' element ={
          <RequireAuth fallbackPath="/login">
            <Visit/>
          </RequireAuth>
        } />
        <Route path='/visit/:target/:id' element ={
          <RequireAuth fallbackPath="/login">
            <FilterVisit/>
          </RequireAuth>
        } />

        
        <Route path='/stay/:route' element ={
          <RequireAuth fallbackPath="/login">
            <Stay/>
          </RequireAuth>
        } />
        <Route path='/stay' element ={
          <RequireAuth fallbackPath="/login">
            <Stay/>
          </RequireAuth>
        } />


        <Route path='/search/:route' element ={
          <RequireAuth fallbackPath="/login">
            <SearchPage/>
          </RequireAuth>
        } />
        


        <Route path='/account' element ={
          <RequireAuth fallbackPath="/login">
            <Account/>
          </RequireAuth>
        } />


        <Route path='/Docs' element ={
          <RequireAuth fallbackPath="/login">
            <Documents/>
          </RequireAuth>
        } />
        <Route path='/Docs/:route/:id' element ={
          <RequireAuth fallbackPath="/login">
            <Documents/>
          </RequireAuth>
        } />
        <Route path='/Docs/:route' element ={
          <RequireAuth fallbackPath="/login">
            <Documents/>
          </RequireAuth>
        } />


        <Route path='/jobs/:route' element ={
          <RequireAuth fallbackPath="/login">
            <JobAndPermissions/>
          </RequireAuth>
        } />
        <Route path='/jobs' element ={
          <RequireAuth fallbackPath="/login">
            <JobAndPermissions/>
          </RequireAuth>
        } />


        <Route path='/inbox' element ={
          <RequireAuth fallbackPath="/login">
            <Inbox/>
          </RequireAuth>
        } />

        <Route path='/login' element ={<LogInPage/>} />
        <Route path='/applicant' element ={<Applicant/>} />

        <Route path='*' element ={
          <RequireAuth fallbackPath="/login">
            <> 
              <NotAvailable/>
            </>
          </RequireAuth>
        } />

      </Routes>
    </div>
  )
}

