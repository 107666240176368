
import axios from 'axios'
import Cookies from 'js-cookie'
import { model } from '../'


export
const clientModel = {
  create : async (createData) => {
    let response = null
    
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.put(model.backEndServer + '/client/create' , createData  ,  {headers : {'access-token' : token, }})
                  .then(res => {
                    // //console.log(res);
                    response = res;
                  })
                  .catch(err => {
                    // //console.log(err);
                    response = err.response;
                  } );
    }
    catch(err)
    {
      // //console.log(err)
      response = err;
    }
    return response
  },
  getAll : async (setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get(model.backEndServer + '/client/get_all' ,  {headers : {'access-token' : token, }} )
      .then(res => {
        // //console.log(res.data);
        setData(res.data);
      })
      .catch(err => 
        {
          //console.log(err)
        })
    }catch(err)
    {
      //console.log(err);
      return []
    }
    return []
  },

  getById : async (userId , setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get(model.backEndServer + `/client/get/${userId}` ,  {headers : {'access-token' : token, }})
      .then(res => {
        // //console.log(res.data);
        setData(res.data);
      })
      .catch(err => {
        // //console.log(err);
      })
    }catch(err)
    {
      // //console.log(err);
      return []
    }
    return []
  },


  updateMainData : async (model) => {
    let response = null
    const token = Cookies.get('_auth');
    try
    {
      const data = await axios.patch(model.backEndServer + '/client/update_main_data' , model ,  {headers : {'access-token' : token, }})
        .then(res => {
          console.log(res);
          response = res.data;
        })
        .catch(err => {
          console.log(err);
          response = err.response;
        } );
    }
    catch(err)
    {
      console.log(err)
      response = err;
    }
    return response
  },








  MainStandardTable : {
    id: "#" ,
    name: 'الأسم' ,
    phone: "الهاتف",
    phone2: "الهاتف",
    address : 'العنوان',
    famousPlace : 'مكان مشهور',
  },


}
 