import axios from 'axios'
import Cookies from 'js-cookie'
import { model } from '..';


export
const priceModel = {
  getAll : async (setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer +  '/pricing/get_all' ,  {headers : {'access-token' : token, }})
      .then(res => {
        console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },
  getAllSections : async (setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer +  '/pricing/get_all_section' ,  {headers : {'access-token' : token, }})
      .then(res => {
        console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },
  getAllType : async (setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer +  '/pricing/get_all_type' ,  {headers : {'access-token' : token, }})
      .then(res => {
        console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },
  getAllArea : async (setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer +  '/pricing/get_all_area' ,  {headers : {'access-token' : token, }})
      .then(res => {
        console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },
  getById : async (id , setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer +  '/pricing/get/'+id ,  {headers : {'access-token' : token, }})
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },


  create : async (modelData) => {
    let response = null
    const token = false;
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.put( model.backEndServer +  '/pricing/create' , modelData ,  {headers : {'access-token' : token, }} )
                  .then(res => {
                    // console.log(res);
                    response = res;
                  })
                  .catch(err => {
                    // console.log(err);
                    response = err.response;
                  } );
    }
    catch(err)
    {
      // console.log(err)
      response = err;
    }
    return response
  },
  update : async (modelData) => {
    let response = null
    const token = false;
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.patch( model.backEndServer +  '/pricing/update' , modelData ,  {headers : {'access-token' : token, }} )
                  .then(res => {
                    // console.log(res);
                    response = res.data;
                  })
                  .catch(err => {
                    // console.log(err);
                    response = err.response.data;
                  } );
    }
    catch(err)
    {
      // console.log(err)
      response = err;
    }
    return response
  },


  standard : {
    id : '#',
    area : 'المنطقة',
    section : 'القسم',
    type: 'النوع',
    price : 'السعر',
    
  },

  

}
 