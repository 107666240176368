import { BiCheckCircle, BiError } from "react-icons/bi";
import SelectBox from "../../dashboard/asset/selectBox";
import TextField from "../../dashboard/asset/textField";
import { JobHandler, availableJobs } from "../assets";
import { CheckBox } from "../../dashboard/asset/checkBox";



export default
function ApplicantBody({inputValues = {} , pageNumber = 0 ,  status = null , values = {} , setImages })
{
  
  return(
    <div id="pageContainer" className=" py-5   relative h-fit overflow-hidden">
        <div className={`  w-fit flex items-center gap-[15vw] pr-[15vw] ease-linear duration-300 ${ pageNumber == 1 ? ' translate-x-1/4 ' : pageNumber == 2 ? ' translate-x-2/4 ' : pageNumber == 3 ? ' translate-x-3/4 ' : ''}  `}>
          <Page1 pageNumber={pageNumber} inputValues={inputValues} />
          {
            status == 'error' || !status ?
            <ErrorPage/> : null
          }
          {
            status == 'found' ?
            <FoundPage/> : null
          }
          
          {
            status == 'notFound' ?
            <Page2 pageNumber={pageNumber} inputValues={inputValues}/>
            :
            null
          }
          <Page3 inputValues={inputValues} values={values} visible={pageNumber > 1 }  />
          <Page4 inputValues={inputValues} setImages = {setImages} />

        </div>
      </div>
  )
}



function Page({children})
{
  return(
    <div className=" flex flex-col gap-10 w-[70vw]  ">
      {
        children
      }
    </div>
  )
}

function Page1({pageNumber , inputValues})
{
  return(
    <Page>
          
      <div className=" flex w-full justify-between items-center">
        <div className=" w-fit border-2 rounded-lg p-2">
          +20
        </div>
        <TextField disabled = {pageNumber != 0 } onChange = {inputValues} name = 'phone' placeholder = "رقم الهاتف"/>
      </div>
      
      <TextField  disabled = {pageNumber != 0 } onChange = {inputValues} name = 'cardId' placeholder = "الرقم القومي"/>
  
    </Page>
  )
}

function Page2({inputValues , pageNumber})
{
  return(
    <Page>
      <TextField disabled = {pageNumber != 1 } onChange = {inputValues} name = 'name' placeholder = "الأسم"/>
      <TextField disabled = {pageNumber != 1 } onChange = {inputValues} name = 'address' placeholder = "العنوان"/>
      <div className=" flex w-full justify-between items-center">
        <div className=" w-fit border-2 rounded-lg p-2">
          +20
        </div>
        <TextField disabled = {pageNumber != 1 } onChange = {inputValues} name = 'phone2' placeholder = "رقم هاتف اخر"/>
      </div>
      <SelectBox disabled = {pageNumber != 1 } onChange = {inputValues} name = 'job' placeholder = 'الوظيفة' options = {availableJobs}  />
    </Page>
  )
}

function Page3({inputValues , values = {} , visible})
{
  /// available jobs is 7 jobs
  return(
    <Page>
      <JobHandler inputValues={inputValues} values={values} visible = {visible} />
    </Page>
  )
}
function Page4({inputValues , setImages})
{
  
  return(
    <Page>
      <div className="flex flex-col justify-between items-center ">
        <label htmlFor="images" className=" text-lg" >
          صورة البطاقة للجانبين و الشهادات و الأوراق ذات الصلة بالوظيفة
          </label>
        <input type="file" multiple id="files" name="image"
        onChange={e => setImages(e.target.files)}
        className="block w-full text-sm text-slate-500
        border p-2 rounded-lg mt-5
        file:mr-4 file:py-2 file:px-4
        file:rounded-full file:border-0
        file:text-sm file:font-semibold mb-5
        file:bg-sky-50 file:text-sky-700
        hover:file:bg-sky-100"  />
      </div>
      <div dir="ltr" className=" text-center border py-2 rounded-lg" >
        <CheckBox onChange={inputValues} name='accepted'  >
            اوافق علي 
            الأحكام و الشروط
        </CheckBox>
        <div className="  font-bold">
          <a href="#" target="_blank" className=" underline text-sky-500 " >
             ما هي الأحكام و الشروط 
          </a>
        </div>
      </div >
    </Page>
  )
}


function FoundPage({})
{
  return(
    <Page>
      <div className=" flex flex-col items-center justify-center text-center text-lg " >
        <div className=" text-6xl text-green-400" >
          <BiCheckCircle/>
        </div>
        <div>
          لقد قمت بالتسجيل من قبل سيتم التواصل معك في اقرب وقت ممكن
        </div>
        <span className=" text-purple-500">
          شكراً لك
        </span>
      </div>
    </Page>
  )
}

function ErrorPage({})
{
  return(
    <Page>
      <div className=" flex flex-col items-center justify-center text-center text-lg " >
        <div className=" text-6xl text-red-400" >
          <BiError/>
        </div>
        <div>
          حدث خطأ اثناء الإرسال يمكنك معاودة الطلب في وقت لاحق
        </div>
        <span className=" text-purple-500">
          شكراً لك
        </span>
      </div>
    </Page>
  )
}