
import { Header } from 'antd/es/layout/layout';
import { useChangeWindowSize, useCheckBlockRoute } from '../../hooks/customHook';
import { MdClose, MdNotifications, MdSearch, MdTextFields } from 'react-icons/md';
import { AccountDialog, HeaderBtn, MobileMenu, NavDropBtn } from './assets';
import { BiSearchAlt, BiUser } from 'react-icons/bi';
import { NotificationDialog } from './notification';
import { useEffect, useState } from 'react';
import { FcSearch } from 'react-icons/fc';
import TextField from '../../dashboard/asset/textField'
import { useNavigate, useNavigation } from 'react-router-dom';





function NavHeader() 
{
  const [blockArea , currentRoute ] = useCheckBlockRoute();
  const [notifyNo , setNotifyNo ] = useState();
  const navigator = useNavigate()
  const [showSearchBar , setShowSearchBar ] = useState();
  const [width , height] = useChangeWindowSize(); /// break number is 430 
  const [values , setValues] = useState({})

  useEffect(() => {
    document.addEventListener('keydown', function(event) {
      if (event.ctrlKey && event.key === 'z') {
        console.log('ctrl + Z pressed');
        alert()
      }
    });
    return () => document.removeEventListener('keydown', () => {});
  },[])

  const focusOut = () => {
    setShowSearchBar(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    navigator('/search/'+ values.search )
  }
  
  const handleInput = (n , v) => {
    setValues(prev => ({...prev , [n] : v }));
  }
  


  return( !blockArea &&
    <div>
      <Header dir='ltr' className=' bg-blue-500 shadow-lg z-20 flex justify-between items-center  ' >
        <div className=' flex items-center gap-4'>
          <NavDropBtn Btn={HeaderBtn} icon={<BiUser/>} Dialog={AccountDialog} />
          <NavDropBtn Btn={HeaderBtn} icon={<MdNotifications/>} Dialog={NotificationDialog} notiNo={notifyNo} setNotifyNo={setNotifyNo} />
          {
            width < 430 &&
            <div className=' sm:hidden'>
              <HeaderBtn onClick={() => setShowSearchBar(true) } icon = {<MdSearch/>} />
            </div>
          }
        </div>
        <form onSubmit={handleSubmit} className='' >
          <div className={` ${showSearchBar && width < 430 ? ' absolute w-full right-0 top-3 ' : width < 430 ? ' hidden ' : ' '}    `}>
          <div  >
              <TextField focusOut = {focusOut} name = 'search'  onChange = {handleInput}>
                <div onClick={() => setShowSearchBar(false)} className=' text-lg'>
                  { 
                    width < 430 ?
                    <MdClose/> :
                    <MdSearch/>
                  }
                </div>
              </TextField>
          </div>
          </div>
        </form>
        <MobileMenu width={width} />
      </Header>
          
    </div>
  )
}





export default NavHeader