import { useEffect, useState } from "react";
import { useCustomPageEvent } from "../components/hooks/events";
import NavSlide from "../components/navigations/sideNavigator";
import NavHeader from "../components/navigations/header";
import Layout from "antd/es/layout/layout";



export 
function BodyWithNavigation({children})
{  
  return(
    <>
      <Layout dir='rtl' >
          <NavSlide/>
          <Layout className=' h-screen'>
          <NavHeader/>
            {children}
          </Layout>
      </Layout>
    </>
  )
}

