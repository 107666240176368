
import { useEffect, useRef, useState } from "react";
import {  textValues } from "./assets";
import { BiErrorCircle } from "react-icons/bi";

/**
 * 
 * @param {style} 
 * @returns 
 */


export default
function TextArea({
  value,
  onChange = () => null,
  placeholder,
  name,
  type,
  err ,
  success ,
  children,
  title,
  empty,
  maxLength = 200
})
{
  const textRef = useRef(null)
  const [active, setActive] = useState(false);
  const [textLength, setTextLength] = useState(0);
  const FieldTitle = textValues.FieldTitle;
  const Child = textValues.ChildSection;

  const handelInput = () => {

    textRef.current && 
    setTextLength(textRef.current.value.length);
  
  }
  useEffect(() =>{
    if(empty)
    {
      textRef.current.value = null;
    }
  } , [empty])
  return (
    <div
      onClick={ () => textRef.current.focus()}>
      <FieldTitle title={placeholder || title} name={name} show={active || ( textRef.current ?  textRef.current.value : false)}/>
      <div>
        <div className={textValues.fieldClassName(active , err , success)}>
          <Child children={children} textRef={textRef} active={active}/>
          <textarea
            maxLength={maxLength}
            ref={textRef}
            className={textValues.inputClassName}
              onInput={handelInput}
              onChange={(v) =>{onChange( name , v.target.value)} }
              onFocus={() => setActive(true)}
              onBlur={() => setActive(false)}
              type={type}
              name={name}
              id={name}
              defaultValue={value}
            />
        </div>
          <div dir="ltr" className=" relative px-5">
            <span className=" absolute -translate-y-6 text-gray-400">
              {textLength} / {maxLength}
            </span>
          </div>
        {
          err && 
          <div className=" text-red-500 flex items-center gap-3" >
              <BiErrorCircle/>
              <span >
                {
                  err
                }
              </span>
          </div>
        }
      </div>
    </div>
  )
}
