import axios from 'axios'
import Cookies from 'js-cookie'
import { model } from '..';


export
const suppliesModel = {
  getAll : async (setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer +  '/supplies/get_all' ,  {headers : {'access-token' : token, }})
      .then(res => {
        console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },
  getById : async (id , setData) => {
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.get( model.backEndServer +  '/supplies/get/'+id ,  {headers : {'access-token' : token, }})
      .then(res => {
        // console.log(res.data);
        setData(res.data);
      })
      .catch(err => console.log(err))
    }catch(err)
    {
      // console.log(err);
      return []
    }
    return []
  },


  create : async (modelData) => {
    let response = null
    const token = false;
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.put( model.backEndServer +  '/supplies/create' , modelData ,  {headers : {'access-token' : token, }} )
                  .then(res => {
                    // console.log(res);
                    response = res.data;
                  })
                  .catch(err => {
                    // console.log(err);
                    response = err.response.data;
                  } );
    }
    catch(err)
    {
      // console.log(err)
      response = err;
    }
    return response
  },
  update : async (modelData) => {
    let response = null
    const token = false;
    try
    {
      const token = Cookies.get('_auth');
      const data = await axios.patch( model.backEndServer +  '/supplies/update' , modelData ,  {headers : {'access-token' : token, }} )
                  .then(res => {
                    // console.log(res);
                    response = res.data;
                  })
                  .catch(err => {
                    // console.log(err);
                    response = err.response.data;
                  } );
    }
    catch(err)
    {
      // console.log(err)
      response = err;
    }
    return response
  },



  standard : {
    id : '#',
    name : 'اسم الصنف',
    cost : 'سعر البيع',
    wholesale: 'سعر الجملة',
    count : 'الكمية',
    gainPercentage : 'نسبة المكسب في القطعة',
    total : 'اجمالي المكسب',
    addDate : 'تاريخ الأضافة',
    expDate : 'تاريخ انتهاء الصلاحية',
  },

  

}
 