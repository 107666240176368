import { useEffect, useState } from "react";
import { TbTransfer } from "react-icons/tb";
import { SiVodafone } from "react-icons/si";
import { CiBank } from "react-icons/ci";
import { GiMoneyStack } from "react-icons/gi";
import { MdOutlineAttachMoney } from "react-icons/md";
import ScrollableDataView from "../dashboard/asset/dataView";
import Button from "../dashboard/asset/button";
import TextField from "../dashboard/asset/textField";
import { employeeModel } from "../../model/employee";
import { stayModel } from "../../model/stay";
import { model } from "../../model";
import Container from '../dashboard/container'
import FindField from '../dashboard/asset/findField'
import SelectBox from '../dashboard/asset/selectBox'
import TextArea from '../dashboard/asset/textArea'
import { LineNotify } from '../dashboard/asset/notify'
import { Field } from '../dashboard/asset/field'
import { paymentModel } from "../../model/payment";
import { useUpdateData } from "../hooks/events";
import BarLoader from "react-spinners/BarLoader";
import { updateDataEvent } from "../events";
import LoadingScreen from "../dashboard/asset/loading";
import { suppliesModel } from "../../model/supplies";

export
function GetAllAvaliableMoeny ({})
{
  const [moneyTotal ,setMoneyTotal] = useState({});
  const [loading ,setLoading] = useState(false);

  const loadData = async () => {
    setLoading(true);
    await paymentModel.getTotals(setMoneyTotal);
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  } , [])

  console.log(moneyTotal);

  useUpdateData(() => {
    loadData();
  })

  return (
    <div className="flex gap-2" >
      <ScrollableDataView>
        {
          loading && <LoadingScreen/>
        }
        {
          moneyTotal.vodafone &&
          <MoneyCard 
          icon={<div className=" text-5xl  text-red-600"><SiVodafone/> </div>}
          amount = {moneyTotal.vodafone} title = {'vodafone'} />
        }
        {
          moneyTotal.instaPay &&
          <MoneyCard 
          icon={<div className=" text-5xl  text-purple-600"><CiBank/> </div>}
          amount = {moneyTotal.instaPay} title = {'instaPay'} />
        }
        {
          moneyTotal.cash && 
          <MoneyCard 
          icon={<div className=" text-5xl  text-green-600"><GiMoneyStack/> </div>}
          amount = {moneyTotal.cash} title = {'cash'} />
        }
        <MoneyCard 
        icon={<div className=" text-5xl  text-sky-600"><MdOutlineAttachMoney/> </div>}
        amount = {moneyTotal.total || 0 } title = {'total'} />
      </ScrollableDataView>
    </div>
  )

}






export
function GetAllPayment ({})
{
  const [payments , setPayments] = useState({})
  const [loading , setLoading] = useState(false)

  const loadData = async () => {
    setLoading(true);
    await paymentModel.getAll(setPayments);
    setLoading(false);
  }

  useEffect(() =>{
    loadData();
  } , [] )
  useUpdateData(() => {
    loadData();
  })

  return(
    <ScrollableDataView>
      {
        loading && <BarLoader/>
      }
      {
        payments.length > 0 &&
        payments.map(e => (
          <ViewMoneyTransaction payment={e}/>
        ))
      }
    </ScrollableDataView>
  )
}



export
function MoneyTransaction({})
{
  const [target , setTarget] = useState('client');
  const [fildData , setFildData] = useState({});
  const [loading , setLoading] = useState(false);
  const [values , setValues] = useState({});
  const [emptyFild , setEmptyFild] = useState(0);
  const [customLog , setCustomLog] = useState({});

  const employeeActionsKeys = {
     pay : 'ايداع' , take : 'سحب' , deduct : 'خصم' , additional : 'اضافي'
  }
  const clientActionsKeys = {
      pay : 'ايداع' , take : 'سحب' , additional : 'اضافي'
  }
  const productActionKeys = {
      buy : 'شراء' , sale : 'بيع' 
  }
  const paymentMethods = {
      vodafone : 'فودافون' , instaPay : 'انستا باي' , cash : 'كاش'
  }



  useEffect(() => {
    setEmptyFild( prev => prev + 1 );
    setValues({targetSection : target })
    if(target === 'employee')
    {
      employeeModel.getAllStay(setFildData);
    }
    else if(target === 'client')
    {
      stayModel.getAll(setFildData);
    }
    else if(target === 'supplies')
    {
      suppliesModel.getAll(setFildData);
    }
  },[target])




  const handleInput = (name , value) => {
    setValues(prev => ({...prev , [name] : value}))
    setCustomLog({});
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
      console.log(values);
    setLoading(true);
      const res = await paymentModel.create(values);
      console.log(values);
      if(res.status === 200)
        {
          setEmptyFild(prev => prev+1)
          updateDataEvent();
          setCustomLog(prev => ({...prev , success : true , err : false , msg : 'تمت المعاملة بنجاح'}))
        } else
        {
          setCustomLog(prev => ({...prev , success : false , err : true }))
          if(res.data.msg)
            setCustomLog(prev => ({...prev , msg : res.data.msg }))
          else
            setCustomLog(prev => ({...prev , msg : 'حدث خطاء لم تتم المعاملة'}))
        }
    // console.log(values);
        setLoading(false);

  }


  return(
    <Container onSubmit={handleSubmit}  title={
        <div className=" flex gap-2 items-center" >
          <div className=" text-3xl rotate-45 text-sky-600">
            <TbTransfer/>
          </div>
          <div>
            {
              'معاملة جديدة'
            }
          </div>
        </div>
      
      } header={
      <div className=" flex gap-4">
        <Button secondary disabled={target === 'client'} OnClick={() => setTarget('client')}>
          عملاء
        </Button>
        <Button secondary disabled={target === 'employee'} OnClick={() => setTarget('employee')}>
          موظفين
        </Button>
        <Button secondary disabled={target === 'supplies'} OnClick={() => setTarget('supplies')}>
          سلعة
        </Button>
      </div>
    } footer={<>
      <Button type={'submit'} loading = { loading } >
        تأكيد الدفع
      </Button>
    </>} >
      <div className=" py-10 grid grid-auto-fit-sm gap-4 ">
        <FindField empty = {emptyFild} data = {fildData}  placeholder = 'الأسم' name = 'targetId'  onChange = {handleInput} />
        <TextField empty = {emptyFild} name = 'amount' placeholder = 'القيمة' onChange = {handleInput} />
        <div className="flex justify-between" >
          <SelectBox empty = {emptyFild}    name = 'action' options = { target === 'client' ? clientActionsKeys :  target === 'employee' ?  employeeActionsKeys : productActionKeys}  onChange = {handleInput} />
          <SelectBox empty = {emptyFild}    name = 'type' options = { paymentMethods }  onChange = {handleInput} />
        </div>
        <TextArea placeholder = 'تعليق' onChange = {handleInput} name = 'comment'   />
        <LineNotify empty = {emptyFild}  err ={customLog.err} success={customLog.success} alert={customLog.alert} text = {customLog.msg}/>
      </div>
    </Container>
  )
}


function MoneyCard ({icon , amount , title})
{
  return(
    <Container
    dir="ltr"
    title2={
      <div className=" text-gray-400">
        {
          title
        }
      </div>
    }
    >
      {
        <div className=" flex gap-4 items-center" >
              {
                icon
              }
          <div className={` ${amount < 0 && ' text-red-500 '}  flex gap-1 text-4xl text-gray-400 `}>
            <span>
              {
                amount
              }
            </span>
            <span>
              EGP
            </span>
          </div>
        </div>
      }
      <div>

      </div>
    </Container>
  )
}



function ViewMoneyTransaction({payment = {}})
{

  return(
    <Container
      title={
        <div>
        {
          model.getMoneyTransaction(payment)
        }
        </div>
      }
      title2={
        <div className=" text-gray-400 flex justify-around" >
          <span>
            {
              model.dateToText(payment.date)
            }
          </span>
          <span>
            {
              model.getPaymentAction(payment)
            }
          </span>
        </div>
      }
    >
      <Field ownKey={'الرقم المرجعي'} value={payment.id}  />
      <Field ownKey={'نوع المعاملة'} value={payment.type}  />
      <Field ownKey={'القائم بالمعاملة'} value={payment.username} reference={'/user/'+payment.userId}  />
      <Field ownKey={'معاملة لصالح'} value={payment.targetName || payment.name || 'الذهاب'} reference={'/'+payment.targetSection + '/' + payment.targetId}  />
      {
        payment.comment &&
        <div>
          <span className=" font-bold" >تعليق</span>
          <div className=" max-w-60  w-fit text-gray-500 " >
            
            <span>
              {
                payment.comment
              }
            </span>
          </div>
        </div>
      }
    </Container>
  )
}
