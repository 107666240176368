
import Page from "./page";
import { useEffect, useState } from "react";
import { useDelay, useGetPermissions } from "../hooks/customHook";

import { userModel } from "../../model/user";
import { AddNewNotes, GetAllNotes } from "../notes";
import NewItemDialog from "../dashboard/asset/newItemDialog";






function TimeNow ({})
{
  const [time , setTime] = useState();
  useDelay(() => {
    let date = new Date();
    setTime(date.toLocaleTimeString());
  },1)
  
  return(
    <div className=" h-fit text-xl border p-2 w-fit rounded-lg bg-sky-500 text-white " >
      {
        time
      }
      </div>
  )
}


function UserName({auth = {}})
{
  // console.log(auth)
  //  employeeModel.getByUserId(auth.id);
  return(
    <div className=" flex flex-col items-center">
      <div className=" gap-4 flex items-center">
        <span className=" text-lg " >اهلاً بك </span>
        
        <span className=" text-xl font-bold" >{
          auth.name || <SkeletonLine/>
        }</span>
      </div>
      <div className=" gap-4 flex items-center text-lg ">
        {/* <span className=" text-lg ">كود مستخدم  : </span> */}
        <span>{auth.userId || <div className=" w-10 overflow-hidden" > <SkeletonLine/>  </div> }</span>
      </div>
    </div>
  )
}

export
function SkeletonLine ({})
{
  
  return(
    <div className=" bg-gray-300 w-32 h-5 "/>

  )
}




export default
function Home()
{
  const myPem = useGetPermissions();
  const [loading , setLoading] = useState(false);
  const [auth , setAuth] = useState({});
  
  useEffect(() => {
    userModel.checkAuth(setAuth);
    // console.log(myPem)
  } , [])

  return( 
    <Page 
    // title='الرئيسية' Icon={<FcHome/>}
    >
      <div className="flex justify-between" >
        <UserName auth = {auth} />
        <TimeNow/>
      </div>
      <div className=" gap-4 flex items-center">
        <span className=" text-lg ">وظيفة : </span>
        { <span>{myPem.job ||<div className=" w-10 overflow-hidden" > <SkeletonLine/>  </div> }</span>} 
      </div>
      <GetAllNotes type="" />
      <NewItemDialog>
        <AddNewNotes/>
      </NewItemDialog>
    </Page>
  )
}