import { useEffect, useState } from "react";
import { useArrayFilter, useDelay, useFindDateInArray, useGetPermissions, useUpdateApi } from "../../../../hooks/customHook";
import { visitModel } from "../../../../../model/visit";
import { useUpdateData } from "../../../../hooks/events";
import { VisitsDataHeader, VisitsDataUserHeader } from "./assets";
import { VisitAdminCard , VisitUserCard } from "./visitCards";
import Button from "../../../asset/button";
import {  BiRightArrowAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";



export function VisitsFilterData({ id  , target })
{
  let today = new Date();
  const [date , setDate] = useState(today.toLocaleDateString());
  const [status , setStatus] = useState();
  const [visits , setVisits] = useState([{}]);
  
  const [focusMood , setFocusMood] = useState(false);
  const permissions = useGetPermissions();
  


  useEffect(() => {
    if(target == 'client')
      visitModel.getAllByClientId( id , setVisits);
    else
      visitModel.getAllByUserId( id , setVisits);
  },[id])
  useUpdateData(() => {
    if(target == 'client')
      visitModel.getAllByClientId( id , setVisits);
    else
      visitModel.getAllByUserId( id , setVisits);
  },[])

  useEffect(() => {
    let newVisit = visits
    newVisit.sort((a , b) => a.time < b.time ? 1 : -1 )
    newVisit.sort((a , b) => a.date > b.date ? 1 : -1 )
    setVisits(newVisit) 
  },[date , status])
  
  const dataWithDate = useFindDateInArray(visits , date && new Date(date).toLocaleDateString());
  const dataWithStatus = useArrayFilter(dataWithDate , status  , 'status');
  
  useEffect(() => {
    ////console.log(visits , status , date);
  }, [dataWithStatus])
  useDelay(()=> {
    if(target == 'client')
      visitModel.getAllByClientId( id , setVisits);
    else
      visitModel.getAllByUserId( id , setVisits);
  },3)

  useEffect(() => {
    setFocusMood(false)
    if(!permissions.visitUser) return;
    if(visits && permissions)
      {
        if(visits.length > 1) return 
        if( (visits[0].status == 'approved' || visits[0].status == 'arrived'))
        {
          setFocusMood(true)
        }
      }
  },[visits])

  return permissions && (<>{   
    ////// admin side
      (permissions.visitAdmin &&
      <>   
        <VisitsDataHeader visitsNumber={ dataWithStatus && dataWithStatus.length} setDate={setDate} setStatus = {setStatus} today={today} date={date}  />
        <div className=" grid grid-auto-fit-lg sm:p-3 py-4 gap-4 p-0 " >
          {
            (  dataWithStatus.length > 0 ?
            dataWithStatus.map(visit => ((<VisitAdminCard visit={visit} />)))
            : <NoDataFound/>) 
          }
        </div>
      </>
    )  || /////// user side
    (
      permissions.visitUser &&
      
      <>
        <VisitsDataUserHeader setDate={setDate} setStatus = {setStatus} today={today} date={date} focusMood = {focusMood}  />
          <div className=" grid grid-auto-fit-lg py-4 gap-4 p-3 " >
            {
              
              (dataWithStatus &&
              dataWithStatus.length > 0 ?
                dataWithStatus.map(visit => ((<VisitUserCard visit={visit} />)))
              : visits.length == 1 ? <VisitUserCard visit={visits[0]}/> : <NoDataFound/>)
            }
          </div>
      </>
  )}</>
  )
}

export default function VisitsData({  })
{
  let today = new Date();
  const [date , setDate] = useState(today.toLocaleDateString());
  const [status , setStatus] = useState();
  const [visits , setVisits] = useState([{}]);
  
  const [focusMood , setFocusMood] = useState(false);
  const permissions = useGetPermissions();
  


  useEffect(() => {
    visitModel.getAll(setVisits);
  },[])
  useUpdateData(() => {
    visitModel.getAll(setVisits);
  },[])

  useEffect(() => {
    let newVisit = visits
    newVisit.sort((a , b) => a.time < b.time ? 1 : -1 )
    newVisit.sort((a , b) => a.date > b.date ? 1 : -1 )
    setVisits(newVisit) 
  },[date , status])
  
  const dataWithDate = useFindDateInArray(visits , date && new Date(date).toLocaleDateString());
  const dataWithStatus = useArrayFilter(dataWithDate , status  , 'status');
  
  useEffect(() => {
    ////console.log(visits , status , date);
  }, [dataWithStatus])
  useDelay(()=> {
    visitModel.getAll(setVisits);
  },3)

  useEffect(() => {
    setFocusMood(false)
    if(!permissions.visitUser) return;
    if(visits && permissions)
      {
        if(visits.length > 1) return 
        if( (visits[0].status == 'approved' || visits[0].status == 'arrived'))
        {
          setFocusMood(true)
        }
      }
  },[visits])

  return permissions && (<>{   
    ////// admin side
      (permissions.visitAdmin &&
      <>   
        <VisitsDataHeader visitsNumber={ dataWithStatus && dataWithStatus.length} setDate={setDate} setStatus = {setStatus} today={today} date={date}  />
        <div className=" grid grid-auto-fit-lg sm:p-3 py-4 gap-4 p-0 " >
          {
            (  dataWithStatus.length > 0 &&
            dataWithStatus.map(visit => ((<VisitAdminCard visit={visit} />)))
          ) 
          }
        </div>
      </>
    )  || /////// user side
    (
      permissions.visitUser &&
      
      <>
        <VisitsDataUserHeader setDate={setDate} setStatus = {setStatus} today={today} date={date} focusMood = {focusMood}  />
          <div className=" grid grid-auto-fit-lg py-4 gap-4 p-3 " >
            {
              
              (dataWithStatus &&
              dataWithStatus.length > 0 ?
                dataWithStatus.map(visit => ((<VisitUserCard visit={visit} />)))
              : visits.length == 1 && <VisitUserCard visit={visits[0]}/> )
            }
          </div>
      </>
  )}</>
  )
}





function NoDataFound({})
{
  return(
    <div>
      no Data Found Ooops !
    </div>
  )
}

export
function SingleVisit({visitId})
{
  const [visitData , setVisitData] = useState(null);
  const permissions = useGetPermissions();
  const navigate = useNavigate();
  useDelay(() => {
    visitModel.getById(visitId , setVisitData);
  },2)
  useEffect(() => {
    visitModel.getById(visitId , setVisitData);
  // //console.log(visitId);
  },[visitId])



  const goBack = () =>
  {
    navigate('/visit/list')
  }

  return( visitData &&
    <div className=" gap-4 flex flex-col">
      <Button type={'button'} OnClick={goBack}>
        <BiRightArrowAlt/>
      </Button>
      <div>
        {
          permissions.visitAdmin ?
           (<VisitAdminCard visit={visitData} />) 
          :
          permissions.visitAdmin ?
           (<VisitUserCard visit={visitData} />) 
          :' '
        }
      </div>
    </div>
  )
}

function CollectedDiv({})
{

  return(
    <div className=" relative">
      <div className=" absolute w-screen h-fit bg-black">
        ali mohamed ali
      </div>
    </div>
  )
}
