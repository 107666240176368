import { Input, Radio, Select } from "antd"
import { Option } from "antd/es/mentions"
import { BiSearch } from "react-icons/bi"

export
function PageNavigate({handleModeChange , mode , options = {}})
{
  return(
    <div className=" flex gap-4" >
      <Radio.Group
        onChange={handleModeChange}
        value={mode}
        style={{
          marginBottom: 8,
        }}
      >
        {
          Object.keys(options).map(e =>(
            <Radio.Button value={e}>{options[e]}</Radio.Button>
          ))
        }
      </Radio.Group>
      </div>
  )
}


export
function SearchHeadBox({setSearchText , setFilterText , setStatusText = null , options = {} , defaultValue })
{ 
  

  return(
    <div>
      <div className="flex gap-4 pb-4">
        <Input
          onChange={e => setSearchText(e.target.value)}
          addonBefore = {
            <Select onChange={(e) => setFilterText(e)} defaultValue={defaultValue}>
              {
                Object.keys(options).map(e => (
                  <Option value={e}>{options[e]}</Option>
                ))
              }
            </Select>
          }
          addonAfter = {
            <BiSearch/>
          }
          placeholder="بحث ...."
        />
          {
            setStatusText != null &&
            <div className="flex" >
                <Select defaultValue="all"
                  onChange={ e => setStatusText(e) }
                >
                  <Option value="all">الجميع</Option>
                  <Option value="working">شغال</Option>
                  <Option value="stop">متوقف</Option>
                  <Option value="available">متاح</Option>
                  <Option value="waiting">انتظار</Option>
                </Select>
            </div>
          }
      </div>
    </div>
  )
}