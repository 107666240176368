import { useEffect, useState } from "react";
import { CiEdit } from "react-icons/ci";
import { BiRightArrow, BiX } from "react-icons/bi";
import ScrollableDataView from "../dashboard/asset/dataView";
import Container from "../dashboard/container";
import Button from "../dashboard/asset/button";
import Page from "./page";
import NewItemDialog from "../dashboard/asset/newItemDialog";
import { Field } from "../dashboard/containers/data/assets";
import TextField from "../dashboard/asset/textField";
import TextArea from "../dashboard/asset/textArea";
import { dealModel } from "../../model/deal";
import { updateDataEvent } from "../events";
import { model } from "../../model";
import { useUpdateData } from "../hooks/events";
import { LineNotify } from "../dashboard/asset/notify";
import SelectBox from "../dashboard/asset/selectBox";
import { useNavigate, useParams } from "react-router-dom";
import { FaBackward } from "react-icons/fa6";




export default function DealsPage()
{
  const {route} =  useParams();

  return(
    <Page>
      <NewItemDialog>
        <AddNewDeal/>
      </NewItemDialog>
{
      route ? 
      (
        <GetDialogById id={route} />
      ) : (
        <GetAllDeals/>  
      )
}
    </Page>
  )
}



function GetAllDeals({})
{
  const [deals , setDeals] = useState([]);
  useEffect(() => {
    dealModel.getAll(setDeals);
  } , [] )
  
  useUpdateData(() => {
    dealModel.getAll(setDeals);
  })

  return(
    <ScrollableDataView title={'التعاقدات'}>
      {
        deals.map(e => (
          <DealDialog deal={e}/>
        ))
      }
    </ScrollableDataView>  
  )
}

function GetDialogById({id})
{
  const [deal , setDeal] = useState();
  useEffect(()=> {
    dealModel.getById(id , setDeal)
  } , [])
  const navigator = useNavigate();
  const handleBack = () => {
    navigator('/deals');
  }

  return(
  <div className=" flex flex-col gap-4" >
    <Button OnClick={handleBack} secondary  >
      <BiRightArrow/>
    </Button>
    <DealDialog deal={deal}/>
  </div>)
}


function DealDialog({deal = {}})
{
  const [loading , setLoading] = useState(false);
  const [change , setChange] = useState(false);
  const [values , setValues ] = useState({});
  const [log , setLog] = useState({});
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if(change)
    {
      if(values.phone || values.details)
      {
        const res = await dealModel.update(values);
        console.log(res);
        if(res.status == 200)
        {
          setLog({success : true , text : 'تم تأكيد التغيررات'})
          updateDataEvent();
          setChange(false);
        }
        else
        {
          setLog({err : true , text : 'حدث خطأ'})
        }
      }
      else
      {
        setLog({err : true , text : 'يجب تغير الحقل'})
      }
    }
    else
    {
      const res = await dealModel.updateDate(deal.id);
      if(res.status == 200)
        {
        setLog({success : true , text : 'تم التحديث'})
      }
      else
      {
        setLog({err : true , text : 'حدث خطأ'})
      }
    }
    setLoading(false);
  
  }

  const handleInput = (name , value) => {
    setLog({})
    setValues(prev => ({...prev , [name] : value , id : deal.id}));
  }


  const handleChange = () => {
    // console.log('change')
    // toDo 
    setChange(prev => !prev);
  }

  return(
    <Container
      onSubmit={handleSubmit}
      title={
        <div className=" flex justify-between " > 
          <div className=" flex items-center gap-2" >
            {
              model.getDealIcon(deal)
            }
          </div>
          <div className=" text-sm">
            <Button secondary type={'button'} OnClick={handleChange} >
              {
                !change ?
                <CiEdit/>
                :
                <BiX/>
              }
            </Button>
          </div>
        </div>
      }
      title2={
          <span>
          {
            deal.name
          }
          </span>
      }
      footer={
        <div>
          <Button loading = { loading } >
            {
              change ? 'تأكيد التغيرات' : 'تم التواصل'
            }
          </Button>
        </div>
      } 
    >
      <Field ownKey={'رقم التواصل'} referencel={'tel:+20' + deal.phone} value={
        change ? 
        <TextField value = {deal.phone} name = 'phone' onChange = {handleInput} /> 
        :
        deal.phone
        } />
      <Field ownKey={'المندوب'}  reference={'/user/'+deal.authorId }value={ deal.authorName } />
      <Field ownKey={'تفاصيل التعاقد'} value={
        change ? 
        <TextArea maxLength = {200} value = {deal.details} name = 'details' onChange = {handleInput} /> 
        :
        deal.details
        } />
      <Field ownKey={model.dateToText(deal.addDate)} value={
        model.dateToText(deal.updateDate)
        }  />
      <LineNotify err={log.err} text={log.text} success={log.success}  />
    </Container>
  )
}

function AddNewDeal({})
{
  const [loading , setLoading] = useState(false);
  const [values , setValues] = useState({ });
  const [log , setLog] = useState({})

  const handleInput = (name , value) => {
    setLog({})
    setValues(prev => ({...prev , [name] : value}))
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true);
    console.log(values);
    if(values.name && values.section && values.phone && values.details)
    {
      const res = await dealModel.create(values);
      if(res.status == 200)
      {
        setLog({success : true , text : 'تم اضافة تعاقد جديد بنجاح'})
        updateDataEvent(); 
      }
      else
        setLog({err : true , text : 'حدث خطأ اثناء الأرسال'})
    }
    else
      setLog({err : true , text : 'اكمل الحقول'})


    setLoading(false);
  }
  return(
    <Container
      onSubmit={handleSubmit}
      title={'اضافة تعاقد جديد'}
      footer={<Button loading = {loading}>
        اضافة
      </Button>}
    >
      <TextField err = {log.err && !values.name && 'اكمل الحقل' } name = 'name' placeholder = 'اسم المنشأة' onChange = {handleInput} />
      <SelectBox err = {log.err && !values.section && 'اكمل الحقل' } name = 'section' options = {{ pharmacy : 'صيدلية' , hospital : 'مستشفي' , doctor : 'دكتور' , center : 'مركز'  }} onChange = {handleInput} />
      <TextArea err = {log.err && !values.details && 'اكمل الحقل' } name = 'details' placeholder = 'تفاصيل التعاقد' maxLength = {200} onChange = {handleInput} />
      <TextField err = {log.err && !values.phone && 'اكمل الحقل' } name = 'phone' type = 'tel' placeholder = 'رقم التواصل' onChange = {handleInput} />

      <LineNotify err={log.err} text={log.text} success={log.success}  />
    </Container>
  )
}

